import {
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  MapOutlined,
  AddOutlined,
  PublicOutlined,
} from "@mui/icons-material";

import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { ICONS } from "../../../const/icons";
import { db, Place } from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";
import { haversineDistance } from "../../../utils/location";
import { IconSelector } from "./IconSelector";
import { useSearchParams } from "react-router-dom";
import { useSettings } from "../../../hooks/useSettings";
import { DEV_COLOUR } from "../../../const/colours";

type PlaceWithDistance = Place & { distance?: number };

export const PlacesComponent: React.FC = () => {
  const cloudSyncedDB = useCloudSyncedDB();
  const places = useLiveQuery(() => db.places.toArray(), []);
  const [editingPlace, setEditingPlace] = useState<Place | null>(null);
  const [deletingPlace, setDeletingPlace] = useState<Place | null>(null);
  const [nameFilter, setNameFilter] = useState("");
  const [latLonFilter, setLatLonFilter] = useState({
    lat: 0,
    lon: 0,
    radius: 0,
  });
  const [showAllPlaces, setShowAllPlaces] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const placeId = searchParams.get("placeId");
    if (placeId && places) {
      const place = places.find((p) => p.id === placeId);
      if (place) {
        setEditingPlace(place);
      }
      setSearchParams({}, { replace: true });
      setTimeout(() => {
        // Scroll to this component
        const placesElement = document.getElementById("places");
        if (placesElement) {
          placesElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 200);
    }
    const lat = searchParams.get("lat");
    const lon = searchParams.get("lon");
    const radius = searchParams.get("radius");
    if (lat && lon && radius) {
      setLatLonFilter({
        lat: parseFloat(lat),
        lon: parseFloat(lon),
        radius: parseFloat(radius),
      });
      setSearchParams({}, { replace: true });
      setTimeout(() => {
        // Scroll to this component
        const placesElement = document.getElementById("places");
        if (placesElement) {
          placesElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 200);
    }
  }, [searchParams, places, setSearchParams]);

  const handleEdit = (place: Place) => {
    setEditingPlace(place);
  };

  const handleAdd = async (values: Omit<Place, "id">) => {
    await cloudSyncedDB.addPlace(values);
    setShowAddForm(false);
  };

  const handleViewNearby = (place: Place) => {
    setLatLonFilter({
      lat: place.latitude,
      lon: place.longitude,
      radius: 1000,
    });
  };

  const handleUpdate = async (updatedPlace: Place) => {
    await cloudSyncedDB.updatePlace(updatedPlace.id, updatedPlace);
    setEditingPlace(null);
  };

  const handleCancel = () => {
    setEditingPlace(null);
    setShowAddForm(false);
  };

  const handleDeleteConfirmation = (place: Place) => {
    setDeletingPlace(place);
  };

  const handleDelete = async () => {
    if (deletingPlace) {
      await cloudSyncedDB.deletePlace(deletingPlace.id);
      setDeletingPlace(null);
    }
  };

  const handleCancelDelete = () => {
    setDeletingPlace(null);
  };

  const handleViewOnMap = (place: Place) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${place.latitude},${place.longitude}`;
    window.open(url, "_blank");
  };

  const filteredPlaces: PlaceWithDistance[] = useMemo(() => {
    if (!places) return [];

    let fPlaces: PlaceWithDistance[];

    if (latLonFilter.lat && latLonFilter.lon) {
      fPlaces = places.map((place) => ({
        ...place,
        distance: haversineDistance(
          latLonFilter.lat,
          latLonFilter.lon,
          place.latitude,
          place.longitude
        ),
      }));
    } else {
      fPlaces = places;
    }

    if (nameFilter) {
      const filters = nameFilter.toLowerCase().split(" ");
      fPlaces = fPlaces.filter((place) =>
        filters.some((filter) =>
          place.name.toLowerCase().includes(filter.toLowerCase())
        )
      );
    }
    // Only filter by lat/lon if all 3 values are set and valid
    if (latLonFilter.lat && latLonFilter.lon && latLonFilter.radius > 0) {
      fPlaces = fPlaces.filter(
        (place) => place.distance && place.distance <= latLonFilter.radius
      );
      // Sort by distance to lat/lon
      fPlaces = fPlaces.sort((a, b) => {
        if (!a.distance || !b.distance) return 0;
        return a.distance - b.distance;
      });
    }

    return fPlaces;
  }, [places, nameFilter, latLonFilter]);
  const displayedPlaces: PlaceWithDistance[] = showAllPlaces
    ? filteredPlaces
    : filteredPlaces.slice(0, 10);

  if (editingPlace || showAddForm) {
    return (
      <Paper elevation={2} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>
          {editingPlace ? "Edit Place" : "Add New Place"}
        </Typography>
        <PlaceForm
          place={
            editingPlace ||
            ({
              id: "",
              name: "",
              latitude: 0,
              longitude: 0,
              radiusMetres: 100,
              icon: "place",
            } as Place)
          }
          onSubmit={
            editingPlace
              ? (updatedPlace) => handleUpdate(updatedPlace)
              : (newPlace) => handleAdd(newPlace)
          }
          onCancel={handleCancel}
        />
      </Paper>
    );
  }

  return (
    <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        marginBottom={3}
        marginTop={2}>
        <Typography variant="h4" gutterBottom>
          Places <small>({places?.length ?? 0})</small>
        </Typography>
        <Button
          startIcon={<AddOutlined />}
          variant="contained"
          onClick={() => setShowAddForm(true)}>
          Add
        </Button>
      </Stack>

      <Stack direction="column" spacing={2}>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={2}
          justifyContent="space-between"
          alignItems="center">
          <TextField
            label="Filter by Name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}
            margin="normal"
          />
          <Stack
            direction={{
              xs: "column",
              sm: "row",
              md: "row",
            }}
            width={{ xs: "100%", sm: "100%", md: "100%" }}
            spacing={2}>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ width: { xs: "100%", sm: "100%", md: "70%" } }}>
              <TextField
                label="Latitude"
                type="number"
                value={latLonFilter.lat}
                onChange={(e) =>
                  setLatLonFilter({
                    ...latLonFilter,
                    lat: parseFloat(e.target.value),
                  })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Longitude"
                type="number"
                value={latLonFilter.lon}
                onChange={(e) =>
                  setLatLonFilter({
                    ...latLonFilter,
                    lon: parseFloat(e.target.value),
                  })
                }
                fullWidth
                margin="normal"
              />
            </Stack>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              spacing={2}
              sx={{ width: { xs: "100%", sm: "40%", md: "33%" } }}>
              <TextField
                label="Radius (km)"
                type="number"
                value={latLonFilter.radius}
                onChange={(e) =>
                  setLatLonFilter({
                    ...latLonFilter,
                    radius: parseFloat(e.target.value),
                  })
                }
                fullWidth
                margin="normal"
              />
              <IconButton
                onClick={() => setLatLonFilter({ lat: 0, lon: 0, radius: 0 })}
                sx={{ alignSelf: "flex-start" }}>
                <ClearOutlined />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: { xs: "100%", sm: "100%" } }}>
          <Typography variant="body2">
            Showing {displayedPlaces.length} of {filteredPlaces.length} places
          </Typography>
          {filteredPlaces.length > 10 && (
            <Button onClick={() => setShowAllPlaces(!showAllPlaces)}>
              {showAllPlaces
                ? "Show less"
                : `Show all (${filteredPlaces.length})`}
            </Button>
          )}
        </Stack>
      </Stack>

      {displayedPlaces.length > 0 ? (
        <>
          <List>
            {displayedPlaces.map((place: PlaceWithDistance) => (
              <PlaceRow
                key={place.id}
                place={place}
                onEdit={handleEdit}
                onDelete={handleDeleteConfirmation}
                onViewMap={handleViewOnMap}
                onNearby={handleViewNearby}
              />
            ))}
          </List>
          {displayedPlaces.length < filteredPlaces.length && (
            <Typography variant="body2">
              {filteredPlaces.length - displayedPlaces.length} more places not
              shown
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="body1">No places found</Typography>
      )}
      {deletingPlace && (
        <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
          <Typography variant="h6">Confirm Deletion</Typography>
          <Typography>
            Are you sure you want to delete {deletingPlace.name}?
          </Typography>
          <Button onClick={handleDelete} color="error">
            Yes, Delete
          </Button>
          <Button onClick={handleCancelDelete}>Cancel</Button>
        </Paper>
      )}
    </Paper>
  );
};

interface PlaceFormProps {
  place: Place;
  onSubmit: (values: Place) => void;
  onCancel: () => void;
}

const PlaceForm: React.FC<PlaceFormProps> = ({ place, onSubmit, onCancel }) => {
  return (
    <Formik
      initialValues={place}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="radiusMetres"
                label="Radius (m)"
                type="number"
                value={values.radiusMetres}
                onChange={handleChange}
                sx={{ width: "150px" }}
                margin="normal"
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                name="latitude"
                label="Latitude"
                type="number"
                value={values.latitude}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="longitude"
                label="Longitude"
                type="number"
                value={values.longitude}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Stack>

            <IconSelector
              selectedIcon={values.icon}
              onSelectIcon={(iconName) => setFieldValue("icon", iconName)}
              color="primary"
            />

            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginRight: 2 }}>
                {place.id ? "Update" : "Add"}
              </Button>
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const PlaceRow: React.FC<{
  place: PlaceWithDistance;
  onEdit: (place: Place) => void;
  onDelete: (place: Place) => void;
  onViewMap: (place: Place) => void;
  onNearby: (place: Place) => void;
}> = ({ place, onEdit, onDelete, onViewMap, onNearby }) => {
  const Icon = ICONS[place.icon];
  let secondaryText = `Lat: ${place.latitude}, Long: ${place.longitude}, Radius: ${place.radiusMetres}m`;
  if (place.distance !== undefined) {
    secondaryText += `, Distance: ${place.distance.toFixed(0)}m`;
  }

  const { developerMode } = useSettings();
  return (
    <ListItem>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={place.name} secondary={secondaryText} />
      {developerMode && (
        <Tooltip title={`ID: ${place.id}`}>
          <Typography
            sx={{
              ml: 2,
              fontSize: "0.8rem",
              color: DEV_COLOUR,
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            ID: {place.id}
          </Typography>
        </Tooltip>
      )}
      <Tooltip title="Show nearby">
        <IconButton color="primary" onClick={() => onNearby(place)}>
          <MapOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Show map">
        <IconButton color="primary" onClick={() => onViewMap(place)}>
          <PublicOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton onClick={() => onEdit(place)}>
          <EditOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton onClick={() => onDelete(place)} color="error">
          <DeleteOutlined />
        </IconButton>
      </Tooltip>
    </ListItem>
  );
};
