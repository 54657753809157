import React from "react";
import { Button, TextField, Stack, Autocomplete } from "@mui/material";
import { Form, Formik } from "formik";
import { Activity, Category } from "../../../database/db";
import { IconSelector } from "./IconSelector";

interface ActivityFormProps {
  activity?: Activity;
  categories: Category[];
  onSubmit: (values: Omit<Activity, "id">) => void;
  onCancel: () => void;
}

export const ActivityForm: React.FC<ActivityFormProps> = ({
  activity,
  categories,
  onSubmit,
  onCancel,
}) => {
  return (
    <Formik
      initialValues={activity || { name: "", categoryId: "", icon: "" }}
      validate={(values) => {
        const errors: Partial<{ name: string; categoryId: string }> = {};
        if (!values.name) errors.name = "Name is required";
        if (!values.categoryId) errors.categoryId = "Category is required";
        return errors;
      }}
      onSubmit={onSubmit}>
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Stack direction="column" spacing={2}>
            <TextField
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
            />
            <Autocomplete
              options={categories}
              getOptionLabel={(option) => option.name}
              value={categories.find((c) => c.id === values.categoryId) || null}
              onChange={(_, newValue) => {
                setFieldValue("categoryId", newValue?.id || 0);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  error={touched.categoryId && !!errors.categoryId}
                  helperText={touched.categoryId && errors.categoryId}
                />
              )}
            />
            <IconSelector
              selectedIcon={values.icon}
              onSelectIcon={(iconName) => setFieldValue("icon", iconName)}
              color={
                categories.find((c) => c.id === values.categoryId)?.colour ||
                "primary"
              }
            />
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button type="submit" variant="contained" color="primary">
                {activity ? "Update" : "Add"} Activity
              </Button>
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
