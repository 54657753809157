import React, { useState, useEffect, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Filler,
  ChartOptions,
  TooltipItem,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { db } from "../../../database/db";
import { Button, Stack } from "@mui/material";
import { addDays, format, startOfDay } from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

type TimePeriod = "day" | "week" | "month";

const Trends: React.FC = () => {
  const [endDate, setEndDate] = useState<Date>(startOfDay(new Date()));
  const [timePeriod, setTimePeriod] = useState<TimePeriod>("week");
  const [chartData, setChartData] = useState<ChartData<"line">>({
    datasets: [],
  });

  const loadData = useCallback(async () => {
    const end = endDate;
    let start: Date;
    let interval: number;

    switch (timePeriod) {
      case "day":
        start = new Date(end.getTime() - 24 * 60 * 60 * 1000);
        interval = 60 * 60 * 1000; // 1 hour
        break;
      case "week":
        start = new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000);
        interval = 24 * 60 * 60 * 1000; // 1 day
        break;
      case "month":
        start = new Date(end.getTime() - 30 * 24 * 60 * 60 * 1000);
        interval = 24 * 60 * 60 * 1000; // 1 day
        break;
    }

    const timeslots = await db.timeslots
      .where("startTimestampMills")
      .between(start.getTime(), end.getTime())
      .toArray();

    const activities = await db.activities.toArray();
    const activityMap = new Map(activities.map((a) => [a.id, a]));

    const categories = await db.categories.toArray();
    const categoryMap = new Map(categories.map((c) => [c.id, c]));

    const categoryData = new Map<string, number[]>();
    const labels: string[] = [];

    for (let t = start.getTime(); t < end.getTime(); t += interval) {
      labels.push(new Date(t).toLocaleDateString());
      categories.forEach((category) => {
        if (!categoryData.has(category.id)) {
          categoryData.set(category.id, []);
        }
        categoryData.get(category.id)!.push(0);
      });
    }

    timeslots.forEach((timeslot) => {
      const activity = activityMap.get(timeslot.activityId);
      if (activity) {
        const categoryId = activity.categoryId;
        const duration =
          (timeslot.endTimestampMills - timeslot.startTimestampMills) / 60000; // minutes
        const index = Math.floor(
          (timeslot.startTimestampMills - start.getTime()) / interval
        );
        if (index >= 0 && index < labels.length) {
          categoryData.get(categoryId)![index] += duration;
        }
      }
    });

    // Calculate percentages
    const totalData: number[] = new Array(labels.length).fill(0);
    categoryData.forEach((data) => {
      data.forEach((value, index) => {
        totalData[index] += value;
      });
    });

    const datasets = Array.from(categoryData.entries()).map(
      ([categoryId, data]) => {
        const category = categoryMap.get(categoryId)!;
        const percentageData = data.map((value, index) =>
          totalData[index] === 0 ? 0 : (value / totalData[index]) * 100
        );
        return {
          label: category.name,
          data: percentageData,
          backgroundColor: category.colour,
          borderColor: category.colour,
          borderWidth: 1,
          fill: true,
          averageArea:
            percentageData.reduce((a, b) => a + b, 0) / percentageData.length,
        };
      }
    );

    // Sort datasets by average area (largest at the bottom)
    datasets.sort((a, b) => b.averageArea - a.averageArea);

    setChartData({ labels, datasets });
  }, [endDate, timePeriod]);

  useEffect(() => {
    loadData();
  }, [endDate, loadData, timePeriod]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(startOfDay(new Date(e.target.value)));
  };

  const handleTimePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimePeriod(e.target.value as TimePeriod);
  };

  const goToPreviousDay = () => {
    setEndDate(addDays(endDate, -1));
  };

  const goToNextDay = () => {
    setEndDate(addDays(endDate, 1));
  };

  const goToToday = () => {
    setEndDate(startOfDay(new Date()));
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: {
        stacked: true,
        min: 0,
        max: 100,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Stacked Area Chart",
      },
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<"line">) => {
            const label = context.dataset.label || "";
            const value = context.parsed.y;
            return `${label}: ${value.toFixed(2)}%`;
          },
        },
      },
    },
  };

  return (
    <div>
      <h2>Trends</h2>
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        <Button variant="outlined" onClick={goToPreviousDay}>
          Previous Day
        </Button>
        <Button variant="outlined" onClick={goToNextDay}>
          Next Day
        </Button>
        <Button variant="contained" onClick={goToToday}>
          Today
        </Button>
        <input
          type="date"
          onChange={handleDateChange}
          value={format(endDate, "yyyy-MM-dd")}
        />
        <select
          value={timePeriod}
          onChange={handleTimePeriodChange}
          style={{ width: 120 }}>
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
        </select>
      </Stack>
      <div style={{ height: 400 }}>
        <Line options={options} data={chartData} />
      </div>
    </div>
  );
};

export default Trends;
