import { Place } from "../database/db";
import { db } from "../database/db";

export const haversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371e3; // Earth's radius in meters
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in meters
};

export async function getNearbyPlacesFromDb(
  lat: number,
  lon: number,
  maxDistanceKm: number = 2
): Promise<Place[]> {
  // Convert maxDistanceKm to degrees (approximate)
  const maxDegreeDifference = maxDistanceKm / 111.32;

  // Calculate bounding box
  const minLat = lat - maxDegreeDifference;
  const maxLat = lat + maxDegreeDifference;
  const minLon = lon - maxDegreeDifference / Math.cos((lat * Math.PI) / 180);
  const maxLon = lon + maxDegreeDifference / Math.cos((lat * Math.PI) / 180);

  // Query the database for places within the bounding box
  const potentialNearbyPlaces = await db.places
    .where("latitude")
    .between(minLat, maxLat)
    .and((place) => place.longitude >= minLon && place.longitude <= maxLon)
    .toArray();

  return potentialNearbyPlaces;
}

export async function getPlacesForLocation(
  lat: number,
  lon: number
): Promise<Place[]> {
  const places = await getNearbyPlacesFromDb(lat, lon);
  return places.filter((place) => {
    const distance = haversineDistance(
      lat,
      lon,
      place.latitude,
      place.longitude
    );
    return distance <= place.radiusMetres;
  });
}
