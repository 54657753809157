import React from "react";
import styled from "@emotion/styled";

interface LocationHistoryMarkProps {
  timestampMills: number;
  dayStartMills: number;
  pxPerMs: number;
}

const Mark = styled.div<{ top: number }>`
  position: absolute;
  right: 0;
  width: 6px;
  height: 3px;
  border-radius: 4px 0 0 4px;
  background-color: #55555544;
  top: ${(props) => props.top}px;
`;

export const LocationHistoryMark: React.FC<LocationHistoryMarkProps> = ({
  timestampMills,
  dayStartMills,
  pxPerMs,
}) => {
  const top = (timestampMills - dayStartMills) * pxPerMs;

  return <Mark top={top} />;
};
