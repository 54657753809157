import React, { createContext, useState, useCallback, ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { GREY_COLOUR } from "../const/colours";

interface ModalContextType {
  showModal: (
    title: string,
    content: ReactNode,
    options?: {
      icon?: ReactNode;
      colour?: string;
      okText?: string;
      cancelText?: string | false;
    }
  ) => Promise<boolean>;
}

export const ModalContext = createContext<ModalContextType | undefined>(
  undefined
);

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState<ReactNode>(null);
  const [icon, setIcon] = useState<ReactNode>(null);
  const [colour, setColour] = useState<string | undefined>(undefined);
  const [okText, setOkText] = useState("OK");
  const [cancelText, setCancelText] = useState<string | null>(null);
  const [resolvePromise, setResolvePromise] = useState<
    ((value: boolean) => void) | null
  >(null);

  const showModal = useCallback(
    (
      title: string,
      content: ReactNode,
      options?: {
        icon?: ReactNode;
        colour?: string;
        okText?: string;
        cancelText?: string | false;
      }
    ): Promise<boolean> => {
      setTitle(title);
      setContent(content);
      setIcon(options?.icon || null);
      setColour(options?.colour);
      setOkText(options?.okText || "OK");
      if (options?.cancelText === false) {
        setCancelText(null);
      } else {
        setCancelText(options?.cancelText || "Cancel");
      }
      setOpen(true);
      return new Promise<boolean>((resolve) => {
        setResolvePromise(() => resolve);
      });
    },
    []
  );

  const handleClose = useCallback(
    (value: boolean) => {
      setOpen(false);
      if (resolvePromise) {
        resolvePromise(value);
      }
    },
    [resolvePromise]
  );

  return (
    <ModalContext.Provider value={{ showModal }}>
      {children}
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle
          style={{ color: colour, display: "flex", alignItems: "center" }}>
          {icon && <IconButton style={{ color: colour }}>{icon}</IconButton>}
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {cancelText && (
            <Button
              onClick={() => handleClose(false)}
              sx={{ color: GREY_COLOUR }}>
              {cancelText}
            </Button>
          )}
          <Button
            onClick={() => handleClose(true)}
            autoFocus
            style={{ color: colour }}>
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
    </ModalContext.Provider>
  );
};
