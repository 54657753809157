import React, { useCallback, useEffect, useState } from "react";
import { Activity, Category, db, Timeslot } from "../../../database/db";
import PercentageGraph from "./PercentageGraph";

type Tab = "today" | "last7days" | "last30days";

const TimeAnalysis: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tab>("today");
  const [timeslots, setTimeslots] = useState<Timeslot[]>([]);
  const [activityMap, setActivityMap] = useState<Map<string, Activity>>(
    new Map()
  );
  const [categoryMap, setCategoryMap] = useState<Map<string, Category>>(
    new Map()
  );
  const [categoryData, setCategoryData] = useState<
    { category: Category; minutes: number; totalTime: string }[]
  >([]);

  const loadData = useCallback(async () => {
    const now = new Date();
    let startDate: Date;
    let totalPeriodMinutes: number;

    switch (activeTab) {
      case "today":
        startDate = new Date(now.setHours(0, 0, 0, 0));
        totalPeriodMinutes = 24 * 60;
        break;
      case "last7days":
        startDate = new Date(now.setDate(now.getDate() - 7));
        totalPeriodMinutes = 7 * 24 * 60;
        break;
      case "last30days":
        startDate = new Date(now.setDate(now.getDate() - 30));
        totalPeriodMinutes = 30 * 24 * 60;
        break;
    }

    const loadedTimeslots = await db.timeslots
      .where("startTimestampMills")
      .aboveOrEqual(startDate.getTime())
      .toArray();

    setTimeslots(loadedTimeslots);

    const activities = await db.activities.toArray();
    const activityMapTemp = new Map(activities.map((a) => [a.id, a]));
    setActivityMap(activityMapTemp);

    const categories = await db.categories.toArray();
    const categoryMapTemp = new Map(categories.map((c) => [c.id, c]));
    setCategoryMap(categoryMapTemp);

    calculateCategoryData(
      loadedTimeslots,
      activityMapTemp,
      categoryMapTemp,
      totalPeriodMinutes
    );
  }, [activeTab]);

  useEffect(() => {
    loadData();
  }, [activeTab, loadData]);

  const calculateCategoryData = (
    timeslots: Timeslot[],
    activityMap: Map<string, Activity>,
    categoryMap: Map<string, Category>,
    totalPeriodMinutes: number
  ) => {
    const categoryMinutes = new Map<string, number>();
    let loggedMinutes = 0;

    timeslots.forEach((timeslot) => {
      const duration =
        (timeslot.endTimestampMills - timeslot.startTimestampMills) / 60000;
      loggedMinutes += duration;

      const activity = activityMap.get(timeslot.activityId);
      if (activity) {
        const categoryId = activity.categoryId;
        categoryMinutes.set(
          categoryId,
          (categoryMinutes.get(categoryId) || 0) + duration
        );
      }
    });

    const unknownMinutes = totalPeriodMinutes - loggedMinutes;

    const data = Array.from(categoryMinutes.entries()).map(
      ([categoryId, minutes]) => ({
        category: categoryMap.get(categoryId)!,
        minutes: Math.round(minutes),
        totalTime: "0m",
      })
    );

    data.push({
      category: { id: "", name: "Unknown", colour: "#CCCCCC" },
      minutes: Math.round(unknownMinutes),
      totalTime: "0m",
    });

    setCategoryData(data);
  };

  console.log("Timeslots", timeslots);
  console.log("Activity map", activityMap);
  console.log("Category map", categoryMap);

  return (
    <div>
      <div>
        <button onClick={() => setActiveTab("today")}>Today</button>
        <button onClick={() => setActiveTab("last7days")}>Last 7 Days</button>
        <button onClick={() => setActiveTab("last30days")}>Last 30 Days</button>
      </div>
      <h2>
        {activeTab === "today"
          ? "Today"
          : activeTab === "last7days"
          ? "Last 7 Days"
          : "Last 30 Days"}
      </h2>
      <PercentageGraph data={categoryData} />
    </div>
  );
};

export default TimeAnalysis;
