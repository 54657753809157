import { Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Button, Stack, Tooltip, Typography } from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import long_logo from "../assets/long_logo.svg";
import { useCloudSyncedDB } from "../hooks/useCloudSyncedDb";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSettings } from "../hooks/useSettings";
import { KeyIcon } from "./KeyIcon";

interface AppHeaderProps {
  toggleDrawer: () => void;
}

export function AppHeader({ toggleDrawer }: AppHeaderProps) {
  const cloudSyncedDB = useCloudSyncedDB();
  const unsyncedChangeCount = useLiveQuery(
    () => cloudSyncedDB.getChangeCount(),
    [cloudSyncedDB]
  );

  const { cloudSyncEnabled } = useSettings();

  return (
    <AppBar position="static" sx={{ zIndex: 2100, maxWidth: "100%" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}>
        <Stack
          sx={{
            width: "100%",
            maxWidth: "960px",
            padding: "0.5rem 2rem",
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <a
              href="/"
              style={{ padding: "0rem", margin: "0rem", marginRight: "1rem" }}>
              <img src={long_logo} alt="DumberTime" height={50} />
            </a>

            {cloudSyncEnabled && (
              <>
                {cloudSyncedDB.downloadingChanges && (
                  <>
                    <CloudDownloadIcon />
                    {cloudSyncedDB.changesLeftToReceive > 0 && (
                      <Typography variant="body1">
                        {cloudSyncedDB.changesLeftToReceive}
                      </Typography>
                    )}
                  </>
                )}

                {!!unsyncedChangeCount && unsyncedChangeCount > 0 && (
                  <>
                    <CloudUploadIcon />
                    <Typography variant="body1">
                      {unsyncedChangeCount}
                    </Typography>
                  </>
                )}
              </>
            )}
          </Stack>

          <Tooltip
            slotProps={{
              popper: {
                sx: {
                  zIndex: 2200,
                },
              },
              tooltip: {
                sx: {
                  bgcolor: "#EEEEEE",
                  color: "#333333",
                },
              },
            }}
            placement="bottom"
            title={<KeyIcon keyName="~" />}>
            <Button variant="contained" onClick={toggleDrawer}>
              <MenuIcon />
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
    </AppBar>
  );
}
