export function isValidUrl(url: string): boolean {
  try {
    const urlObj = new URL(url);
    if (!urlObj.hostname) {
      return false;
    }
    return true;
  } catch (_) {
    return false;
  }
}
