import { Timeslot } from "../database/db";

// Given a list of timeslots,
// returns a list of time ranges where each range is a gap between timeslots
// or a gap between a timeslot and the start or end of the day
// This removes gaps that are less than minRangeMinutes
export const getBlankTimeRanges = (
  timeslots: Timeslot[],
  dayBounds: [number, number],
  minRangeMinutes: number = 1
) => {
  const blankTimeRanges: [number, number][] = [];
  let currentStart = dayBounds[0];

  for (const timeslot of timeslots) {
    if (timeslot.startTimestampMills > currentStart) {
      const range = timeslot.startTimestampMills - currentStart;
      if (range >= minRangeMinutes * 60 * 1000) {
        blankTimeRanges.push([currentStart, timeslot.startTimestampMills]);
      }
    }
    currentStart = timeslot.endTimestampMills;
  }

  if (currentStart < dayBounds[1]) {
    blankTimeRanges.push([currentStart, dayBounds[1]]);
  }

  return blankTimeRanges;
};

// Given a list of timeslots,
// returns a list of boundaries where each boundary is the start or end of a timeslot
// or the start or end of the day
// This removes boundaries that are within minRangeMinutes of each other
export const generateTimeslotBoundaries = (
  timeslots: Timeslot[],
  dayBounds: [number, number],
  minRangeMinutes: number = 2
) => {
  const boundaries: number[] = [];

  // Add the start of the day
  boundaries.push(dayBounds[0]);
  timeslots.forEach((t) => {
    boundaries.push(t.startTimestampMills);
    boundaries.push(t.endTimestampMills);
  });
  // Add the end of the day
  boundaries.push(dayBounds[1]);

  // Scan through boundaries and remove boundaries that are within 1 minute of each other
  const newBoundaries: number[] = [];
  for (let i = 0; i < boundaries.length; i++) {
    if (
      i > 0 &&
      boundaries[i] - boundaries[i - 1] < minRangeMinutes * 60 * 1000
    ) {
      continue;
    }
    newBoundaries.push(boundaries[i]);
  }
  return newBoundaries;
};

// Given a list of timeslot boundaries,
// returns a list of potential points to add a new timeslot
// This adds new boundaries where the gap between boundaries is greater than maxGap
export const getPotentialAddPoints = (
  timeslotBoundaries: number[],
  dayBounds: [number, number],
  maxGapMins: number = 60
) => {
  const uniqueBoundaries = Array.from(
    new Set([...dayBounds, ...timeslotBoundaries])
  ).sort((a, b) => a - b);
  const points: number[] = [];

  for (let i = 0; i < uniqueBoundaries.length - 1; i++) {
    const start = uniqueBoundaries[i];
    const end = uniqueBoundaries[i + 1];
    points.push(start);

    for (
      let time = start + maxGapMins * 60 * 1000;
      time < end;
      time += maxGapMins * 60 * 1000
    ) {
      points.push(time);
    }
  }

  points.push(dayBounds[1]);

  return points;
};
