import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MATERIAL_COLOUR_ARRAY } from "../../../const/colours";
import { ICON_ARRAY } from "../../../const/icons";
import { db } from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";
import { useSettings } from "../../../hooks/useSettings";
import {
  clearDatabase,
  seedDefaults,
  setAppInitialised,
} from "../../../utils/db_helpers";
import {
  ExtractedLocationHistory,
  ExtractedPlace,
  extractLocationHistory,
  extractPlaceAndCategories,
  extractPlacesFromGeoloc,
  extractTimeslots,
} from "../../../utils/smartertime";

const SmarterTimeImport: React.FC = () => {
  const [geolocFile, setGeolocFile] = useState<File | null>(null);
  const [timeslotsFile, setTimeslotsFile] = useState<File | null>(null);
  const [showWarning, setShowWarning] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [importProgress, setImportProgress] = useState(0);

  const cloudDb = useCloudSyncedDB();
  const { disableCloudSync } = useSettings();

  const [importLocations, setImportLocations] = useState(true);
  const [deriveLocations, setDeriveLocations] = useState(true);
  const [importMetadata, setImportMetadata] = useState(true);

  const onDropGeoloc = useCallback((acceptedFiles: File[]) => {
    setGeolocFile(acceptedFiles[0]);
  }, []);

  const onDropTimeslots = useCallback((acceptedFiles: File[]) => {
    setTimeslotsFile(acceptedFiles[0]);
  }, []);

  const {
    getRootProps: getGeolocRootProps,
    getInputProps: getGeolocInputProps,
  } = useDropzone({
    onDrop: onDropGeoloc,
    accept: { "text/csv": [".csv"] },
    multiple: false,
  });

  const {
    getRootProps: getTimeslotsRootProps,
    getInputProps: getTimeslotsInputProps,
  } = useDropzone({
    onDrop: onDropTimeslots,
    accept: { "text/csv": [".csv"] },
    multiple: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseCSV = (file: File): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (results) => {
          resolve(results.data);
        },
        header: true,
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const confirmImport = async () => {
    console.log("Importing data");
    if (timeslotsFile && (importLocations ? geolocFile : true)) {
      setIsImporting(true);
      setImportProgress(0);

      console.log("Disabling cloud sync");

      // Clear cloud backup
      await disableCloudSync();
      await cloudDb.emptyCloudBackupChanges();
      try {
        const timeslotsData = await parseCSV(timeslotsFile);
        console.log("Timeslots JSON:", timeslotsData);
        console.log(`Timeslots file has ${timeslotsData.length} entries`);

        const { places: placesFromTimeslots, categories } =
          extractPlaceAndCategories(timeslotsData);
        console.log("Locations:", placesFromTimeslots);
        console.log("Categories:", categories);

        const timeslots = extractTimeslots(timeslotsData);
        console.log("Timeslots:", timeslots);

        let places: ExtractedPlace[] = [];
        let locationHistory: ExtractedLocationHistory[] = [];
        if (importLocations && geolocFile) {
          const geolocData = await parseCSV(geolocFile);
          console.log("Geoloc JSON:", geolocData);
          console.log(`Geoloc file has ${geolocData.length} entries`);

          setTotalRows(geolocData.length + timeslotsData.length);

          places = extractPlacesFromGeoloc(geolocData);
          console.log("Places:", places);

          locationHistory = extractLocationHistory(geolocData);
          console.log("Location History:", locationHistory);
        } else {
          setTotalRows(timeslotsData.length);
        }

        await clearDatabase();
        await seedDefaults(); // Add default categories and activities

        const normalise = (str: string): string => {
          return str.toLowerCase().replace(/[^a-z0-9]/g, "");
        };

        const existingCategories = await db.categories.toArray();
        const existingActivities = await db.activities.toArray();

        // Create maps for faster lookups
        const categoryMap = new Map(
          existingCategories.map((c) => [normalise(c.name), c])
        );
        let activityMap = new Map(
          existingActivities.map((a) => [
            `${normalise(a.name)}_${a.categoryId}`,
            a,
          ])
        );

        for (const category of Object.keys(categories)) {
          console.log("Adding category", category);
          const normalisedCategoryName = normalise(category);
          let categoryId: string;

          // Try to find an existing category
          const existingCategory = categoryMap.get(normalisedCategoryName);

          if (existingCategory) {
            categoryId = existingCategory.id;
          } else {
            // If no matching category found, create a new one
            const categoryToAdd = {
              name: category,
              colour:
                MATERIAL_COLOUR_ARRAY[
                  Math.floor(Math.random() * MATERIAL_COLOUR_ARRAY.length)
                ].hex,
            };
            categoryId = await cloudDb.addCategory(categoryToAdd);
            // Add the new category to the map
            categoryMap.set(normalisedCategoryName, {
              ...categoryToAdd,
              id: categoryId,
            });
          }

          for (const activity of categories[category].activities) {
            const normalisedActivityName = normalise(activity);

            // Try to find an existing activity
            const existingActivity = activityMap.get(
              `${normalisedActivityName}_${categoryId}`
            );

            if (!existingActivity) {
              // If no matching activity found, create a new one
              const activityToAdd = {
                name: activity,
                categoryId: categoryId,
                icon: ICON_ARRAY[Math.floor(Math.random() * ICON_ARRAY.length)]
                  .name,
              };
              const newActivityId = await cloudDb.addActivity(activityToAdd);
              // Add the new activity to the map
              activityMap.set(`${normalisedActivityName}_${categoryId}`, {
                ...activityToAdd,
                id: newActivityId,
              });
            }
          }
        }

        // Places will be empty if importLocations is false
        for (const place of places) {
          const placeToAdd = {
            name: place.name,
            icon: "place",
            latitude: place.latitude,
            longitude: place.longitude,
            radiusMetres: 50,
            smarterTimeImport: true,
          };
          await cloudDb.addPlace(placeToAdd);
        }

        const placesDb = await db.places.toArray();
        const activitiesDb = await db.activities.toArray();

        // Create maps for faster lookups
        const placeMap = new Map(placesDb.map((p) => [normalise(p.name), p]));
        activityMap = new Map(activitiesDb.map((a) => [normalise(a.name), a]));

        // Match the timeslot to the places and activities
        const timeslotsToAdd = [];
        let processedRows = 0;
        const CHUNK_SIZE = 1000;
        let counter = 0;
        const locationHistoryToAdd = [];
        for (const timeslot of timeslots) {
          const normalisedPlaceName = normalise(timeslot.placeName || "");
          const normalisedActivityName = normalise(timeslot.activityName || "");
          const place = normalisedPlaceName
            ? placeMap.get(normalisedPlaceName)
            : undefined;
          const activity = normalisedActivityName
            ? activityMap.get(normalisedActivityName)
            : undefined;

          processedRows++;
          if (activity) {
            const timeslotToAdd = {
              placeId: place?.id || undefined,
              activityId: activity.id,
              startTimestampMills: timeslot.startTimestampMills,
              endTimestampMills: timeslot.endTimestampMills,
              timezone: timeslot.timezone,
              smarterTimeImport: true,
              ...(importMetadata ? { metadata: timeslot.metadata } : {}),
            };
            timeslotsToAdd.push(timeslotToAdd);
          } else if (normalisedActivityName !== "") {
            console.warn(
              `Timeslot ${timeslot.startTimestampMills} - ${timeslot.endTimestampMills} for activity ${timeslot.activityName} not found in activities`
            );
          }

          if (importLocations && deriveLocations && place) {
            const derivedLocation = {
              timestampMills: timeslot.startTimestampMills,
              latitude: place.latitude,
              longitude: place.longitude,
              smarterTimeImport: true,
            };

            locationHistoryToAdd.push(derivedLocation);
          }

          counter++;
          if (counter % CHUNK_SIZE === 0) {
            await cloudDb.bulkAddTimeslots(timeslotsToAdd);
            if (locationHistoryToAdd.length > 0)
              await cloudDb.bulkAddLocationHistory(locationHistoryToAdd);

            setImportProgress(processedRows);

            timeslotsToAdd.length = 0;
            locationHistoryToAdd.length = 0;
            // Allow the UI to update
            await new Promise((resolve) => setTimeout(resolve, 0));

            // Clear the arrays for the next chunk
          }
        }

        // Handle any remaining data
        if (timeslotsToAdd.length > 0) {
          await cloudDb.bulkAddTimeslots(timeslotsToAdd);
        }
        if (locationHistoryToAdd.length > 0) {
          await cloudDb.bulkAddLocationHistory(locationHistoryToAdd);
        }
        setImportProgress(processedRows);

        // Import location history only if importLocations is true
        if (importLocations) {
          const locationHistoryToAdd = [];
          const LOCATION_CHUNK_SIZE = 1000;
          let counter = 0;
          for (const location of locationHistory) {
            processedRows++;
            if (processedRows % 100 === 0) {
              setImportProgress(processedRows);
            }
            const locationToAdd = {
              timestampMills: location.timestampMills,
              latitude: location.latitude,
              longitude: location.longitude,
              guessedActivity: location.guessedActivity,
              guessedActivityConfidence: location.guessedActivityConfidence,
              smarterTimeImport: true,
              ...(importMetadata ? { metadata: location.metadata } : {}),
            };
            locationHistoryToAdd.push(locationToAdd);

            counter++;
            if (counter % LOCATION_CHUNK_SIZE === 0) {
              await cloudDb.bulkAddLocationHistory(locationHistoryToAdd);
              setImportProgress(processedRows);
              // Allow the UI to update
              await new Promise((resolve) => setTimeout(resolve, 0));
              // Clear the array for the next chunk
              locationHistoryToAdd.length = 0;
            }
          }

          // Handle any remaining data
          if (locationHistoryToAdd.length > 0) {
            await cloudDb.bulkAddLocationHistory(locationHistoryToAdd);
          }
          setImportProgress(processedRows);
        }

        setAppInitialised(true);
      } catch (error) {
        console.error("Error parsing CSV:", error);
      } finally {
        setIsImporting(false);
        setShowWarning(false);
      }
    }
  };

  const handleImport = () => {
    setShowWarning(true);
  };

  return (
    <Paper elevation={2} sx={{ padding: 3, marginTop: 4 }}>
      <Typography variant="h5">SmarterTime Import</Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 3, textAlign: "left" }}>
        To import data from SmarterTime:
        <ol style={{ paddingLeft: "20px" }}>
          <li>Open the SmarterTime app on your device</li>
          <li>Navigate to the 'Data' section in the app</li>
          <li>Look for and tap on the 'Export' option</li>
          <li>
            Select the data you wish to export (ensure you include timeslots.csv
            and, if desired, geoloc.csv)
          </li>
          <li>Once exported, transfer the files to this device</li>
          <li>
            Use the file selection boxes below to choose the exported files
          </li>
        </ol>
      </Typography>
      <FormGroup>
        <Stack direction="column" spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={importLocations}
                onChange={(e) => setImportLocations(e.target.checked)}
              />
            }
            label={
              <Typography sx={{ fontSize: "1rem", textAlign: "left" }}>
                Import location data
              </Typography>
            }
          />
          {importLocations && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={deriveLocations}
                  onChange={(e) => setDeriveLocations(e.target.checked)}
                />
              }
              label={
                <Typography sx={{ fontSize: "1rem", textAlign: "left" }}>
                  Derive additional location information from timeslot data
                  (takes longer and uses more space)
                </Typography>
              }
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={importMetadata}
                onChange={(e) => setImportMetadata(e.target.checked)}
              />
            }
            label={
              <Typography sx={{ fontSize: "1rem", textAlign: "left" }}>
                Import metadata (uncheck to save space, but some data unrelated
                to DumberTime will be lost)
              </Typography>
            }
          />
        </Stack>
      </FormGroup>
      <Box
        {...getTimeslotsRootProps()}
        sx={{ border: "1px dashed grey", p: 2, my: 2 }}>
        <input {...getTimeslotsInputProps()} />
        <Typography>
          {timeslotsFile
            ? `File selected: ${timeslotsFile.name}`
            : "Drop timeslots.csv file here"}
        </Typography>
      </Box>
      {importLocations && (
        <Box
          {...getGeolocRootProps()}
          sx={{ border: "1px dashed grey", p: 2, my: 2 }}>
          <input {...getGeolocInputProps()} />
          <Typography>
            {geolocFile
              ? `File selected: ${geolocFile.name}`
              : "Drop geoloc.csv file here"}
          </Typography>
        </Box>
      )}

      {timeslotsFile && (importLocations ? geolocFile : true) && (
        <>
          <Button
            variant="contained"
            onClick={handleImport}
            disabled={isImporting}
            sx={{ mt: 2 }}>
            {isImporting ? "Importing..." : "Import"}
          </Button>
        </>
      )}
      {isImporting && (
        <Stack
          direction="column"
          spacing={2}
          marginTop={2}
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}>
          {importProgress > 0 ? (
            <>
              <LinearProgress
                variant="determinate"
                value={(importProgress / totalRows) * 100}
                sx={{ width: "100%", marginBottom: 1 }}
              />
              <Typography variant="body2" color="text.secondary">
                {importProgress} of {totalRows} rows imported
              </Typography>
            </>
          ) : (
            <CircularProgress />
          )}
          {totalRows > 0 && `Importing ${totalRows} rows`}
          <Typography variant="body2" color="text.secondary">
            Please do not close this page until the import is complete.
          </Typography>
        </Stack>
      )}
      {showWarning && !isImporting && (
        <Alert
          severity="warning"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={confirmImport}
              disabled={isImporting}>
              Confirm
            </Button>
          }>
          Warning: This action will erase all existing data and replace it with
          the imported data and disconnect your cloud backup.
        </Alert>
      )}
    </Paper>
  );
};

export default SmarterTimeImport;
