/**
 * Converts a string to title case.
 * @param str The input string to convert.
 * @returns The string converted to title case.
 */
export function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, (txt) => {
    const firstChar = txt.charAt(0);
    const restOfWord = txt.substr(1);
    return (
      firstChar.toUpperCase() + restOfWord.replace(/[A-Z]/g, (match) => match)
    );
  });
}
