import React from "react";
import { Formik, Form } from "formik";
import {
  Typography,
  Button,
  Stack,
  Autocomplete,
  TextField,
  Alert,
  Paper,
  Slider,
} from "@mui/material";
import { Activity } from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";

interface MergeActivityFormProps {
  activity: Activity;
  activities: Activity[];
  onCancel: () => void;
  onMerge: () => void;
}

export const MergeActivityForm: React.FC<MergeActivityFormProps> = ({
  activity,
  activities,
  onCancel,
  onMerge,
}) => {
  const cloudSyncedDB = useCloudSyncedDB();
  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
      <Typography variant="h6" gutterBottom>
        Merge Activity: {activity.name}
      </Typography>
      <Alert severity="warning" sx={{ marginBottom: 2 }}>
        Warning: This action is not reversible. All timeslots associated with
        this activity will be reassigned to the selected activity.
      </Alert>
      <Formik
        initialValues={{
          targetActivity: null as Activity | null,
          confirmMerge: 0,
        }}
        validate={(values) => {
          const errors: { targetActivity?: string; confirmMerge?: string } = {};
          if (!values.targetActivity) {
            errors.targetActivity = "Target activity is required";
          }
          if (values.confirmMerge < 90) {
            errors.confirmMerge =
              "Please confirm that you are at least 90% sure to merge";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.targetActivity && values.confirmMerge >= 90) {
            try {
              // Update all timeslots
              await cloudSyncedDB.updateTimeslotsForActivity(activity.id, {
                activityId: values.targetActivity.id,
              });

              // Delete the original activity
              await cloudSyncedDB.deleteActivity(activity.id);

              onMerge();
            } catch (error) {
              console.error("Error merging activity:", error);
              // Handle error (e.g., show an error message)
            }
          }
          setSubmitting(false);
        }}>
        {({ values, setFieldValue, errors, touched, isSubmitting }) => (
          <Form>
            <Stack spacing={2}>
              <Autocomplete
                options={activities.filter((a) => a.id !== activity.id)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Merge into"
                    error={touched.targetActivity && !!errors.targetActivity}
                    helperText={touched.targetActivity && errors.targetActivity}
                  />
                )}
                onChange={(_, value) => setFieldValue("targetActivity", value)}
              />
              <Typography gutterBottom>
                Are you sure you want to merge these activities?
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography>Hmmm...</Typography>
                <Slider
                  value={values.confirmMerge}
                  onChange={(_, newValue) =>
                    setFieldValue("confirmMerge", newValue)
                  }
                  aria-labelledby="confirm-merge-slider"
                  valueLabelDisplay="off"
                  step={1}
                  min={0}
                  max={100}
                />
                <Typography>Definitely!</Typography>
              </Stack>
              {touched.confirmMerge && errors.confirmMerge && (
                <Typography color="error" variant="caption">
                  {errors.confirmMerge}
                </Typography>
              )}
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button onClick={onCancel} variant="outlined">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    isSubmitting ||
                    values.confirmMerge < 90 ||
                    !values.targetActivity
                  }>
                  Merge
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
