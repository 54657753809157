import { useContext } from "react";
import { SettingsContext, SettingsContextType } from "./SettingsProvider";

export enum SettingsType {
  ShowLocation = "showLocation",
  SnapToMills = "snapToMills",
  DefaultZoom = "defaultZoom",
  DefaultTimeslotDuration = "defaultTimeslotDuration",
  LocationHistoryEndpoint = "locationHistoryEndpoint",
  CloudBackupEndpoint = "cloudBackupEndpoint",
  StartAnalyticsScreen = "startAnalyticsScreen",
  CloudSyncEnabled = "cloudSyncEnabled",
  LastCloudChangeTimestamp = "lastCloudChangeTimestamp",
  CloudSyncKey = "cloudSyncKey",
  AutoSyncLocationHistory = "autoSyncLocationHistory",
  AutoSyncBackups = "autoSyncBackups",
  SyncBatchSize = "syncBatchSize",
  DeveloperMode = "developerMode",
}

export const defaultSettings: Record<SettingsType, boolean | number | string> =
  {
    [SettingsType.ShowLocation]: true,
    [SettingsType.SnapToMills]: 1000 * 60,
    [SettingsType.DefaultZoom]: 50,
    [SettingsType.DefaultTimeslotDuration]: 1000 * 60 * 30,
    [SettingsType.LocationHistoryEndpoint]: "",
    [SettingsType.CloudBackupEndpoint]: "",
    [SettingsType.StartAnalyticsScreen]: "recents",
    [SettingsType.CloudSyncEnabled]: false,
    [SettingsType.LastCloudChangeTimestamp]: 0,
    [SettingsType.CloudSyncKey]: "",
    [SettingsType.AutoSyncLocationHistory]: true,
    [SettingsType.AutoSyncBackups]: true,
    [SettingsType.SyncBatchSize]: 5000,
    [SettingsType.DeveloperMode]: false,
  };

export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};
