import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Paper,
  Typography,
  Slider,
  Button,
  List,
  ListItem,
  ListItemText,
  Switch,
  Alert,
  Box,
  Stack,
} from "@mui/material";
import { Activity } from "../../../database/db";
import {
  getActivitiesOlderThan,
  bulkArchiveActivities,
} from "../../../utils/activity_management";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";

export const ActivityBulkArchive: React.FC<{ onCancel: () => void }> = ({
  onCancel,
}) => {
  const [daysAgo, setDaysAgo] = useState<number>(60);
  const [activities, setActivities] = useState<
    (Activity & {
      lastUsedTime: number;
      timeslotCount: number;
      toArchive: boolean;
    })[]
  >([]);

  const cloudSyncedDB = useCloudSyncedDB();

  const fetchActivities = async (days: number) => {
    console.log("Fetching activities older than", days, "days");
    const fetchedActivities = await getActivitiesOlderThan(days);
    setActivities(
      fetchedActivities.map((activity) => ({ ...activity, toArchive: true }))
    );
  };

  useEffect(() => {
    fetchActivities(daysAgo);
  }, [daysAgo]);

  const handleUpdateDaysAgo = useCallback((newDaysAgo: number) => {
    setDaysAgo(newDaysAgo);
  }, []);

  const handleActivityToggle = (id: string) => {
    setActivities(
      activities.map((activity) =>
        activity.id === id
          ? { ...activity, toArchive: !activity.toArchive }
          : activity
      )
    );
  };

  const handleArchive = async () => {
    const activityIdsToArchive = activities
      .filter((activity) => activity.toArchive)
      .map((activity) => activity.id);
    await bulkArchiveActivities(cloudSyncedDB, activityIdsToArchive);
    onCancel();
  };

  const allChecked = useMemo(
    () => activities.every((activity) => activity.toArchive),
    [activities]
  );

  const handleToggleAll = useCallback(() => {
    setActivities(
      activities.map((activity) => ({ ...activity, toArchive: !allChecked }))
    );
  }, [activities, allChecked]);

  const totalActivities = activities.length;
  const selectedActivities = activities.filter(
    (activity) => activity.toArchive
  ).length;

  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
      <Typography variant="h5" gutterBottom>
        Bulk Archive Activities
      </Typography>

      <Alert severity="info" sx={{ mb: 2 }}>
        Archived activities will not show up in the main search.
      </Alert>

      <Alert severity="warning" sx={{ mb: 2 }}>
        Warning: This action cannot be undone in bulk. However, you can
        individually unarchive activities on the data page if needed.
      </Alert>

      <DaysAgoSelector
        initialDaysAgo={daysAgo}
        onUpdate={handleUpdateDaysAgo}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent={"space-between"}
        alignItems={"center"}>
        <Typography gutterBottom>{daysAgo} days ago</Typography>

        <Typography gutterBottom>
          Total activities: {totalActivities}, Selected: {selectedActivities}
        </Typography>

        <Button onClick={handleToggleAll} variant="outlined">
          {allChecked ? "Uncheck All" : "Check All"}
        </Button>
      </Stack>

      <ActivityList
        activities={activities}
        onActivityToggle={handleActivityToggle}
      />

      <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleArchive}
          variant="contained"
          color="primary"
          disabled={!activities.some((activity) => activity.toArchive)}>
          Archive Selected Activities
        </Button>
      </Box>
    </Paper>
  );
};

const DaysAgoSelector: React.FC<{
  initialDaysAgo: number;
  onUpdate: (newDaysAgo: number) => void;
}> = React.memo(({ initialDaysAgo, onUpdate }) => {
  const [sliderValue, setSliderValue] = useState(initialDaysAgo);

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  };

  const handleUpdate = () => {
    onUpdate(sliderValue);
  };

  return (
    <>
      <Typography gutterBottom>Select activities older than:</Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Slider
          value={sliderValue}
          onChange={handleSliderChange}
          aria-labelledby="days-ago-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={30}
          max={90}
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <Button variant="contained" onClick={handleUpdate}>
          Update
        </Button>
      </Box>
    </>
  );
});

const ActivityList: React.FC<{
  activities: (Activity & {
    lastUsedTime: number;
    timeslotCount: number;
    toArchive: boolean;
  })[];
  onActivityToggle: (id: string) => void;
}> = React.memo(({ activities, onActivityToggle }) => (
  <List>
    {activities.map((activity) => (
      <ListItem key={activity.id} divider>
        <ListItemText
          primary={activity.name}
          secondary={`Last used: ${new Date(
            activity.lastUsedTime
          ).toLocaleDateString()}`}
        />
        <Switch
          edge="end"
          onChange={() => onActivityToggle(activity.id)}
          checked={activity.toArchive}
        />
      </ListItem>
    ))}
  </List>
));
