export const MATERIAL_COLOURS: Record<string, string> = {
  red: "#F44336",
  pink: "#F48FB1",
  purple: "#9C27B0",
  deep_purple: "#9575CD",
  indigo: "#3F51B5",
  blue: "#2196F3",
  light_blue: "#4FC3F7",
  cyan: "#00BCD4",
  teal: "#009688",
  green: "#4CAF50",
  light_green: "#9CCC65",
  lime: "#DCE775",
  yellow: "#FDD835",
  amber: "#FFB300",
  orange: "#FB8C00",
  deep_orange: "#FF5722",
  brown: "#5D4037",
  grey: "#616161",
  slate: "#607D8B",
  light_grey: "#E0E0E0",
};

export const MATERIAL_COLOUR_ARRAY: { name: string; hex: string }[] =
  Object.keys(MATERIAL_COLOURS).map((key) => ({
    name: key,
    hex: MATERIAL_COLOURS[key],
  }));

export const PRIMARY_COLOUR = "#018979";
export const SECONDARY_COLOUR = "#E2EB98";
export const TERTIARY_COLOUR = "#FF7B9C";
export const QUATERNARY_COLOUR = "#89AAE6";
export const QUINARY_COLOUR = "#EB8258";
export const GREY_COLOUR = "#999999";

export const LOCATION_COLORS = [
  "#FF6B6B", // Coral Red
  "#4ECDC4", // Caribbean Green
  "#FFA07A", // Light Salmon
  "#45B7D1", // Sky Blue
  "#98D8C8", // Mint
  "#FFD700", // Gold
  "#9370DB", // Medium Purple
  "#20B2AA", // Light Sea Green
  "#FF69B4", // Hot Pink
  "#8FBC8F", // Dark Sea Green
];

export const DEV_COLOUR = "#CC00CC";
