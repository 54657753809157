import React, { createContext, useState, useCallback, ReactNode } from "react";
import { AlertColor } from "@mui/material";
import { GlobalSnackbar } from "../page/shared/GlobalSnackbar";

interface SnackbarContextType {
  showSnackbar: (
    message: string | ReactNode,
    severity?: AlertColor,
    autoHideDuration?: number
  ) => void;
}

export const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

interface SnackbarProviderProps {
  children: React.ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | ReactNode>("");
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [autoHideDuration, setAutoHideDuration] = useState<number>(6000);
  const showSnackbar = useCallback(
    (
      message: string | ReactNode,
      severity: AlertColor = "info",
      autoHideDuration: number = 6000
    ) => {
      setMessage(message);
      setSeverity(severity);
      setAutoHideDuration(autoHideDuration);
      setOpen(true);
    },
    []
  );

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <GlobalSnackbar
        open={open}
        message={message}
        severity={severity}
        onClose={() => {
          setOpen(false);
        }}
        autoHideDuration={autoHideDuration}
      />
    </SnackbarContext.Provider>
  );
};
