import styled from "@emotion/styled";
import { format } from "date-fns";
import React from "react";

interface CurrentTimeLineProps {
  dayStartMills: number;
  pxPerMs: number;
  showTime: boolean;
  currentTimeMills: number;
}

const Line = styled.div<{ top: number }>`
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ff0000;
  top: ${(props) => props.top}px;
  z-index: 1000;
`;

const TimeLabel = styled.div`
  position: absolute;
  right: 5px;
  background-color: #ff0000;
  color: white;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 2px;
  transform: translateY(-50%);
`;

export const CurrentTimeLine: React.FC<CurrentTimeLineProps> = ({
  dayStartMills,
  pxPerMs,
  currentTimeMills,
  showTime,
}) => {
  const top = (currentTimeMills - dayStartMills) * pxPerMs;
  const timeString = format(new Date(currentTimeMills), "HH:mm");

  if (currentTimeMills === 0) {
    return null;
  }
  return (
    <Line className="current-time-line" top={top}>
      {showTime && <TimeLabel>{timeString}</TimeLabel>}
    </Line>
  );
};
