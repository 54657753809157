import { Box, Typography } from "@mui/material";

type SpecialKey =
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowUp"
  | "ArrowDown"
  | "Enter"
  | "Backspace"
  | "Delete"
  | "Escape"
  | "Tab"
  | "Space";

type AlphaNumeric =
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F"
  | "G"
  | "H"
  | "I"
  | "J"
  | "K"
  | "L"
  | "M"
  | "N"
  | "O"
  | "P"
  | "Q"
  | "R"
  | "S"
  | "T"
  | "U"
  | "V"
  | "W"
  | "X"
  | "Y"
  | "Z"
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9";

type Symbol =
  | "!"
  | "@"
  | "#"
  | "$"
  | "%"
  | "^"
  | "&"
  | "*"
  | "("
  | ")"
  | "-"
  | "_"
  | "+"
  | "="
  | "["
  | "]"
  | "{"
  | "}"
  | ";"
  | ":"
  | ","
  | "."
  | "<"
  | ">"
  | "/"
  | "?"
  | "|"
  | "\\"
  | "`"
  | "~"
  | '"'
  | "'";

type ValidKey = SpecialKey | AlphaNumeric | Symbol;

interface KeyIconProps {
  keyName: ValidKey;
}

export const KeyIcon = ({ keyName }: KeyIconProps) => {
  const displayName = (() => {
    switch (keyName) {
      case "ArrowLeft":
        return "←";
      case "ArrowRight":
        return "→";
      case "ArrowUp":
        return "↑";
      case "ArrowDown":
        return "↓";
      case "Enter":
        return "↵";
      case "Backspace":
        return "⌫";
      case "Delete":
        return "Del";
      case "Escape":
        return "Esc";
      case "Tab":
        return "⇥";
      case "Space":
        return "␣";
      default:
        return keyName;
    }
  })();

  return (
    <Box
      component="span"
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #cccccc99",
        borderRadius: "3px",
        padding: "2px 2px",
        minWidth: "24px",
        mx: 0.5,
      }}>
      <Typography
        component="span"
        sx={{
          fontSize: "0.8em",
          fontFamily: "monospace",
          textTransform: "uppercase",
        }}>
        {displayName}
      </Typography>
    </Box>
  );
};
