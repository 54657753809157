const customRenderers = {
  h1: ({ children }: { children: React.ReactNode }) => {
    const id = children?.toString().toLowerCase().replace(/\s+/g, "-");
    return (
      <>
        <a id={id}></a>
        <h1>{children}</h1>
      </>
    );
  },
  h2: ({ children }: { children: React.ReactNode }) => {
    const id = children?.toString().toLowerCase().replace(/\s+/g, "-");
    return (
      <>
        <a id={id}></a>
        <h2>{children}</h2>
      </>
    );
  },
};

export default customRenderers;
