import { TZDate } from "@date-fns/tz";
import { addDays, format } from "date-fns";

export const dateStringToDayBoundsMills = (
  date: string,
  timezone: string
): [number, number] | null => {
  try {
    const [year, month, day] = date.split("-").map((s) => parseInt(s));
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return null;
    }
    const dS = new TZDate(year, month - 1, day, 0, 0, 0, 0, timezone);
    const dE = addDays(dS, 1);
    if (isNaN(dS.getTime()) || isNaN(dE.getTime())) {
      return null;
    }
    return [dS.getTime(), dE.getTime()];
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getComputerTimezone = (): string => {
  const timezone = format(new Date(), "xxx"); // +02:00
  return timezone;
};

export const timeDisplay = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  if (hours > 0 && mins > 0) {
    return `${hours}h ${mins}m`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else {
    return `${mins}m`;
  }
};

export const timeOfDay = (mills: number) => {
  return format(mills, "HH:mm");
};
