import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo } from "react";
import { db } from "../database/db";
import {
  ActivityJoinCategory,
  getActivityJoinCategoryMap,
} from "../database/helpers";
import useStorageBackedState from "./useStorageBackedState";

interface ActivityUsageCount {
  [activityId: string]: number;
}

interface ActivityJoinCategoryWithUsage extends ActivityJoinCategory {
  usageCount?: number;
}

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds
const STORAGE_KEY = "activityUsageCount";

export const useActivityJoinCategory = () => {
  const activities = useLiveQuery(() => db.activities.toArray(), []);
  const categories = useLiveQuery(() => db.categories.toArray(), []);
  const [activityUsageCount, setActivityUsageCount] =
    useStorageBackedState<ActivityUsageCount>({}, STORAGE_KEY);
  const [lastUpdate, setLastUpdate] = useStorageBackedState<number>(
    0,
    "lastUpdateActivityUsageCount"
  );

  const fetchTimeslots = useCallback(async () => {
    const sixtyDaysAgo = new Date();
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
    const recentTimeslots = await db.timeslots
      .where("startTimestampMills")
      .aboveOrEqual(sixtyDaysAgo.getTime())
      .toArray();

    const newActivityUsageCount = recentTimeslots.reduce((acc, timeslot) => {
      acc[timeslot.activityId] = (acc[timeslot.activityId] || 0) + 1;
      return acc;
    }, {} as ActivityUsageCount);

    setActivityUsageCount(newActivityUsageCount);
    setLastUpdate(Date.now());
  }, [setActivityUsageCount, setLastUpdate]);

  useEffect(() => {
    const now = Date.now();
    if (now - lastUpdate > CACHE_DURATION) {
      fetchTimeslots();
    }
  }, [fetchTimeslots, lastUpdate, activities, categories]);

  const activityCategoryMap = useMemo<
    Record<string, ActivityJoinCategoryWithUsage>
  >(() => {
    if (!activities || !categories) return {};
    const map = getActivityJoinCategoryMap(activities, categories) as Record<
      string,
      ActivityJoinCategoryWithUsage
    >;
    // Add usage count to the map
    for (const mapId in map) {
      const activity = map[mapId];
      activity.usageCount = activityUsageCount[activity.id] || 0;
    }
    // Remove activities that are archived
    Object.keys(map).forEach((key) => {
      if (map[key].archived) {
        delete map[key];
      }
    });
    return map;
  }, [activities, categories, activityUsageCount]);

  const activityCategoryArray = useMemo(() => {
    const array = Object.values(activityCategoryMap);
    array.sort((a, b) => {
      const usageCountDiff = (b.usageCount || 0) - (a.usageCount || 0);
      if (usageCountDiff !== 0) return usageCountDiff;
      return a.name.localeCompare(b.name);
    });
    return array;
  }, [activityCategoryMap]);

  const refreshData = useCallback(() => {
    fetchTimeslots();
  }, [fetchTimeslots]);

  return {
    activities,
    activityCategoryArray,
    activityCategoryMap,
    refreshData,
  };
};
