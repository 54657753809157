import { Activity, db } from "../database/db";
import { CloudSyncedDB } from "../hooks/useCloudSyncedDb";

export const getAllActivityMetadata = async (
  daysAgo: number = 60
): Promise<Record<string, { lastUsedTime: number; timeslotCount: number }>> => {
  const timeAgo = Date.now() - daysAgo * 24 * 60 * 60 * 1000;
  const timeslots = await db.timeslots
    .where("startTimestampMills")
    .aboveOrEqual(timeAgo)
    .sortBy("startTimestampMills");

  const result: {
    [key: string]: {
      lastUsedTime: number;
      timeslotCount: number;
    };
  } = {};
  timeslots.forEach((timeslot) => {
    if (!result[timeslot.activityId]) {
      result[timeslot.activityId] = {
        lastUsedTime: timeslot.startTimestampMills,
        timeslotCount: 1,
      };
    } else {
      result[timeslot.activityId].lastUsedTime = timeslot.startTimestampMills;
      result[timeslot.activityId].timeslotCount += 1;
    }
  });

  return result;
};

export const getActivitiesOlderThan = async (
  daysAgo: number
): Promise<(Activity & { lastUsedTime: number; timeslotCount: number })[]> => {
  const activities = await db.activities.toArray();
  const activityMetadata = await getAllActivityMetadata(daysAgo);

  const unusedActivities = activities
    .map((activity) => ({
      ...activity,
      lastUsedTime: activityMetadata[activity.id]?.lastUsedTime,
      timeslotCount: activityMetadata[activity.id]?.timeslotCount,
    }))
    .filter((activity) => !activity.timeslotCount && !activity.lastUsedTime);

  unusedActivities.sort((a, b) => {
    if (a.name < b.name) return -1;
    return 1;
  });
  return unusedActivities;
};

export const bulkArchiveActivities = async (
  cloudSyncedDB: CloudSyncedDB,
  activityIds: string[]
) => {
  const activities = await db.activities.bulkGet(activityIds);
  const archivedActivities = activities
    .filter((activity) => !!activity)
    .map((activity) => ({
      key: activity.id,
      changes: {
        archived: true,
      },
    }));

  await cloudSyncedDB.bulkUpdateActivities(archivedActivities);
};
