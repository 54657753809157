import { useCallback, useEffect, useMemo, useState } from "react";

function useSessionBackedState<T>(
  defaultValue: T,
  storageKey: string | false,
  storeDefaultIfNotStored = false
) {
  const initialValueString = useMemo(
    () => storageKey && sessionStorage.getItem(storageKey),
    [storageKey]
  );
  const initialValue =
    initialValueString && initialValueString !== "undefined"
      ? JSON.parse(initialValueString)
      : defaultValue;
  const [value, setValue] = useState<T>(initialValue);

  const onSetValue = useCallback(
    (newValue: T | ((previous: T) => T)) => {
      let valueToStore = newValue;
      if (newValue instanceof Function) {
        setValue((previous) => {
          const outputValue = newValue(previous);
          valueToStore = outputValue;
          if (storageKey) {
            sessionStorage.setItem(storageKey, JSON.stringify(valueToStore));
          }
          return outputValue;
        });
      } else {
        setValue(newValue);
        if (storageKey) {
          sessionStorage.setItem(storageKey, JSON.stringify(valueToStore));
        }
      }
    },
    [storageKey]
  );

  useEffect(() => {
    if (
      storeDefaultIfNotStored &&
      storageKey &&
      !sessionStorage.keys().includes(storageKey)
    ) {
      onSetValue(defaultValue);
    }
  }, [defaultValue, onSetValue, storageKey, storeDefaultIfNotStored]);

  return useMemo(
    () => [value, onSetValue] as [typeof value, typeof onSetValue],
    [onSetValue, value]
  );
}

export const useClearSessionBackedState = () => {
  return useCallback(
    (storageKey: string) => sessionStorage.removeItem(storageKey),
    []
  );
};

export default useSessionBackedState;
