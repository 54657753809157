import React, { createContext, ReactNode, useMemo, useState } from "react";
import { TimeslotChange } from "../utils/conflict_management";

export interface UndoRedoContextType {
  popUndoSteps: () => TimeslotChange[] | null;
  pushUndoSteps: (
    step: TimeslotChange | TimeslotChange[],
    clearRedo: boolean
  ) => void;
  pushRedoSteps: (step: TimeslotChange | TimeslotChange[]) => void;
  popRedoSteps: () => TimeslotChange[] | null;
  totalUndoSteps: number;
  totalRedoSteps: number;
}

export const UndoRedoContext = createContext<UndoRedoContextType | undefined>(
  undefined
);

export const UndoRedoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // Stored as a 2D array to allow for multiple operations per step
  const [undoSteps, setUndoSteps] = useState<TimeslotChange[][]>([]);
  const [redoSteps, setRedoSteps] = useState<TimeslotChange[][]>([]);

  const popUndoStep = (): TimeslotChange[] | null => {
    if (undoSteps.length === 0) {
      return null;
    }
    const step = undoSteps[undoSteps.length - 1];
    setUndoSteps(undoSteps.slice(0, undoSteps.length - 1));

    return step;
  };

  const pushUndoStep = (
    step: TimeslotChange | TimeslotChange[],
    clearRedo: boolean = true
  ) => {
    if (Array.isArray(step)) {
      setUndoSteps([...undoSteps, step]);
    } else {
      setUndoSteps([...undoSteps, [step]]);
    }
    if (clearRedo) {
      setRedoSteps([]);
    }
  };

  const pushRedoStep = (step: TimeslotChange | TimeslotChange[]) => {
    if (Array.isArray(step)) {
      setRedoSteps([...redoSteps, step]);
    } else {
      setRedoSteps([...redoSteps, [step]]);
    }
  };

  const popRedoStep = (): TimeslotChange[] | null => {
    if (redoSteps.length === 0) {
      return null;
    }
    const step = redoSteps[redoSteps.length - 1];
    setRedoSteps(redoSteps.slice(0, redoSteps.length - 1));

    return step;
  };

  const totalUndoSteps = useMemo(() => {
    return undoSteps.length;
  }, [undoSteps]);

  const totalRedoSteps = useMemo(() => {
    return redoSteps.length;
  }, [redoSteps]);

  return (
    <UndoRedoContext.Provider
      value={{
        popUndoSteps: popUndoStep,
        pushUndoSteps: pushUndoStep,
        pushRedoSteps: pushRedoStep,
        popRedoSteps: popRedoStep,
        totalUndoSteps,
        totalRedoSteps,
      }}>
      {children}
    </UndoRedoContext.Provider>
  );
};
