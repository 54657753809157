import { Container } from "@mui/material";
import ReactMarkdown, { Components } from "react-markdown";
import about from "../../public/about.md?raw";
import "../assets/markdown.css";
import customRenderers from "../utils/react_markdown_renderer";

export const About = () => {
  return (
    <Container sx={{ paddingY: "50px" }} className="markdown-content">
      <ReactMarkdown components={customRenderers as Partial<Components>}>
        {about}
      </ReactMarkdown>
    </Container>
  );
};
