import React from "react";
import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import { timeDisplay } from "../../../utils/time";

const BlankTimeslotRowStyled = styled(Box)`
  position: absolute;
  left: 50px;
  width: calc(100% - 120px);
  z-index: 1;
  box-sizing: border-box;
  opacity: 1;
  background-color: #eeeeee88;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

interface BlankTimeslotRowProps {
  startTimestampMills: number;
  endTimestampMills: number;
  dayBounds: number[];
  pxPerMs: number;
  onMouseEnter: () => void;
  onClick: () => void;
}

const BlankTimeslotRow: React.FC<BlankTimeslotRowProps> = ({
  startTimestampMills,
  endTimestampMills,
  dayBounds,
  pxPerMs,
  onMouseEnter,
  onClick,
}) => {
  const top = (startTimestampMills - dayBounds[0]) * pxPerMs;
  const height = (endTimestampMills - startTimestampMills) * pxPerMs;
  const minutes = (endTimestampMills - startTimestampMills) / 1000 / 60;

  return (
    <BlankTimeslotRowStyled
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      sx={{
        top: `${top}px`,
        height: `${height}px`,
      }}>
      <Stack direction="row" padding={1} gap={1} justifyContent="flex-end">
        <Typography color="#888888" fontStyle="italic">
          {timeDisplay(minutes)}
        </Typography>
      </Stack>
    </BlankTimeslotRowStyled>
  );
};

export default BlankTimeslotRow;
