import styled from "@emotion/styled";
import {
  Add,
  AddAlarmOutlined,
  ChevronLeftOutlined,
  Edit,
  Map,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocationHistory, Place } from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";
import { getPlacesForLocation } from "../../../utils/location";
import { LocationTimeBlock } from "../../../utils/location_history_processing";
import { CurrentTimeLine } from "./CurrentTimeLine";
import { LocationHistoryMark } from "./LocationHistoryMark";
import LocationTimeBlockComponent from "./LocationTimeBlockComponent";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { NewTimeslotParams } from "./AddTimeslotComponents";
interface LocationColumnProps {
  dayBounds: [number, number];
  pxPerMs: number;
  currentTimeMills: number;
  locationHistory: LocationHistory[] | undefined;
  locationTimeBlocks: LocationTimeBlock[];
  applyPlaceToTimeslots: (
    locationTimeBlock: LocationTimeBlock,
    place: Place
  ) => void;
  setNewTimeslotParams: (params: NewTimeslotParams) => void;
}

const Column = styled(Stack)`
  position: relative;
`;

const LocationMenu = styled(Box)`
  position: absolute;
  left: calc(-320px + 20px);
  width: 320px;
  background-color: transparent;
  z-index: 9999;
  padding-right: 15px;
  padding-left: 15px;

  transition: opacity 0.25s, left 0.25s, top 0.1s;
  pointer-events: none;

  opacity: 0;

  &.visible {
    opacity: 1;
    left: -320px;
    pointer-events: auto;
    transition: opacity 0.1s, left 0.1s, top 0.1s;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 5px 15px;
  span {
    flex-grow: 1;
    text-align: center;
  }
`;

interface WideButtonProps {
  tooltipText?: React.ReactNode;
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}

const WideButton: React.FC<WideButtonProps> = ({
  tooltipText,
  icon,
  text,
  onClick,
}) => {
  const button = (
    <StyledButton onClick={onClick}>
      {icon} <span>{text}</span>
    </StyledButton>
  );

  return tooltipText ? (
    <Tooltip
      title={tooltipText}
      placement="left"
      arrow
      slotProps={{
        popper: {
          sx: { zIndex: 9999 },
        },
      }}>
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export const LocationHistoryColumn: React.FC<LocationColumnProps> = ({
  dayBounds,
  pxPerMs,
  currentTimeMills,
  locationHistory,
  locationTimeBlocks,
  applyPlaceToTimeslots,
  setNewTimeslotParams,
}) => {
  const [lastHoveredLocationTimeBlock, setLastHoveredLocationTimeBlock] =
    useState<LocationTimeBlock | null>(null);
  const [hoveredLocationTimeBlock, setHoveredLocationTimeBlock] =
    useState<LocationTimeBlock | null>(null);
  const [hoveredLocationMenu, setHoveredLocationMenu] =
    useState<boolean>(false);
  const [hoverLocationMenuPosition, setHoverLocationMenuPosition] = useState<
    number | null
  >(null);
  const [cursorYPos, setCursorYPos] = useState(0);

  const cloudDb = useCloudSyncedDB();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hoveredLocationMenu && !hoveredLocationTimeBlock) {
      return;
    }
    if (hoveredLocationMenu) return;
    setHoverLocationMenuPosition(cursorYPos - 35);
  }, [
    cursorYPos,
    hoveredLocationMenu,
    hoveredLocationTimeBlock,
    setHoverLocationMenuPosition,
  ]);

  const [placeMap, setPlaceMap] = useState<Record<string, Place>>({});

  const currentLocationTimeBlockPlace = useMemo(() => {
    if (!lastHoveredLocationTimeBlock) return null;
    return placeMap[lastHoveredLocationTimeBlock.locationId.toString()];
  }, [lastHoveredLocationTimeBlock, placeMap]);

  useEffect(() => {
    const fetchPlaces = async () => {
      for (const block of locationTimeBlocks) {
        const fetchedPlaces = await getPlacesForLocation(
          block.latitude,
          block.longitude
        );
        if (fetchedPlaces.length > 0) {
          setPlaceMap((prev) => {
            const newMap = { ...prev };
            newMap[block.locationId.toString()] = fetchedPlaces[0];
            return newMap;
          });
        }
      }
    };
    fetchPlaces();
  }, [locationTimeBlocks, cloudDb]);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setCursorYPos(e.clientY - rect.top);
  };

  const viewArea = useCallback(
    (locationTimeBlock: LocationTimeBlock | null) => {
      if (!locationTimeBlock) return;
      // Navigate to the map page with the placeId as a search param
      navigate(
        `/data?lat=${locationTimeBlock.latitude}&lon=${locationTimeBlock.longitude}&radius=1000`
      );
    },
    [navigate]
  );

  const viewOnMap = useCallback(
    (locationTimeBlock: LocationTimeBlock | null) => {
      if (locationTimeBlock) {
        // Open location in Google Maps
        const url = `https://www.google.com/maps/search/?api=1&query=${locationTimeBlock.latitude},${locationTimeBlock.longitude}`;
        window.open(url, "_blank");
      }
    },
    []
  );

  const editPlace = useCallback(
    (place: Place) => {
      // Navigate to the data page with the placeId as a search param
      navigate(`/data?placeId=${place.id}#places`);
    },
    [navigate]
  );
  const addPlace = useCallback(
    async (locationTimeBlock: LocationTimeBlock | null) => {
      if (!locationTimeBlock) return;
      // Get a name for the place using window.prompt
      const name = window.prompt("Enter a name for the place");
      if (!name) return;
      const newPlace = {
        name,
        latitude: locationTimeBlock.latitude,
        longitude: locationTimeBlock.longitude,
        radiusMetres: 50,
        icon: "place",
      };
      // Add the place to the database
      await cloudDb.addPlace(newPlace);
    },
    [cloudDb]
  );

  const locationMenuVisible = useMemo(() => {
    return (
      (hoveredLocationTimeBlock || hoveredLocationMenu) &&
      hoverLocationMenuPosition !== null
    );
  }, [
    hoveredLocationTimeBlock,
    hoveredLocationMenu,
    hoverLocationMenuPosition,
  ]);

  return (
    <Column
      width="50px"
      direction="column"
      sx={{
        border: "1px solid #aaaaaa",
      }}
      onMouseMove={handleMouseMove}>
      {dayBounds &&
        locationHistory?.map((location) => (
          <LocationHistoryMark
            key={location.timestampMills + location.id}
            timestampMills={location.timestampMills}
            dayStartMills={dayBounds[0]}
            pxPerMs={pxPerMs}
          />
        ))}
      {locationTimeBlocks.map((locationTimeBlock, index) => (
        <LocationTimeBlockComponent
          key={index}
          block={locationTimeBlock}
          place={placeMap[locationTimeBlock.locationId.toString()]}
          dayStartMills={dayBounds[0]}
          pxPerMs={pxPerMs}
          onMouseEnter={() => {
            setHoveredLocationTimeBlock(locationTimeBlock);
            setLastHoveredLocationTimeBlock(locationTimeBlock);
          }}
          onMouseLeave={() => {
            setHoveredLocationTimeBlock(null);
          }}
          onClick={() => {}}
        />
      ))}
      <LocationMenu
        className={locationMenuVisible ? "visible" : ""}
        onMouseEnter={() => {
          setHoveredLocationMenu(true);
        }}
        onMouseLeave={() => {
          setHoveredLocationMenu(false);
        }}
        sx={{
          top: `${hoverLocationMenuPosition}px`,
        }}>
        <Paper>
          <Stack direction="column" gap={1}>
            {currentLocationTimeBlockPlace ? (
              <>
                <Typography variant="body1" padding={2}>
                  {currentLocationTimeBlockPlace.name}
                </Typography>
                <WideButton
                  tooltipText="Create a new timeslot"
                  icon={<AddAlarmOutlined />}
                  text="Create Timeslot"
                  onClick={() => {
                    setHoveredLocationMenu(false);
                    if (!lastHoveredLocationTimeBlock) return;
                    setNewTimeslotParams({
                      startMs: lastHoveredLocationTimeBlock.startMills,
                      endMs: lastHoveredLocationTimeBlock.endMills,
                      activityId: "",
                    });
                  }}
                />
                <WideButton
                  tooltipText="Apply this place to all timeslots overlapping this time range"
                  icon={<ChevronLeftOutlined />}
                  text="Apply"
                  onClick={() => {
                    setHoveredLocationMenu(false);
                    if (!lastHoveredLocationTimeBlock) return;
                    applyPlaceToTimeslots(
                      lastHoveredLocationTimeBlock,
                      currentLocationTimeBlockPlace
                    );
                  }}
                />
                <Divider />
                <WideButton
                  icon={<Edit />}
                  text="Edit Place"
                  onClick={() => {
                    editPlace(currentLocationTimeBlockPlace);
                    setHoveredLocationMenu(false);
                  }}
                />
                <WideButton
                  tooltipText="Search nearby places in dumbertime"
                  icon={<Map />}
                  text="See Nearby Places"
                  onClick={() => {
                    viewArea(lastHoveredLocationTimeBlock);
                    setHoveredLocationMenu(false);
                  }}
                />
                <WideButton
                  tooltipText={
                    <>
                      Open in Google Maps (new tab)
                      <br />
                      <em>I can't afford to pay for Google Maps API</em>
                    </>
                  }
                  icon={<OpenInNewOutlinedIcon />}
                  text="Open Map"
                  onClick={() => {
                    viewOnMap(lastHoveredLocationTimeBlock);
                    setHoveredLocationMenu(false);
                  }}
                />
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  fontStyle={"italic"}
                  color={"#777"}
                  padding={2}>
                  Unknown
                </Typography>

                <WideButton
                  tooltipText="Create a new timeslot with this time range"
                  icon={<AddAlarmOutlined />}
                  text="Create Timeslot"
                  onClick={() => {
                    setHoveredLocationMenu(false);
                    if (!lastHoveredLocationTimeBlock) return;
                    setNewTimeslotParams({
                      startMs: lastHoveredLocationTimeBlock.startMills,
                      endMs: lastHoveredLocationTimeBlock.endMills,
                      activityId: "",
                    });
                  }}
                />
                <Divider />
                <WideButton
                  tooltipText="Add a new place at this location"
                  icon={<Add />}
                  text="Add Place"
                  onClick={() => {
                    addPlace(lastHoveredLocationTimeBlock);
                    setHoveredLocationMenu(false);
                  }}
                />
                <WideButton
                  tooltipText="Search nearby places in dumbertime"
                  icon={<Map />}
                  text="See Nearby Places"
                  onClick={() => {
                    viewArea(lastHoveredLocationTimeBlock);
                    setHoveredLocationMenu(false);
                  }}
                />
                <WideButton
                  tooltipText={
                    <>
                      Open in Google Maps (new tab)
                      <br />
                      <em>I can't afford to pay for Google Maps API</em>
                    </>
                  }
                  icon={<OpenInNewOutlinedIcon />}
                  text="Open Map"
                  onClick={() => {
                    viewOnMap(lastHoveredLocationTimeBlock);
                    setHoveredLocationMenu(false);
                  }}
                />
              </>
            )}
          </Stack>
        </Paper>
      </LocationMenu>

      <CurrentTimeLine
        dayStartMills={dayBounds[0]}
        pxPerMs={pxPerMs}
        currentTimeMills={currentTimeMills}
        showTime={false}
      />
    </Column>
  );
};
