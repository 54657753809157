import {
  AccessTime,
  AccountBalance,
  AccountBalanceWallet,
  AcUnit,
  AddReaction,
  AirlineSeatFlat,
  AlarmOn,
  AutoStories,
  AvTimer,
  BackHand,
  BakeryDining,
  Bathtub,
  Bed,
  Bedtime,
  BusinessCenter,
  Cake,
  Call,
  Camera,
  CameraAlt,
  CameraRoll,
  CarRental,
  Celebration,
  Chair,
  Chalet,
  Church,
  Code,
  Comment,
  Commute,
  Computer,
  Cookie,
  Coronavirus,
  Countertops,
  Description,
  DesignServices,
  DinnerDining,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DirectionsRun,
  DirectionsTransit,
  DirectionsWalk,
  Diversity3,
  DownhillSkiing,
  Draw,
  EditNote,
  Emergency,
  EmojiNature,
  EmojiPeople,
  Error,
  Face2,
  FaceRetouchingNatural,
  FamilyRestroom,
  Fastfood,
  Favorite,
  FavoriteBorder,
  FitnessCenter,
  Flatware,
  Flight,
  FormatPaint,
  Groups,
  Hiking,
  Home,
  Hotel,
  HourglassEmpty,
  IceSkating,
  ImportContacts,
  Iron,
  Lightbulb,
  LocalActivity,
  LocalBar,
  LocalCafe,
  LocalDining,
  LocalFireDepartment,
  LocalGasStation,
  LocalGroceryStore,
  LocalHospital,
  LocalLaundryService,
  LocalLibrary,
  MediaBluetoothOn,
  Medication,
  Movie,
  Museum,
  Newspaper,
  NoteAlt,
  Park,
  PeopleOutline,
  Pets,
  PhoneEnabled,
  Place,
  PlayCircle,
  Pool,
  Restaurant,
  RollerSkating,
  Sailing,
  SelfImprovement,
  ShapeLine,
  ShoppingBag,
  Shower,
  Skateboarding,
  Snowboarding,
  Spa,
  Sports,
  SportsEsports,
  SportsGymnastics,
  Subway,
  Terminal,
  TheaterComedy,
  Tour,
  Train,
  Translate,
  Tv,
  TwoWheeler,
  ViewCarousel,
  Weekend,
  Work,
  Yard,
  Healing,
  Brush,
  MusicNote,
  RecordVoiceOver,
  Wc,
} from "@mui/icons-material";

export const ICONS: Record<string, typeof AccountBalance> = {
  // Media and Entertainment
  tv: Tv,
  movie: Movie,
  theater_comedy: TheaterComedy,
  game_controller: SportsEsports,
  play_circle: PlayCircle,
  newspaper: Newspaper,
  carousel: ViewCarousel,

  // Education and Learning
  book: ImportContacts,
  book_flip: AutoStories,
  library: LocalLibrary,
  translate: Translate,

  // Personal Care and Health
  bathtub: Bathtub,
  shower: Shower,
  spa: Spa,
  fitness_center: FitnessCenter,
  medication: Medication,
  red_cross: LocalHospital,
  beauty_face: FaceRetouchingNatural,
  meditation: SelfImprovement,
  healing: Healing,
  toilet: Wc,

  // Home and Household
  home: Home,
  bed: Bed,
  bedtime: Bedtime,
  chair: Chair,
  chalet: Chalet,
  countertops: Countertops,
  iron: Iron,
  laundry: LocalLaundryService,
  yard: Yard,

  // Work and Business
  work: Work,
  briefcase: BusinessCenter,
  computer: Computer,
  code: Code,
  terminal: Terminal,
  description: Description,
  note: NoteAlt,
  edit_note: EditNote,

  // Social and Communication
  add_reaction: AddReaction,
  favorite: Favorite,
  favourite_border: FavoriteBorder,
  face: Face2,
  family_restroom: FamilyRestroom,
  people: PeopleOutline,
  diversity: Diversity3,
  groups: Groups,
  comment: Comment,
  call: Call,
  phone_enabled: PhoneEnabled,
  celebration: Celebration,
  person: EmojiPeople,
  backhand: BackHand,

  // Sports and Activities
  sports: Sports,
  gymnastics: SportsGymnastics,
  hiking: Hiking,
  ski: DownhillSkiing,
  skateboarding: Skateboarding,
  iceskate: IceSkating,
  rollerskate: RollerSkating,
  snowboarding: Snowboarding,
  run: DirectionsRun,
  bike: DirectionsBike,
  sailing: Sailing,
  pool: Pool,

  // Transportation
  car_rental: CarRental,
  motorcycle: TwoWheeler,
  flight: Flight,
  subway: Subway,
  train: Train,
  commute: Commute,
  boat: DirectionsBoat,
  car: DirectionsCar,
  transit: DirectionsTransit,
  bus: DirectionsBus,
  walk: DirectionsWalk,

  // Shopping and Services
  shopping: ShoppingBag,
  grocery: LocalGroceryStore,
  activity: LocalActivity,
  cafe: LocalCafe,
  bar: LocalBar,

  // Food and Dining
  restaurant: Restaurant,
  dinner: DinnerDining,
  dining: LocalDining,
  croissant: BakeryDining,
  cookie: Cookie,
  cake: Cake,
  burger: Fastfood,
  utensils: Flatware,

  // Art and Creativity
  draw: Draw,
  design: DesignServices,
  shape_line: ShapeLine,
  paint_roller: FormatPaint,
  paint: Brush,
  music: MusicNote,

  // Nature and Outdoors
  park: Park,
  nature: EmojiNature,
  pets: Pets,
  snowflake: AcUnit,

  // Places and Tourism
  museum: Museum,
  place: Place,
  hotel: Hotel,
  tour: Tour,

  // Time and Scheduling
  alarm: AlarmOn,
  time: AccessTime,
  hourglass_empty: HourglassEmpty,
  timer: AvTimer,

  // Finance
  bank: AccountBalance,
  wallet: AccountBalanceWallet,
  gas: LocalGasStation,

  // Emergency and Safety
  fire: LocalFireDepartment,
  astriks: Emergency,
  error: Error,
  coronavirus: Coronavirus,

  // Photography
  film: CameraRoll,
  apeture: Camera,
  camera: CameraAlt,

  // Miscellaneous
  lightbulb: Lightbulb,
  weekend: Weekend,
  church: Church,
  voice_over: RecordVoiceOver,
  media_bluetooth: MediaBluetoothOn,
  liedown: AirlineSeatFlat,
};

export const ICON_ARRAY: { name: string; icon: typeof AccountBalance }[] =
  Object.keys(ICONS).map((key) => ({
    name: key,
    icon: ICONS[key],
  }));
