import { Activity, Category } from "./db";

export type ActivityJoinCategory = Activity & {
  category: string;
  colour: string;
};

export const joinActivitiesWithCategories = (
  activities: Activity[],
  categories: Category[]
): ActivityJoinCategory[] => {
  return activities.map((activity) => {
    const category = categories.find((c) => c.id === activity.categoryId);
    return {
      ...activity,
      category: category?.name || "",
      colour: category?.colour || "#000000",
    };
  });
};

export const convertActivitiesToMap = (
  activities: ActivityJoinCategory[]
): Record<string, ActivityJoinCategory> => {
  return activities.reduce((acc, activity) => {
    acc[activity.id] = activity;
    return acc;
  }, {} as Record<string, ActivityJoinCategory>);
};

export const getActivityJoinCategoryMap = (
  activities: Activity[],
  categories: Category[]
): Record<string, ActivityJoinCategory> => {
  const activityArray = joinActivitiesWithCategories(activities, categories);
  return convertActivitiesToMap(activityArray);
};
