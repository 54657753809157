import styled from "@emotion/styled";
import { QuestionMark } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ICONS } from "../../../const/icons";
import { Timeslot } from "../../../database/db";
import { ActivityJoinCategory } from "../../../database/helpers";
import { timeDisplay } from "../../../utils/time";

const TimeslotRow = styled(Box)<{
  $isConflicting: boolean;
  $isEditing: boolean;
  $isSelected: boolean;
}>`
  position: absolute;
  left: 50px;
  width: calc(100% - 120px);
  z-index: 1;
  box-sizing: border-box;
  border: ${(props) =>
    props.$isConflicting
      ? "4px solid red"
      : props.$isEditing
      ? "4px solid yellow"
      : props.$isSelected
      ? "2px solid blue"
      : "1px solid #aaaaaa"};
  cursor: pointer;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

interface TimeslotComponentProps {
  timeslot: Timeslot;
  dayBounds: number[];
  pxPerMs: number;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  isSelected: boolean;
  isConflicting: boolean;
  isEditing: boolean;
  onMouseEnter: () => void;
  onClick: () => void;
  onDoubleClick: () => void;
}

export const MIN_TIMESLOT_HEIGHT = 40;
const TimeslotComponent: React.FC<TimeslotComponentProps> = ({
  timeslot,
  dayBounds,
  pxPerMs,
  activityCategoryMap,
  isSelected,
  isConflicting,
  isEditing,
  onMouseEnter,
  onClick,
  onDoubleClick,
}) => {
  const activity = activityCategoryMap[timeslot.activityId];
  const Icon = activity ? ICONS[activity.icon] : QuestionMark;
  const colour = activity ? activity.colour : "#999999";
  const name = activity ? activity.name : "Unknown";
  const top = (timeslot.startTimestampMills - dayBounds[0]) * pxPerMs;
  const height =
    (timeslot.endTimestampMills - timeslot.startTimestampMills) * pxPerMs;
  const minutes =
    (timeslot.endTimestampMills - timeslot.startTimestampMills) / 1000 / 60;

  return (
    <TimeslotRow
      sx={{
        top: `${top}px`,
        height: `${height}px`,
        backgroundColor: isSelected ? `${colour}EE` : `${colour}88`,
        overflow: isEditing ? "visible" : "hidden",
      }}
      $isSelected={isSelected}
      $isConflicting={isConflicting}
      $isEditing={isEditing}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onDoubleClick={onDoubleClick}>
      <Stack direction="row" padding={1} gap={1}>
        {height >= MIN_TIMESLOT_HEIGHT && (
          <>
            <Icon />
            <Box sx={{ flexGrow: 1 }}>
              <Typography textAlign="left">{name}</Typography>
            </Box>
            {timeslot.placeId && <LocationOnIcon />}

            <Typography>{timeDisplay(minutes)}</Typography>
          </>
        )}
      </Stack>
    </TimeslotRow>
  );
};

export default TimeslotComponent;
