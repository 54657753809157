import { MATERIAL_COLOURS } from "../const/colours";
import { db, dbuuid } from "../database/db";
import { DEFAULT_ACTIVITIES, DEFAULT_CATEGORIES } from "../database/defaults";

export const setAppInitialised = (
  initialised: boolean,
  uniqueIdentifier?: string
) => {
  const alreadyInitialised = localStorage.getItem("appInitialised");
  if (alreadyInitialised === (initialised ? "true" : "false")) {
    return;
  }
  localStorage.setItem("appInitialised", initialised ? "true" : "false");
  if (initialised) {
    localStorage.setItem("uniqueIdentifier", uniqueIdentifier ?? dbuuid());
  } else {
    localStorage.removeItem("uniqueIdentifier");
  }
};

export const clearDatabase = async () => {
  await db.delete();
  await db.open();
  setAppInitialised(false);
};

export const seedDefaults = async () => {
  await db.delete();
  await db.open();

  const categoryMap = new Map<string, string>();
  for (const category of DEFAULT_CATEGORIES) {
    const dbid = dbuuid();
    await db.categories.add({
      id: dbid,
      name: category.name,
      colour: MATERIAL_COLOURS[category.colour],
    });
    categoryMap.set(category.temp_id, dbid);
  }
  console.log("Default categories imported");

  for (const activity of DEFAULT_ACTIVITIES) {
    const categoryId = categoryMap.get(activity.categoryId);
    if (categoryId) {
      await db.activities.add({
        id: dbuuid(),
        name: activity.name,
        icon: activity.icon,
        categoryId: categoryId,
      });
    }
  }
  console.log("Default activities imported");
};

export const isAppInitialised = async () => {
  const isInitialised = localStorage.getItem("appInitialised");
  if (isInitialised === "true") {
    return true;
  }
  // Check if the database has any data
  const categoryCount = await db.categories.count();
  const activityCount = await db.activities.count();

  if (categoryCount > 0 || activityCount > 0) {
    // If there's data in the database, set the app as initialised
    setAppInitialised(true);
    return true;
  }
  return false;
};

export const initializeAppIfNeeded = async () => {
  if (!(await isAppInitialised())) {
    setAppInitialised(true);
    await seedDefaults();
  }
};
