import { Alert, Button, List, Paper, Stack, Typography } from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useState } from "react";
import { Category, db } from "../../../database/db";
import { CategoryForm } from "./CategoryForm";
import { CategoryRow } from "./CategoryRow";
import { MergeCategoryForm } from "./MergeCategoryForm";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";

export const CategoryComponent: React.FC<{
  setSelectedCategory: (category: Category | null) => void;
}> = ({ setSelectedCategory }) => {
  const categories = useLiveQuery(() => db.categories.toArray(), []);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [deletingCategory, setDeletingCategory] = useState<Category | null>(
    null
  );
  const [mergingCategory, setMergingCategory] = useState<Category | null>(null);
  const [showAddForm, setShowAddForm] = useState(false);

  const cloudSyncedDB = useCloudSyncedDB();

  const handleEdit = (category: Category) => {
    setEditingCategory(category);
  };

  const handleUpdate = async (updatedCategory: Category) => {
    await cloudSyncedDB.updateCategory(updatedCategory.id!, updatedCategory);
    setEditingCategory(null);
  };

  const handleCancel = () => {
    setEditingCategory(null);
    setShowAddForm(false);
    setMergingCategory(null);
  };

  const handleDeleteConfirmation = (category: Category) => {
    setDeletingCategory(category);
  };

  const handleDelete = async () => {
    if (deletingCategory) {
      await cloudSyncedDB.deleteCategory(deletingCategory.id!);
      setDeletingCategory(null);
    }
  };

  const handleCancelDelete = () => {
    setDeletingCategory(null);
  };

  const addCategory = async (values: { name: string; colour: string }) => {
    await cloudSyncedDB.addCategory({
      ...values,
    });
    setShowAddForm(false);
  };

  const handleMerge = (category: Category) => {
    setMergingCategory(category);
  };

  if (editingCategory) {
    return (
      <Paper elevation={2} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>
          Edit Category
        </Typography>
        <CategoryForm
          category={editingCategory}
          onSubmit={(values: { name: string; colour: string }) =>
            handleUpdate({ ...editingCategory, ...values })
          }
          onCancel={handleCancel}
        />
      </Paper>
    );
  }

  if (mergingCategory && categories) {
    return (
      <MergeCategoryForm
        category={mergingCategory}
        categories={categories}
        onCancel={handleCancel}
        onMerge={() => {
          setMergingCategory(null);
          // You might want to refresh the categories list here
        }}
      />
    );
  }

  return (
    <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        marginBottom={3}
        marginTop={2}>
        <Typography variant="h4" gutterBottom>
          Categories <small>({categories?.length ?? 0})</small>
        </Typography>
      </Stack>
      {!showAddForm && (
        <>
          {categories && categories.length > 0 ? (
            <List>
              {categories.map((category: Category) => (
                <CategoryRow
                  key={category.id}
                  category={category}
                  onEdit={handleEdit}
                  onDelete={handleDeleteConfirmation}
                  onMerge={handleMerge}
                  onClick={setSelectedCategory}
                />
              ))}
            </List>
          ) : (
            <Typography variant="body1">No categories found</Typography>
          )}
          {deletingCategory && (
            <Alert
              severity="warning"
              action={
                <>
                  <Button color="inherit" size="small" onClick={handleDelete}>
                    Confirm
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={handleCancelDelete}>
                    Cancel
                  </Button>
                </>
              }>
              Are you sure you want to delete {deletingCategory.name}?
            </Alert>
          )}
          <Button onClick={() => setShowAddForm(true)}>Add Category</Button>
        </>
      )}
      {showAddForm && (
        <>
          <Typography variant="h6" gutterBottom>
            Add New Category
          </Typography>
          <CategoryForm onSubmit={addCategory} onCancel={handleCancel} />
        </>
      )}
    </Paper>
  );
};
