import styled from "@emotion/styled";
import {
  Button,
  Stack,
  TextField,
  Typography,
  getLuminance,
} from "@mui/material";
import { Form, Formik } from "formik";
import { HexColorPicker } from "react-colorful";
import { MATERIAL_COLOUR_ARRAY } from "../../../const/colours";
import { Category } from "../../../database/db";

const ColourButton = styled(Button)<{ colour: string }>`
  background-color: ${(props) => props.colour};
  color: ${(props) => (getLuminance(props.colour) < 0.5 ? "#fff" : "#000")};
  transition: filter 0.2s;
  &:hover {
    filter: brightness(1.1);
  }
`;

interface CategoryFormProps {
  category?: Category;
  onSubmit: (values: { name: string; colour: string }) => void;
  onCancel: () => void;
}

export const CategoryForm: React.FC<CategoryFormProps> = ({
  category,
  onSubmit,
  onCancel,
}) => {
  return (
    <Formik
      initialValues={category || { name: "", colour: "#ff0000" }}
      validate={(values) => {
        const errors: Partial<{ name: string }> = {};
        if (!values.name) {
          errors.name = "Name is Required";
        }
        return errors;
      }}
      onSubmit={onSubmit}>
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Stack direction="column" spacing={2}>
            <TextField
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
            />
            <HexColorPicker
              color={values.colour}
              onChange={(colour) => setFieldValue("colour", colour)}
            />
            <Stack direction="row" spacing={1} flexWrap="wrap">
              {MATERIAL_COLOUR_ARRAY.map((colour) => (
                <ColourButton
                  colour={colour.hex}
                  key={colour.name}
                  onClick={() => setFieldValue("colour", colour.hex)}>
                  {colour.name}
                </ColourButton>
              ))}
            </Stack>
            <Typography>{values.colour}</Typography>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button type="submit" variant="contained" color="primary">
                {category ? "Update" : "Add"} Category
              </Button>
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
