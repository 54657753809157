import styled from "@emotion/styled";
import { Add, Cancel, Check, QuestionMark } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatDuration, intervalToDuration } from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ICONS } from "../../../const/icons";
import { ActivityJoinCategory } from "../../../database/helpers";
import { useActivityJoinCategory } from "../../../hooks/useActivityJoinCategory";
import { useSettings } from "../../../hooks/useSettings";
import { toTitleCase } from "../../../utils/string";
import { timeOfDay } from "../../../utils/time";

export type NewTimeslotParams = {
  startMs: number;
  endMs: number;
  activityId: string;
};
const StyledPaper = styled(Paper)<{ height?: string }>`
  padding: 1rem;
  position: sticky;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: ${({ height }) => height || "100px"};
  z-index: 1000;
  background-color: #ffffffee;

  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
`;

const TimeslotIndicatorWrapper = styled(Box)`
  position: absolute;
  left: 70px;
  width: 40px;
  z-index: 1000;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #aaaaaa;
  cursor: pointer;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

const DragHandle = styled(Button)`
  position: absolute;
  left: -5px;
  width: 50px;
  min-width: 40px;
  height: 10px;
  background-color: #eeeeeedd;
  cursor: pointer;
  cursor: grab;
  box-sizing: border-box;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ffffffdd;
  }

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

const TimeIndicator = styled(Typography)`
  position: absolute;
  left: 50px;
  font-size: 0.8rem;
  background-color: #ffffffdd;
  padding: 0.2rem;
  border-radius: 5px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

interface NewTimeslotDraggableIndicatorProps {
  pxPerMs: number;
  newTimeslotParams: NewTimeslotParams;
  dayBounds: number[];
  setNewTimeslotTimes: (times: { startMs: number; endMs: number }) => void;
}

export const NewTimeslotDraggableIndicator: React.FC<
  NewTimeslotDraggableIndicatorProps
> = ({ pxPerMs, newTimeslotParams, dayBounds, setNewTimeslotTimes }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartY, setDragStartY] = useState(0);
  const [cursorYPos, setCursorYPos] = useState(0);
  const [dragHandle, setDragHandle] = useState<"top" | "bottom">("top");
  const [topMills, setTopMills] = useState(newTimeslotParams.startMs);
  const [bottomMills, setBottomMills] = useState(newTimeslotParams.endMs);
  const [startTopMills, setStartTopMills] = useState(newTimeslotParams.startMs);
  const [startBottomMills, setStartBottomMills] = useState(
    newTimeslotParams.endMs
  );

  const { activityCategoryMap } = useActivityJoinCategory();

  const startDragging = (handle: "top" | "bottom", dragStart: number) => {
    setIsDragging(true);
    setCursorYPos(dragStart);
    setDragStartY(dragStart);
    setDragHandle(handle);
    setStartTopMills(topMills);
    setStartBottomMills(bottomMills);
  };

  const { snapToMills } = useSettings();
  /**
   * Rounds a timestamp in milliseconds to the nearest snap point.
   */
  const roundToSnap = useCallback(
    (mills: number) => {
      return Math.round(mills / snapToMills) * snapToMills;
    },
    [snapToMills]
  );

  const calculateDragTopBottom = useCallback(
    (
      dragHandle: "top" | "bottom",
      topMills: number,
      bottomMills: number
    ): { top: number; bottom: number } => {
      if (!isDragging) {
        return {
          top: (topMills - dayBounds[0]) * pxPerMs,
          bottom: (bottomMills - dayBounds[0]) * pxPerMs,
        };
      }

      const movementPerMs = (dragStartY - cursorYPos) / pxPerMs;
      const newMills = roundToSnap(
        dragHandle === "top"
          ? topMills - movementPerMs
          : bottomMills - movementPerMs
      );

      let newTopMills, newBottomMills;
      if (dragHandle === "top") {
        newTopMills = Math.max(
          dayBounds[0],
          Math.min(newMills, bottomMills - snapToMills)
        );
        newBottomMills = bottomMills;
      } else {
        newTopMills = topMills;
        newBottomMills = Math.min(
          dayBounds[1],
          Math.max(newMills, topMills + snapToMills)
        );
      }

      const newTop = (newTopMills - dayBounds[0]) * pxPerMs;
      const newBottom = (newBottomMills - dayBounds[0]) * pxPerMs;

      return { top: newTop, bottom: newBottom };
    },
    [
      cursorYPos,
      dayBounds,
      dragStartY,
      isDragging,
      pxPerMs,
      roundToSnap,
      snapToMills,
    ]
  );

  const { topPx, bottomPx, height } = useMemo(() => {
    const { top: topPx, bottom: bottomPx } = calculateDragTopBottom(
      dragHandle,
      startTopMills,
      startBottomMills
    );
    const height = bottomPx - topPx;
    return { topPx, bottomPx, height };
  }, [calculateDragTopBottom, dragHandle, startTopMills, startBottomMills]);

  const calculateDragUpdate = useCallback(() => {
    const _topMills = roundToSnap(topPx / pxPerMs + dayBounds[0]);
    const _bottomMills = roundToSnap(bottomPx / pxPerMs + dayBounds[0]);
    setTopMills(_topMills);
    setBottomMills(_bottomMills);
    return { startMs: _topMills, endMs: _bottomMills };
  }, [roundToSnap, topPx, pxPerMs, dayBounds, bottomPx]);

  const calculateDragStop = useCallback(() => {
    const { startMs, endMs } = calculateDragUpdate();
    setIsDragging(false);
    setStartTopMills(startMs);
    setStartBottomMills(endMs);
    setNewTimeslotTimes({
      startMs,
      endMs,
    });
  }, [calculateDragUpdate, setNewTimeslotTimes]);

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (isDragging) {
        e.preventDefault();
        setCursorYPos(e.touches[0].clientY);
        calculateDragUpdate();
      }
    },
    [isDragging, calculateDragUpdate]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      setCursorYPos(e.clientY);
      calculateDragUpdate();
    },
    [calculateDragUpdate]
  );

  const handleMouseUp = useCallback(() => {
    calculateDragStop();
  }, [calculateDragStop]);

  useEffect(() => {
    window.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchend", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleMouseUp);
    };
  }, [
    calculateDragStop,
    calculateDragUpdate,
    handleMouseMove,
    handleMouseUp,
    handleTouchMove,
  ]);

  useEffect(() => {
    setStartTopMills(newTimeslotParams.startMs);
    setStartBottomMills(newTimeslotParams.endMs);
  }, [
    newTimeslotParams.startMs,
    newTimeslotParams.endMs,
    setStartTopMills,
    setStartBottomMills,
  ]);

  const activityColour = useMemo(() => {
    if (!activityCategoryMap) return "#eeeeee";
    if (!newTimeslotParams.activityId) return "#eeeeee";
    return (
      activityCategoryMap[newTimeslotParams.activityId]?.colour || "#eeeeee"
    );
  }, [newTimeslotParams.activityId, activityCategoryMap]);

  return (
    <TimeslotIndicatorWrapper
      sx={{
        top: `${topPx}px`,
        height: `${height}px`,
        backgroundColor: `${activityColour}cc`,
      }}>
      <DragHandle
        sx={{
          top: `-10px`,
        }}
        onMouseDown={(e) => {
          startDragging("top", e.clientY);
        }}
        onTouchStart={(e) => {
          startDragging("top", e.touches[0].clientY);
        }}>
        =
      </DragHandle>
      <DragHandle
        sx={{
          bottom: `-10px`,
        }}
        onMouseDown={(e) => {
          startDragging("bottom", e.clientY);
        }}
        onTouchStart={(e) => {
          startDragging("bottom", e.touches[0].clientY);
        }}>
        =
      </DragHandle>

      <TimeIndicator
        sx={{
          bottom: `-10px`,
        }}>
        {timeOfDay(bottomMills)}
      </TimeIndicator>
      <TimeIndicator
        sx={{
          top: `-10px`,
        }}>
        {timeOfDay(topMills)}
      </TimeIndicator>
    </TimeslotIndicatorWrapper>
  );
};

interface SuggestedTimeslotInfoProps {
  setAddingActivity: (activityId: string) => void;
}
export const SuggestedTimeslotInfo: React.FC<SuggestedTimeslotInfoProps> = ({
  setAddingActivity,
}) => {
  const { activityCategoryArray } = useActivityJoinCategory();

  const topActivities = useMemo(() => {
    if (!activityCategoryArray) return [];
    return activityCategoryArray.slice(0, 8);
  }, [activityCategoryArray]);

  const ActivityIcon = useCallback((activity: ActivityJoinCategory) => {
    return ICONS[activity.icon];
  }, []);

  return (
    <StyledPaper height="110px">
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            height: "65px",
            overflowY: "hidden",
            rowGap: 5,
          }}>
          <Tooltip title={"Add Timeslot"} placement="top" arrow>
            <Button
              onClick={() => setAddingActivity("")}
              sx={{
                backgroundColor: "#eeeeee55",
                "&:hover": {
                  backgroundColor: "#eeeeee77",
                },
                color: "black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "8px",
                width: "100px",
              }}>
              <Add />
              <Typography
                variant="caption"
                sx={{
                  maxWidth: "80px",
                  textAlign: "center",
                  fontSize: "0.7rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}>
                Add Now
              </Typography>
            </Button>
          </Tooltip>
          {topActivities.map((activity) => {
            const Icon = ActivityIcon(activity);
            return (
              <Tooltip
                key={activity.id}
                title={toTitleCase(activity.name)}
                placement="top"
                arrow>
                <Button
                  onClick={() => setAddingActivity(activity.id)}
                  sx={{
                    backgroundColor: activity.colour + "55",
                    "&:hover": {
                      backgroundColor: activity.colour + "77",
                    },
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "8px",
                    width: "100px",
                  }}>
                  <Icon />
                  <Typography
                    variant="caption"
                    sx={{
                      maxWidth: "80px",
                      textAlign: "center",
                      fontSize: "0.7rem",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}>
                    {toTitleCase(activity.name)}
                  </Typography>
                </Button>
              </Tooltip>
            );
          })}
        </Stack>
        <Typography variant="body2" color="text.secondary" align="center">
          Your most frequently used activities will appear here
        </Typography>
      </Stack>
    </StyledPaper>
  );
};

interface AddTimeslotComponentProps {
  newTimeslotParams: NewTimeslotParams;
  cancel: () => void;
  addTimeslot: (timeslot: NewTimeslotParams) => void;
  updateActivity: (activityId: string) => void;
}

export const NewTimeslotInfo: React.FC<AddTimeslotComponentProps> = ({
  newTimeslotParams,
  cancel,
  addTimeslot,
  updateActivity,
}) => {
  // Calculate the top and bottom of the timeslot
  const { activityCategoryArray, activityCategoryMap } =
    useActivityJoinCategory();

  const [selectedActivity, setSelectedActivity] = useState<string | null>(null);

  const [defaultActivityId, setDefaultActivityId] = useState<string | null>(
    null
  );

  const addTimeslotHandler = useCallback(() => {
    if (newTimeslotParams.startMs === 0 || newTimeslotParams.endMs === 0)
      return;
    if (selectedActivity) {
      addTimeslot({
        startMs: newTimeslotParams.startMs,
        endMs: newTimeslotParams.endMs,
        activityId: selectedActivity,
      });
    }
  }, [
    newTimeslotParams.startMs,
    newTimeslotParams.endMs,
    selectedActivity,
    addTimeslot,
  ]);

  const [isLoading, setIsLoading] = useState(true);
  const [autocompleteKey, setAutocompleteKey] = useState(0);

  const autocompleteOptions = useMemo<
    {
      id: string;
      label: string;
      colour: string;
    }[]
  >(() => {
    if (!activityCategoryArray) return [];
    return activityCategoryArray.map((a) => ({
      id: a.id,
      label: a.name,
      colour: a.colour,
    }));
  }, [activityCategoryArray]);

  useEffect(() => {
    if (autocompleteOptions.length > 0) {
      setIsLoading(false);
      console.log(
        autocompleteOptions.length,
        newTimeslotParams.activityId,
        autocompleteOptions
      );
      if (newTimeslotParams.activityId) {
        setSelectedActivity(newTimeslotParams.activityId);
        setDefaultActivityId(newTimeslotParams.activityId);
      } else if (autocompleteOptions[0]) {
        setSelectedActivity(autocompleteOptions[0].id);
        setDefaultActivityId(autocompleteOptions[0].id);
      }
      // Force a re-render of Autocomplete when options change
      setAutocompleteKey((prev) => prev + 1);
    }
  }, [autocompleteOptions, newTimeslotParams.activityId]);

  useEffect(() => {
    if (selectedActivity) {
      updateActivity(selectedActivity);
    }
  }, [defaultActivityId, selectedActivity, updateActivity]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        cancel();
      }
      if (e.key === "Enter") {
        addTimeslotHandler();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [addTimeslotHandler, cancel]);

  const Icon = useMemo(
    () =>
      selectedActivity
        ? ICONS[activityCategoryMap[selectedActivity].icon]
        : QuestionMark,
    [selectedActivity, activityCategoryMap]
  );

  const activityColour = useMemo(
    () =>
      selectedActivity
        ? activityCategoryMap[selectedActivity].colour
        : "#eeeeee",
    [selectedActivity, activityCategoryMap]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <StyledPaper>
      <Stack direction="row" gap={2} alignItems="center">
        <Icon
          sx={{
            width: 30,
            height: 30,
            color: activityColour,
          }}
        />

        {!isLoading && autocompleteOptions.length > 0 && defaultActivityId && (
          <Autocomplete
            key={autocompleteKey}
            options={autocompleteOptions}
            size="small"
            sx={{ width: 300 }}
            autoHighlight
            disableClearable
            loading={isLoading}
            defaultValue={autocompleteOptions.find(
              (a) => a.id === defaultActivityId
            )}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const textField = inputRef.current;
                if (
                  textField &&
                  textField.getAttribute("aria-expanded") === "true"
                ) {
                  event.stopPropagation();
                }
              } else {
                event.stopPropagation();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={inputRef}
                variant="standard"
                autoFocus
              />
            )}
            renderOption={(props, option) => {
              if (!option?.id || !activityCategoryMap?.[option.id]) {
                return null;
              }
              const Icon = ICONS[activityCategoryMap[option.id]?.icon];
              return (
                <Box component="li" {...props} key={option.id}>
                  <Stack direction="row" gap={2}>
                    {Icon && <Icon sx={{ color: option.colour }} />}
                    {toTitleCase(option.label)}
                  </Stack>
                </Box>
              );
            }}
            onChange={(_, newValue) => {
              if (newValue?.id) {
                setSelectedActivity(newValue.id);
              }
            }}
          />
        )}

        <Stack direction="column" gap={0}>
          <Typography>
            {timeOfDay(newTimeslotParams.startMs)} -{" "}
            {timeOfDay(newTimeslotParams.endMs)}
          </Typography>
          <Typography>
            {formatDuration(
              intervalToDuration({
                start: newTimeslotParams.startMs,
                end: newTimeslotParams.endMs,
              }),
              {
                format: ["hours", "minutes"],
                zero: false,
              }
            )
              .replace("hours", "hr")
              .replace("hour", "hr")
              .replace("minutes", "min")
              .replace("minute", "min")}
          </Typography>
        </Stack>

        <Stack direction="column" gap={0}>
          <Button variant="text" onClick={addTimeslotHandler}>
            <Check />
          </Button>
          <Button variant="text" onClick={cancel}>
            <Cancel />
          </Button>
        </Stack>
      </Stack>
    </StyledPaper>
  );
};
