import { ThemeProvider } from "@mui/material/styles";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App.tsx";
import { SettingsProvider } from "./hooks/SettingsProvider.tsx";
import { SnackbarProvider } from "./hooks/SnackbarProvider.tsx";
import { UndoRedoProvider } from "./hooks/UndoRedoProvider.tsx";
import { ModalProvider } from "./hooks/ModalProvider";
import { About } from "./page/About.tsx";
import { Analytics } from "./page/analytics/Analytics.tsx";
import { Data } from "./page/data/Data.tsx";
import { Home } from "./page/home/Home.tsx";
import { ImportExport } from "./page/importexport/ImportExport.tsx";
import { Settings } from "./page/Settings.tsx";
import theme from "./theme/theme.ts";
import { Instructions } from "./page/Instructions.tsx";

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/importexport",
        element: <ImportExport />,
      },
      {
        path: "/analytics/:page?",
        element: <Analytics />,
      },
      {
        path: "/data",
        element: <Data />,
      },
      {
        path: "/instructions",
        element: <Instructions />,
      },
      {
        path: "*",
        element: <div>Not Found</div>,
      },
    ],
  },
]);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <SettingsProvider>
        <UndoRedoProvider>
          <SnackbarProvider>
            <ModalProvider>
              <RouterProvider router={router} />
            </ModalProvider>
          </SnackbarProvider>
        </UndoRedoProvider>
      </SettingsProvider>
    </ThemeProvider>
  </StrictMode>
);
