import styled from "@emotion/styled";
import Calendar from "react-calendar";

export const DatePickerCalendar = styled(Calendar)<{ $isSmallScreen: boolean }>`
  width: 100%;
  max-width: ${(props) => (props.$isSmallScreen ? "450px" : "850px")};
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  .react-calendar__tile--now {
    background: #ffff76;
  }

  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }

  .react-calendar__viewContainer {
    gap: 30px;
  }
`;
