// File for default categories and activities from SmarterTime

export type DefaultCategory = {
  name: string;
  temp_id: string;
  colour: string;
};

export type DefaultActivity = {
  name: string;
  icon: string;
  categoryId: string;
};

export const DEFAULT_CATEGORIES: DefaultCategory[] = [
  {
    name: "Art & Entertainment",
    temp_id: "art_entertainment",
    colour: "indigo",
  },
  {
    name: "Chores",
    temp_id: "chores",
    colour: "yellow",
  },
  {
    name: "Health & Hygiene",
    temp_id: "health_hygiene",
    colour: "green",
  },
  {
    name: "Hobbies",
    temp_id: "hobbies",
    colour: "deep_purple",
  },
  {
    name: "Personal Life",
    temp_id: "personal_life",
    colour: "pink",
  },
  {
    name: "Professional Life",
    temp_id: "professional_life",
    colour: "red",
  },
  {
    name: "Resting",
    temp_id: "resting",
    colour: "teal",
  },
  {
    name: "Social",
    temp_id: "social",
    colour: "purple",
  },
  {
    name: "Sport & Fitness",
    temp_id: "sport_fitness",
    colour: "orange",
  },
  {
    name: "Transport",
    temp_id: "transport",
    colour: "light_blue",
  },
  {
    name: "No Category",
    temp_id: "no_category",
    colour: "grey",
  },
];

export const DEFAULT_ACTIVITIES: DefaultActivity[] = [
  {
    name: "Home Time",
    icon: "home",
    categoryId: "personal_life",
  },
  {
    name: "Work",
    icon: "work",
    categoryId: "professional_life",
  },
  {
    name: "Meeting",
    icon: "groups",
    categoryId: "professional_life",
  },
  {
    name: "Groceries",
    icon: "grocery",
    categoryId: "chores",
  },
  {
    name: "Shopping",
    icon: "shopping",
    categoryId: "chores",
  },
  {
    name: "Clean house",
    icon: "chalet",
    categoryId: "chores",
  },
  {
    name: "Tv",
    icon: "tv",
    categoryId: "art_entertainment",
  },
  {
    name: "Lesson",
    icon: "book",
    categoryId: "professional_life",
  },
  {
    name: "Sleep",
    icon: "bedtime",
    categoryId: "resting",
  },
  {
    name: "Bath",
    icon: "bathtub",
    categoryId: "health_hygiene",
  },
  {
    name: "Beautician",
    icon: "add_reaction",
    categoryId: "health_hygiene",
  },
  {
    name: "Bike",
    icon: "bike",
    categoryId: "transport",
  },
  {
    name: "Boat",
    icon: "sailing",
    categoryId: "transport",
  },
  {
    name: "Browse news",
    icon: "newspaper",
    categoryId: "art_entertainment",
  },
  {
    name: "Brush teeth",
    icon: "croissant",
    categoryId: "health_hygiene",
  },
  {
    name: "Bus",
    icon: "bus",
    categoryId: "transport",
  },
  {
    name: "Cafe",
    icon: "cafe",
    categoryId: "social",
  },
  {
    name: "Call",
    icon: "call",
    categoryId: "social",
  },
  {
    name: "Car",
    icon: "car",
    categoryId: "transport",
  },
  {
    name: "Card games",
    icon: "carousel",
    categoryId: "art_entertainment",
  },
  {
    name: "Computer",
    icon: "computer",
    categoryId: "art_entertainment",
  },
  {
    name: "Concert",
    icon: "activity",
    categoryId: "art_entertainment",
  },
  {
    name: "Cook",
    icon: "countertops",
    categoryId: "chores",
  },
  {
    name: "Dance",
    icon: "gymnastics",
    categoryId: "art_entertainment",
  },
  {
    name: "Date",
    icon: "favorite",
    categoryId: "social",
  },
  {
    name: "Design work",
    icon: "shape_line",
    categoryId: "hobbies",
  },
  {
    name: "DIY",
    icon: "design",
    categoryId: "hobbies",
  },
  {
    name: "Doctor visit",
    icon: "medication",
    categoryId: "health_hygiene",
  },
  {
    name: "Draw",
    icon: "draw",
    categoryId: "hobbies",
  },
  {
    name: "Drinks",
    icon: "bar",
    categoryId: "social",
  },
  {
    name: "Drive",
    icon: "car_rental",
    categoryId: "transport",
  },
  {
    name: "Eat",
    icon: "dinner",
    categoryId: "health_hygiene",
  },
  {
    name: "Eat out",
    icon: "restaurant",
    categoryId: "social",
  },
  {
    name: "Exhibition",
    icon: "museum",
    categoryId: "art_entertainment",
  },
  {
    name: "Family time",
    icon: "family_restroom",
    categoryId: "personal_life",
  },
  {
    name: "Garden",
    icon: "yard",
    categoryId: "chores",
  },
  {
    name: "Get ready",
    icon: "alarm",
    categoryId: "health_hygiene",
  },
  {
    name: "Gym",
    icon: "fitness_center",
    categoryId: "sport_fitness",
  },
  {
    name: "Hairdresser",
    icon: "face",
    categoryId: "health_hygiene",
  },
  {
    name: "Hanky-panky",
    icon: "favourite_border",
    categoryId: "social",
  },
  {
    name: "Hike",
    icon: "hiking",
    categoryId: "sport_fitness",
  },
  {
    name: "Hospital Visit",
    icon: "healing",
    categoryId: "health_hygiene",
  },
  {
    name: "Iron",
    icon: "iron",
    categoryId: "chores",
  },
  {
    name: "Laundry",
    icon: "laundry",
    categoryId: "chores",
  },
  {
    name: "Learn Language",
    icon: "translate",
    categoryId: "hobbies",
  },
  {
    name: "Listen to music",
    icon: "media_bluetooth",
    categoryId: "art_entertainment",
  },
  {
    name: "Make-up",
    icon: "beauty_face",
    categoryId: "health_hygiene",
  },
  {
    name: "Mani, pedi",
    icon: "backhand",
    categoryId: "health_hygiene",
  },
  {
    name: "Meditation",
    icon: "meditation",
    categoryId: "resting",
  },
  {
    name: "Motorcycle",
    icon: "motorcycle",
    categoryId: "transport",
  },
  {
    name: "Movies",
    icon: "movie",
    categoryId: "art_entertainment",
  },
  {
    name: "Nap",
    icon: "liedown",
    categoryId: "resting",
  },
  {
    name: "Network",
    icon: "people",
    categoryId: "social",
  },
  {
    name: "Paint",
    icon: "paint",
    categoryId: "hobbies",
  },
  {
    name: "Paper",
    icon: "description",
    categoryId: "hobbies",
  },
  {
    name: "Parks and recreation",
    icon: "park",
    categoryId: "art_entertainment",
  },
  {
    name: "Party",
    icon: "celebration",
    categoryId: "social",
  },
  {
    name: "Personal admin",
    icon: "note",
    categoryId: "personal_life",
  },
  {
    name: "Pet",
    icon: "pets",
    categoryId: "personal_life",
  },
  {
    name: "Phone",
    icon: "phone_enabled",
    categoryId: "art_entertainment",
  },
  {
    name: "Photography",
    icon: "camera",
    categoryId: "hobbies",
  },
  {
    name: "Plane",
    icon: "flight",
    categoryId: "transport",
  },
  {
    name: "Play music",
    icon: "play_circle",
    categoryId: "art_entertainment",
  },
  {
    name: "Potty",
    icon: "toilet",
    categoryId: "health_hygiene",
  },
  {
    name: "Programming",
    icon: "terminal",
    categoryId: "hobbies",
  },
  {
    name: "Read",
    icon: "book_flip",
    categoryId: "hobbies",
  },
  {
    name: "Relax",
    icon: "weekend",
    categoryId: "resting",
  },
  {
    name: "Religion",
    icon: "church",
    categoryId: "personal_life",
  },
  {
    name: "Run",
    icon: "run",
    categoryId: "sport_fitness",
  },
  {
    name: "Shower",
    icon: "shower",
    categoryId: "health_hygiene",
  },
  {
    name: "Sing",
    icon: "voice_over",
    categoryId: "art_entertainment",
  },
  {
    name: "Ski",
    icon: "ski",
    categoryId: "sport_fitness",
  },
  {
    name: "Socialising",
    icon: "diversity",
    categoryId: "social",
  },
  {
    name: "Spa",
    icon: "spa",
    categoryId: "health_hygiene",
  },
  {
    name: "Sport",
    icon: "sports",
    categoryId: "sport_fitness",
  },
  {
    name: "Subway",
    icon: "subway",
    categoryId: "transport",
  },
  {
    name: "Swim",
    icon: "pool",
    categoryId: "sport_fitness",
  },
  {
    name: "Theatre",
    icon: "theater_comedy",
    categoryId: "art_entertainment",
  },
  {
    name: "Time waste",
    icon: "timer",
    categoryId: "resting",
  },
  {
    name: "Tourism",
    icon: "tour",
    categoryId: "art_entertainment",
  },
  {
    name: "Train",
    icon: "train",
    categoryId: "transport",
  },
  {
    name: "Training seminar",
    icon: "library",
    categoryId: "professional_life",
  },
  {
    name: "Vehicle",
    icon: "commute",
    categoryId: "transport",
  },
  {
    name: "Video Games",
    icon: "game_controller",
    categoryId: "art_entertainment",
  },
  {
    name: "Wait",
    icon: "hourglass_empty",
    categoryId: "resting",
  },
  {
    name: "Walk",
    icon: "walk",
    categoryId: "sport_fitness",
  },
  {
    name: "Wash Dishes",
    icon: "utensils",
    categoryId: "chores",
  },
  {
    name: "Write",
    icon: "edit_note",
    categoryId: "hobbies",
  },
];
