import { TZDate } from "@date-fns/tz";
import styled from "@emotion/styled";
import {
  ArrowDownward,
  ArrowUpward,
  DragHandle,
  History as HistoryIcon,
  Redo,
  Today,
  Undo,
  ZoomIn,
} from "@mui/icons-material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

import {
  Button,
  Container,
  FormControlLabel,
  Input,
  Paper,
  Slider,
  Stack,
  Switch,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { addDays, format, isSameDay, parse } from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { db, dbuuid, Place, Timeslot } from "../../database/db";
import { useActivityJoinCategory } from "../../hooks/useActivityJoinCategory";
import { useCloudSyncedDB } from "../../hooks/useCloudSyncedDb";
import useSessionBackedState from "../../hooks/useSessionBackedState";
import { useSettings } from "../../hooks/useSettings";
import { useUndoRedo } from "../../hooks/useUndoRedo";
import {
  applyTimeslotChanges,
  getConflictingTimeslots,
  getTimeslotAdjustments,
} from "../../utils/conflict_management";
import {
  createTimeBlocksFromLocationHistory,
  LocationTimeBlock,
} from "../../utils/location_history_processing";
import {
  dateStringToDayBoundsMills,
  getComputerTimezone,
} from "../../utils/time";
import {
  generateTimeslotBoundaries,
  getBlankTimeRanges,
  getPotentialAddPoints,
} from "../../utils/timeslot";
import { DatePickerCalendar } from "../shared/DatePickerCalendar";
import {
  NewTimeslotDraggableIndicator,
  NewTimeslotInfo,
  NewTimeslotParams,
  SuggestedTimeslotInfo,
} from "./components/AddTimeslotComponents";
import BlankTimeslotRow from "./components/BlankTimeslotComponent";
import { CurrentTimeLine } from "./components/CurrentTimeLine";
import DraggingTimeslotComponent from "./components/DraggingTimeslotComponent";
import { LocationHistoryColumn } from "./components/LocationHistoryColumn";
import SelectedTimeslotInfo from "./components/SelectedTimeslotInfo";
import TimeslotComponent from "./components/TimeslotComponent";
import { KeyIcon } from "../../components/KeyIcon";
import { useSnackbar } from "../../hooks/useSnackbar";

const Hour = styled.div<{ height: number }>`
  height: ${(props) => props.height - 1}px;
import { useCloudSyncedDB } from "../../hooks/useCloudSyncedDb";
  margin-left: 50px;
  position: relative;
  background-color: white;

  .plusButton {
    display: none;
    position: absolute;
    right: 0px;
    height: 100%;
  }
  &:hover {
    .plusButton {
      display: block;
    }
  }
`;

const AddButton = styled(Button)<{ height: number }>`
  position: absolute;
  width: 150px;
  right: 50px;
  font-size: 20px;
  background: #ffffffaa;
  height: ${(props) => props.height}px;
  z-index: 100;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

const DragButton = styled(Button)`
  position: absolute;
  background-color: #ffffffaa;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  left: 50px;
  min-width: 0;
  border: 1px solid black;
  z-index: 200;
  cursor: grab;
  .handleIcon {
    font-size: 15px;
  }

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

const ScaleButton = styled(DragButton)`
  cursor: ns-resize;
  height: 20px;
  width: 60px;
`;

const MergeButton = styled(Button)`
  position: absolute;
  background-color: #ffffffaa;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 80px;
  padding: 0;
  margin: 0;
  left: 40%;
  min-width: 0;
  border: 1px solid black;
  z-index: 200;
  .handleIcon {
    font-size: 15px;
  }

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

const TimeDisplay = styled.div<{ top: number }>`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: 5px;
  width: 40px;
  height: 25px;
  padding: 2px;
  background-color: ${(props) =>
    props.theme === "hover" ? "#EEEEEE" : "white"};
  text-align: left;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 13px;
`;

const HourLabel = styled.div`
  position: absolute;
  font-size: 13px;
  width: 50px;
  left: -43px;
  top: -11px;
  text-align: left;
  opacity: 0.4;
  color: #000;
`;

const TimeslotColumn = styled(Stack)`
  position: relative;
`;

type BlankTimeslot = {
  startTimestampMills: number;
  endTimestampMills: number;
};

export const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();

  const cloudSyncedDB = useCloudSyncedDB();

  const [date, setDate] = useState(() => {
    const params = new URLSearchParams(location.search);
    const dateParam = params.get("date");
    if (dateParam && /^\d{4}-\d{2}-\d{2}$/.test(dateParam)) {
      return dateParam;
    }
    return format(new Date(), "yyyy-MM-dd");
  });
  // Update URL when date changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("date", date);
    navigate(`?${params.toString()}`, { replace: true });
  }, [date, navigate, location.search]);

  const {
    popUndoSteps,
    pushUndoSteps,
    pushRedoSteps,
    popRedoSteps,
    totalUndoSteps,
    totalRedoSteps,
  } = useUndoRedo();
  const { defaultZoom, snapToMills } = useSettings();
  const [pxPerHr, setPxPerHr] = useSessionBackedState(
    defaultZoom,
    "home-pxPerHr"
  );
  const pxPerMs = useMemo(() => {
    return pxPerHr / (60 * 60 * 1000);
  }, [pxPerHr]);

  // create a list of hours from 0 to 23
  const hours = useState([...Array(24).keys()])[0];

  const { activityCategoryMap } = useActivityJoinCategory();

  const [hoveredTimeslot, setHoveredTimeslot] = useState<Timeslot | null>(null);
  const [draggingTimeslot, setDraggingTimeslot] = useState<Timeslot | null>(
    null
  );
  const [dragStartY, setDragStartY] = useState(0);
  const [buttonDragging, setButtonDragging] = useState(false);
  const [cursorYPos, setCursorYPos] = useState(0);
  const [dragHandle, setDragHandle] = useState<"top" | "bottom" | "middle">(
    "top"
  );

  const [showLocationHistory, setShowLocationHistory] = useState(true);

  const [editingTimeslot, setEditingTimeslot] = useState<string | null>(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
    null
  );

  const [newTimeslotParams, setNewTimeslotParams] =
    useState<NewTimeslotParams | null>(null);
  const [conflictingTimeslots, setConflictingTimeslots] = useState<string[]>(
    []
  );

  const [dragTopMills, setDragTopMills] = useState<number>(0);
  const [dragBottomMills, setDragBottomMills] = useState<number>(0);

  const [currentTimeMills, setCurrentTimeMills] = useState<number>(0);

  const [showCalendar, setShowCalendar] = useState(false);

  const dayBounds = useMemo(() => {
    return dateStringToDayBoundsMills(date, getComputerTimezone());
  }, [date]);

  const timeslots = useLiveQuery(async () => {
    if (!dayBounds) return [];
    const timeslots = await db.timeslots
      .where("startTimestampMills")
      .between(dayBounds[0], dayBounds[1])
      .or("endTimestampMills")
      .between(dayBounds[0], dayBounds[1])
      .toArray();

    // Timeslots that exceed the day bounds should be clamped
    return timeslots
      .map((timeslot) => {
        return {
          ...timeslot,
          startTimestampMills: Math.max(
            dayBounds[0],
            Math.min(dayBounds[1], timeslot.startTimestampMills)
          ),
          endTimestampMills: Math.max(
            dayBounds[0],
            Math.min(dayBounds[1], timeslot.endTimestampMills)
          ),
        };
      })
      .filter((timeslot) => {
        // Remove timeslots that are <= 0 milliseconds long
        return timeslot.startTimestampMills < timeslot.endTimestampMills;
      });
  }, [dayBounds]);

  // Get the location history for the day
  const locationHistory = useLiveQuery(() => {
    if (!dayBounds) return [];
    return db.locationHistories
      .where("timestampMills")
      .between(dayBounds[0], dayBounds[1])
      .toArray();
  }, [dayBounds]);

  const locationTimeBlocks: LocationTimeBlock[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!locationHistory) return [];
    return createTimeBlocksFromLocationHistory(dayBounds, locationHistory);
  }, [dayBounds, locationHistory]);

  const selectedTimeslotLotLon = useMemo(() => {
    if (!selectedTimeslot) return null;
    if (!locationHistory) return null;
    // Find the latlon of the timeslot based on the location history
    const closestLocation = locationHistory.find((location) => {
      return (
        location.timestampMills >= selectedTimeslot.startTimestampMills &&
        location.timestampMills <= selectedTimeslot.endTimestampMills
      );
    });
    if (!closestLocation) return null;
    return {
      latitude: closestLocation.latitude,
      longitude: closestLocation.longitude,
    };
  }, [selectedTimeslot, locationHistory]);

  const timeslotBoundaries: number[] = useMemo(() => {
    if (!timeslots) return [];
    if (!dayBounds) return [];
    return generateTimeslotBoundaries(timeslots, dayBounds);
  }, [timeslots, dayBounds]);

  const blankTimeslots: BlankTimeslot[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!timeslots) return [];
    // Calculate blank timeslots as the inverse of timeslots
    const blankTimeslots: BlankTimeslot[] = getBlankTimeRanges(
      timeslots,
      dayBounds
    ).map((range) => ({
      startTimestampMills: range[0],
      endTimestampMills: range[1],
    }));
    return blankTimeslots;
  }, [dayBounds, timeslots]);

  // Get the positions of the drag buttons for each timeslot
  const buttonPositions: number[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!timeslots) return [];
    if (!timeslots.length) return [];
    if (selectedTimeslot === null) return [];

    const timeslot = timeslots.find((t) => t.id === selectedTimeslot.id);

    if (!timeslot) return [];

    const top = (timeslot.startTimestampMills - dayBounds[0]) * pxPerMs;
    const bottom = (timeslot.endTimestampMills - dayBounds[0]) * pxPerMs;
    const middle = (top + bottom) / 2;
    return [top, middle, bottom];
  }, [dayBounds, timeslots, selectedTimeslot, pxPerMs]);

  // This is the timeslot boundaries but with a max of 1h per boundary
  const potentialAddPoints: number[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!timeslotBoundaries) return [];
    return getPotentialAddPoints(timeslotBoundaries, dayBounds, 15);
  }, [dayBounds, timeslotBoundaries]);

  const addButtonPosition = useMemo(() => {
    if (!dayBounds) return null;
    if (!potentialAddPoints) return null;
    // Get last potential add point that is greater than the cursor position
    const cursorMills = cursorYPos / pxPerMs + dayBounds[0];
    const addPoint = potentialAddPoints.filter((p) => p < cursorMills).pop();
    const addPointIndex = potentialAddPoints.findIndex((p) => p === addPoint);
    let nextPoint = dayBounds[1];

    if (addPointIndex !== -1 && addPointIndex + 2 < potentialAddPoints.length) {
      nextPoint = potentialAddPoints[addPointIndex + 2];
    }
    if (!addPoint) return null;
    const addPixel = (addPoint - dayBounds[0]) * pxPerMs;
    const nextPixel = nextPoint ? (nextPoint - dayBounds[0]) * pxPerMs : null;
    return {
      addMs: addPoint,
      addPx: addPixel,
      addHeightPx: nextPixel ? nextPixel - addPixel : 100,
      addMsLength: nextPoint ? nextPoint - addPoint : dayBounds[1] - addPoint,
    };
  }, [dayBounds, potentialAddPoints, cursorYPos, pxPerMs]);

  const shouldShowDragButtons = useMemo(() => {
    if (editingTimeslot || buttonDragging || newTimeslotParams !== null)
      return false;

    return buttonPositions.length > 0;
  }, [buttonDragging, newTimeslotParams, buttonPositions, editingTimeslot]);

  const shouldShowAddButton = useMemo(() => {
    if (editingTimeslot || buttonDragging || newTimeslotParams !== null)
      return false;
    return addButtonPosition !== null;
  }, [editingTimeslot, buttonDragging, newTimeslotParams, addButtonPosition]);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const changeTimeslotActivityAndTime = useCallback(
    async (
      timeslot: Timeslot,
      activityId: string,
      startMs: number,
      endMs: number
    ) => {
      if (!activityId) return;
      const updatedTimeslot = {
        ...timeslot,
        activityId: activityId,
        startTimestampMills: startMs,
        endTimestampMills: endMs,
      };
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [
          {
            action: "update",
            timeslot: updatedTimeslot,
          },
        ],
        true
      );
      pushUndoSteps(undoSteps, true);
      setEditingTimeslot(null);
    },
    [cloudSyncedDB, pushUndoSteps]
  );

  const changeTimeslotPlace = useCallback(
    async (timeslot: Timeslot, placeId: string | null) => {
      console.log("Change timeslot place");

      const updatedTimeslot = {
        ...timeslot,
        placeId: placeId || undefined,
      };
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [
          {
            action: "update",
            timeslot: updatedTimeslot,
          },
        ],
        true
      );
      pushUndoSteps(undoSteps, true);
    },
    [cloudSyncedDB, pushUndoSteps]
  );
  const addTimeslot = useCallback(
    async (timeslot: {
      startMs: number;
      endMs: number;
      activityId: string;
    }) => {
      const { startMs, endMs, activityId } = timeslot;

      console.log("Adding timeslot", timeslot);
      const newTimeslot = {
        id: dbuuid(),
        startTimestampMills: startMs,
        endTimestampMills: endMs,
        activityId,
        timezone: getComputerTimezone(),
      };

      const timeslotChanges = await getTimeslotAdjustments(timeslots || [], {
        id: dbuuid(),
        startTimestampMills: startMs,
        endTimestampMills: endMs,
        activityId,
        timezone: getComputerTimezone(),
      });

      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges, { action: "add", timeslot: newTimeslot }],
        true
      );

      pushUndoSteps(undoSteps, true);
    },
    [timeslots, pushUndoSteps, cloudSyncedDB]
  );

  const deleteTimeslot = useCallback(
    async (timeslotId: string) => {
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [{ action: "delete", timeslot: { id: timeslotId } }],
        true
      );
      pushUndoSteps(undoSteps, true);
    },
    [cloudSyncedDB, pushUndoSteps]
  );
  /**
   * Merges a timeslot up or down based on the specified direction to the previous or next timeslot boundary.
   */
  const processMerges = useCallback(
    async (timeslotId: string | null, direction: "up" | "down") => {
      if (!dayBounds || !timeslots || !timeslotId) return;

      const timeslot = timeslots.find((t) => t.id === timeslotId);
      if (!timeslot) return;

      // Determine new start and end times based on direction
      const [newStart, newEnd] =
        direction === "up"
          ? [
              // Get the last boundary that is less than the timeslot's start time
              timeslotBoundaries
                .filter((b) => b < timeslot.startTimestampMills)
                .pop() || timeslot.startTimestampMills,
              timeslot.endTimestampMills,
            ]
          : [
              timeslot.startTimestampMills,
              // Get the first boundary that is greater than the timeslot's end time
              timeslotBoundaries.find((b) => b > timeslot.endTimestampMills) ||
                timeslot.endTimestampMills,
            ];

      if (
        newStart === timeslot.startTimestampMills &&
        newEnd === timeslot.endTimestampMills
      )
        return;

      const updatedTimeslot = {
        ...timeslot,
        startTimestampMills: newStart,
        endTimestampMills: newEnd,
      };

      // Get and apply timeslot adjustments
      const timeslotChanges = await getTimeslotAdjustments(
        timeslots,
        updatedTimeslot
      );
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges, { action: "update", timeslot: updatedTimeslot }],
        true
      );

      pushUndoSteps(undoSteps, true);
    },
    [dayBounds, timeslots, timeslotBoundaries, pushUndoSteps, cloudSyncedDB]
  );

  /**
   * Rounds a timestamp in milliseconds to the nearest snap point.
   */
  const roundToSnap = useCallback(
    (mills: number) => {
      return Math.round(mills / snapToMills) * snapToMills;
    },
    [snapToMills]
  );

  /**
   * Calculates the new top and bottom positions for a timeslot during dragging.
   * Is called by the useMemo to calculate the dragTopPx and dragBottomPx.
   */
  const calculateDragTopBottom = useCallback(
    (
      timeslot: Timeslot,
      dragHandle: "top" | "bottom" | "middle",
      cursorYPos: number,
      dragStartY: number,
      dayBounds: number[]
    ) => {
      // Calculate original top and bottom positions
      const ogTop = (timeslot.startTimestampMills - dayBounds[0]) * pxPerMs; // Original top position in pixels
      const ogBottom = (timeslot.endTimestampMills - dayBounds[0]) * pxPerMs; // Original bottom position in pixels
      const dragDelta = cursorYPos - dragStartY; // Calculate drag distance

      // Determine new top and bottom based on drag handle
      let top = dragHandle === "bottom" ? ogTop : ogTop + dragDelta;
      let bottom = dragHandle === "top" ? ogBottom : ogBottom + dragDelta;

      // Ensure top is always less than bottom
      if (top > bottom) [top, bottom] = [bottom, top];
      top = Math.max(0, top); // Ensure top is not less than 0
      bottom = Math.min(24 * pxPerHr, bottom); // Ensure bottom is not greater than 24 hours

      // Calculate and round top and bottom to nearest snap point
      const topMills = roundToSnap(top / pxPerMs);
      const bottomMills = roundToSnap(bottom / pxPerMs);

      // Set drag top and bottom mills
      setDragTopMills(Math.round(topMills + dayBounds[0])); // Set drag top mills
      setDragBottomMills(Math.round(bottomMills + dayBounds[0])); // Set drag bottom mills

      // Return new top and bottom positions in pixels
      return { top: topMills * pxPerMs, bottom: bottomMills * pxPerMs };
    },
    [pxPerHr, pxPerMs, roundToSnap]
  );

  const deselectAll = useCallback(() => {
    if (selectedTimeslot) setSelectedTimeslot(null);
    if (editingTimeslot) setEditingTimeslot(null);
    if (newTimeslotParams) setNewTimeslotParams(null);
  }, [selectedTimeslot, editingTimeslot, newTimeslotParams]);

  const openAddTimeslot = useCallback(
    (ms: number, length: number = 60 * 60 * 1000) => {
      // setShowAddTimeslot(true);
      // setLastSelectedHour(hour);
      deselectAll();
      setNewTimeslotParams({
        startMs: ms,
        endMs: ms + length,
        activityId: "",
      });
    },
    [deselectAll]
  );
  /**
   * Calculates the new top and bottom positions for a timeslot during dragging.
   */
  const { dragTopPx, dragBottomPx } = useMemo(() => {
    if (!dayBounds) return { dragTopPx: 0, dragBottomPx: 0 };
    if (!timeslots) return { dragTopPx: 0, dragBottomPx: 0 };
    if (!timeslots.length) return { dragTopPx: 0, dragBottomPx: 0 };
    if (!selectedTimeslot) return { dragTopPx: 0, dragBottomPx: 0 };
    const timeslot = timeslots.find((t) => t.id === selectedTimeslot?.id);
    if (!timeslot) return { dragTopPx: 0, dragBottomPx: 0 };

    const { top, bottom } = calculateDragTopBottom(
      timeslot,
      dragHandle,
      cursorYPos,
      dragStartY,
      dayBounds
    );

    return { dragTopPx: top, dragBottomPx: bottom };
  }, [
    dayBounds,
    timeslots,
    selectedTimeslot,
    calculateDragTopBottom,
    dragHandle,
    cursorYPos,
    dragStartY,
  ]);

  /**
   * Updates the dragged timeslot with the new start and end times.
   */
  const getUpdatedDraggedTimeslot = useCallback(
    (timeslots: Timeslot[], draggingTimeslotId: string | null) => {
      const draggedTimeslot = timeslots.find(
        (t) => t.id === draggingTimeslotId
      );
      if (!draggedTimeslot)
        return { updatedTimeslot: null, start: null, end: null };

      const startTimeNearestMinute = Math.round(dragTopMills / 1000 / 60);
      const endTimeNearestMinute = Math.round(dragBottomMills / 1000 / 60);

      const startTimestampMills = startTimeNearestMinute * 60 * 1000;
      const endTimestampMills = endTimeNearestMinute * 60 * 1000;

      return {
        updatedTimeslot: {
          ...draggedTimeslot,
          startTimestampMills,
          endTimestampMills,
        },
        start: startTimeNearestMinute,
        end: endTimeNearestMinute,
      };
    },
    [dragTopMills, dragBottomMills]
  );

  /**
   * Updates the cursor position and checks for conflicts.
   */
  const mouseMove = useCallback(
    (yPos: number) => {
      setCursorYPos(yPos);

      if (!timeslots) return;

      const { updatedTimeslot } = getUpdatedDraggedTimeslot(
        timeslots,
        draggingTimeslot?.id || null
      );
      if (!updatedTimeslot) return;

      // Check for conflicts
      const conflicts = getConflictingTimeslots(timeslots, updatedTimeslot);
      if (conflicts.length > 0) {
        // Highlight conflicting timeslots
        setConflictingTimeslots(conflicts.map((t) => t.id));
      } else {
        // No conflicts, clear any previously highlighted conflicts
        setConflictingTimeslots([]);
      }
    },
    [draggingTimeslot, timeslots, getUpdatedDraggedTimeslot]
  );

  const applyPlaceToTimeslots = useCallback(
    async (locationTimeBlock: LocationTimeBlock, place: Place) => {
      if (!timeslots) return;

      // Find all timeslots that overlap with this location block
      const overlappingTimeslots = timeslots.filter((timeslot) => {
        return (
          timeslot.startTimestampMills <= locationTimeBlock.endMills &&
          timeslot.endTimestampMills >= locationTimeBlock.startMills
        );
      });

      if (overlappingTimeslots.length === 0) return;

      console.log("Updating with place ID:", place.id);

      // Update each overlapping timeslot with the new place
      const timeslotChanges = overlappingTimeslots.map((timeslot) => ({
        action: "update" as const,
        timeslot: {
          ...timeslot,
          placeId: place.id,
        },
      }));

      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        timeslotChanges,
        true
      );
      pushUndoSteps(undoSteps, true);
    },
    [timeslots, cloudSyncedDB, pushUndoSteps]
  );

  const isToday = useMemo(() => {
    return isSameDay(new Date(), parse(date, "yyyy-MM-dd", new Date()));
  }, [date]);

  const performUndo = useCallback(async () => {
    const undoSteps = popUndoSteps();
    if (!undoSteps) return;
    const redoSteps = await applyTimeslotChanges(
      cloudSyncedDB,
      undoSteps,
      true
    );
    pushRedoSteps(redoSteps);
  }, [popUndoSteps, pushRedoSteps, cloudSyncedDB]);

  const performRedo = useCallback(async () => {
    const redoSteps = popRedoSteps();
    if (!redoSteps) return;
    const undoSteps = await applyTimeslotChanges(
      cloudSyncedDB,
      redoSteps,
      true
    );
    pushUndoSteps(undoSteps, false);
  }, [popRedoSteps, pushUndoSteps, cloudSyncedDB]);

  const releaseDrag = useCallback(async () => {
    // Check if all necessary data is available
    if (!draggingTimeslot || !dayBounds || !timeslots) return;

    setConflictingTimeslots([]);
    setButtonDragging(false);
    setDraggingTimeslot(null);

    const { updatedTimeslot, start, end } = getUpdatedDraggedTimeslot(
      timeslots,
      draggingTimeslot.id
    );
    if (!updatedTimeslot) return;

    // Get and apply timeslot adjustments
    const timeslotChanges = await getTimeslotAdjustments(
      timeslots,
      updatedTimeslot
    );
    const undoSteps = await applyTimeslotChanges(
      cloudSyncedDB,
      [
        ...timeslotChanges,
        {
          action: "update",
          timeslot: {
            ...updatedTimeslot,
            startTimestampMills: start * 60 * 1000,
            endTimestampMills: end * 60 * 1000,
          },
        },
      ],
      true
    );

    setTimeout(() => {
      // Push undo steps and reset state
      pushUndoSteps(undoSteps, true);
    }, 20);
  }, [
    dayBounds,
    draggingTimeslot,
    pushUndoSteps,
    timeslots,
    getUpdatedDraggedTimeslot,
    cloudSyncedDB,
  ]);

  useEffect(() => {
    const setCurrentTime = () => {
      // Round to the nearest minute
      const nowMills = new Date().getTime();
      const nowRounded = Math.round(nowMills / 60000) * 60000;
      if (
        dayBounds &&
        (nowRounded < dayBounds[0] || nowRounded > dayBounds[1])
      ) {
        setCurrentTimeMills(0);
        return;
      }
      setCurrentTimeMills(nowRounded);
    };
    setCurrentTime();
    const timer = setInterval(() => {
      setCurrentTime();
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [dayBounds]);

  // Update the date change handlers
  const goToPreviousDay = useCallback(() => {
    const currentDateObj = parse(date, "yyyy-MM-dd", new Date());
    const oneDayBefore = addDays(currentDateObj, -1);
    setDate(format(oneDayBefore, "yyyy-MM-dd"));
  }, [date, setDate]);

  const goToToday = useCallback(() => {
    setDate(format(new Date(), "yyyy-MM-dd"));
    setShowCalendar(false);
  }, [setDate]);

  const goToNextDay = useCallback(() => {
    const currentDateObj = parse(date, "yyyy-MM-dd", new Date());
    const oneDayAfter = addDays(currentDateObj, 1);
    setDate(format(oneDayAfter, "yyyy-MM-dd"));
  }, [date, setDate]);

  useEffect(() => {
    const handleMouseUp = () => {
      if (buttonDragging) {
        releaseDrag();
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (showCalendar) setShowCalendar(false);
        else if (editingTimeslot) setEditingTimeslot(null);
        else if (selectedTimeslot) setSelectedTimeslot(null);
      } else if (event.key === "ArrowUp") {
        // Jump to the previous timeslot
        if (selectedTimeslot && timeslots) {
          const currentIndex = timeslots.findIndex(
            (t) => t.id === selectedTimeslot.id
          );
          if (currentIndex > 0) {
            setSelectedTimeslot(timeslots[currentIndex - 1]);
          }
        }
      } else if (event.key === "ArrowDown") {
        // Jump to the next timeslot
        if (selectedTimeslot && timeslots) {
          const currentIndex = timeslots.findIndex(
            (t) => t.id === selectedTimeslot.id
          );
          if (currentIndex < timeslots.length - 1) {
            setSelectedTimeslot(timeslots[currentIndex + 1]);
          }
        }
      } else if (event.key === "e") {
        // Edit the selected timeslot
        if (selectedTimeslot) {
          setEditingTimeslot(selectedTimeslot.id);
        }
      } else if (event.key === "t") {
        if (!isToday) {
          goToToday();
        } else {
          showSnackbar("Already on today's date", "info", 2000);
        }
      } else if (event.key === "ArrowLeft") {
        // Go to the previous day
        goToPreviousDay();
      } else if (event.key === "ArrowRight") {
        // Go to the next day
        goToNextDay();
      } else if (event.key === "Delete" || event.key === "Backspace") {
        // Delete the selected timeslot
        if (selectedTimeslot) {
          deleteTimeslot(selectedTimeslot.id);
          deselectAll();
        }
      } else if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === "z"
      ) {
        performRedo();
      } else if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        performUndo();
      }
    };
    window.addEventListener("mouseup", handleMouseUp);

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);

      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    selectedTimeslot,
    editingTimeslot,
    timeslots,
    deleteTimeslot,
    deselectAll,
    showCalendar,
    goToPreviousDay,
    goToNextDay,
    buttonDragging,
    releaseDrag,
    performRedo,
    performUndo,
    isToday,
    goToToday,
  ]);

  const scrollToCurrentTime = useCallback(() => {
    const scrollContainer = document.querySelector(".scroll-container");
    if (!scrollContainer) return;
    const currentTimeElement = document.querySelector(".current-time-line");
    if (!currentTimeElement) return;
    const topBar = document.querySelector(".top-bar");
    if (!topBar) return;

    const containerRect = scrollContainer.getBoundingClientRect();
    const timelineRect = currentTimeElement.getBoundingClientRect();
    const relativeTop =
      timelineRect.top - containerRect.top + scrollContainer.scrollTop;

    scrollContainer.scrollTo({
      top: relativeTop - topBar.clientHeight - 20,
      // behavior: "smooth", // Does not work at all
    });
  }, []);

  return (
    <Container
      sx={{
        userSelect: "none",
      }}>
      <Paper
        className="top-bar"
        sx={{
          padding: 2,
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 1000,
        }}>
        <Stack
          direction="column"
          gap={2}
          width="100%"
          justifyContent="space-around">
          {/* Date selector */}
          <Stack
            direction="row"
            gap={2}
            width="100%"
            justifyContent="space-around">
            <Tooltip
              title={
                <Stack direction="row" gap={1}>
                  Go to previous day
                  <KeyIcon keyName="ArrowLeft" />
                </Stack>
              }>
              <Button onClick={goToPreviousDay}>&lt;</Button>
            </Tooltip>
            <Tooltip
              title={
                <Stack direction="row" gap={1}>
                  Go to today
                  <KeyIcon keyName="T" />
                </Stack>
              }>
              <Button onClick={goToToday}>
                <Today />
              </Button>
            </Tooltip>
            <Input
              sx={{
                width: "100%",
                cursor: "pointer",
                userSelect: "none",
              }}
              type="text"
              value={format(
                parse(date, "yyyy-MM-dd", new Date()),
                "d MMM yyyy"
              )}
              onClick={() => setShowCalendar((c) => !c)}
              readOnly
            />
            <Tooltip
              title={
                <Stack direction="row" gap={1}>
                  Go to next day
                  <KeyIcon keyName="ArrowRight" />
                </Stack>
              }>
              <Button onClick={goToNextDay}>&gt;</Button>
            </Tooltip>
            <Button disabled={totalUndoSteps === 0} onClick={performUndo}>
              <Undo sx={{ marginRight: 1 }} /> {totalUndoSteps}
            </Button>
            <Button disabled={totalRedoSteps === 0} onClick={performRedo}>
              <Redo sx={{ marginRight: 1 }} /> {totalRedoSteps}
            </Button>
          </Stack>
          {showCalendar && (
            <Stack direction="row" gap={2} justifyContent="center">
              <DatePickerCalendar
                $isSmallScreen={!isMediumScreen}
                showDoubleView={isMediumScreen}
                next2Label={null}
                prev2Label={null}
                onChange={(value) => {
                  if (value instanceof Date) {
                    setDate(format(value, "yyyy-MM-dd"));
                    setShowCalendar(false);
                  }
                }}
                value={parse(date, "yyyy-MM-dd", new Date())}
              />
            </Stack>
          )}
          {/* Scale selector */}
          <Stack direction="row" gap={3} alignItems="center">
            <ZoomIn color="primary" />
            <Slider
              marks
              size="medium"
              value={pxPerHr}
              onChange={(_, newValue) => {
                setPxPerHr(newValue as number);
              }}
              min={10}
              max={400}
              step={10}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}px`}
              sx={{}}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showLocationHistory}
                  onChange={(e) => setShowLocationHistory(e.target.checked)}
                />
              }
              label={
                <Stack direction="row" gap={0}>
                  <GpsFixedIcon />
                  <HistoryIcon />
                </Stack>
              }
            />
          </Stack>
        </Stack>
      </Paper>

      {/* Timeslots */}
      <Stack
        direction="row"
        gap={1}
        marginTop="40px"
        marginBottom="40px"
        onMouseMove={(e) => {
          mouseMove(e.clientY - e.currentTarget.getBoundingClientRect().top);
        }}>
        {dayBounds && (
          <>
            <TimeslotColumn
              direction="column"
              width="100%"
              gap="1px"
              padding="1px"
              sx={{
                backgroundColor: "lightblue",
              }}>
              {hours.map((hour, index) => (
                <Hour
                  height={pxPerHr}
                  key={index}
                  onMouseEnter={() => {
                    if (!draggingTimeslot) {
                      setHoveredTimeslot(null);
                    }
                  }}
                  onClick={() => {
                    deselectAll();
                  }}>
                  {index > 0 && (
                    <HourLabel>
                      {`${hour.toString().padStart(2, "0")}:00`}
                    </HourLabel>
                  )}
                </Hour>
              ))}
              {shouldShowDragButtons && (
                <>
                  <ScaleButton
                    sx={{
                      top: `${buttonPositions[0] - 10}px`,
                    }}
                    className="handle top"
                    variant="text"
                    onMouseDown={() => {
                      setButtonDragging(true);
                      setDragStartY(cursorYPos);
                      setDraggingTimeslot(selectedTimeslot);
                      setDragHandle("top");
                    }}>
                    <DragHandle className="handleIcon" />
                  </ScaleButton>
                  <DragButton
                    sx={{
                      top: `${buttonPositions[1] - 15}px`,
                    }}
                    className="handle middle"
                    variant="text"
                    onMouseDown={() => {
                      setButtonDragging(true);
                      setDragStartY(cursorYPos);
                      setDraggingTimeslot(selectedTimeslot);
                      setDragHandle("middle");
                    }}>
                    <DragHandle className="handleIcon" />
                  </DragButton>
                  <ScaleButton
                    sx={{
                      top: `${buttonPositions[2] - 10}px`,
                    }}
                    className="handle bottom"
                    variant="text"
                    onMouseDown={() => {
                      setButtonDragging(true);
                      setDragStartY(cursorYPos);
                      setDraggingTimeslot(selectedTimeslot);
                      setDragHandle("bottom");
                    }}>
                    <DragHandle className="handleIcon" />
                  </ScaleButton>
                  <MergeButton
                    sx={{
                      top: `${buttonPositions[0] - 10}px`,
                    }}
                    variant="text"
                    onClick={() => {
                      processMerges(selectedTimeslot?.id || null, "up");
                    }}>
                    <ArrowUpward className="handleIcon" />
                  </MergeButton>
                  <MergeButton
                    sx={{
                      top: `${buttonPositions[2] - 10}px`,
                    }}
                    variant="text"
                    onClick={() => {
                      processMerges(selectedTimeslot?.id || null, "down");
                    }}>
                    <ArrowDownward className="handleIcon" />
                  </MergeButton>
                </>
              )}

              {timeslotBoundaries.map((mills, index) => {
                // If this belongs to the highlighted timeslot, or dragged timeslot, don't show it
                if (
                  [
                    draggingTimeslot?.startTimestampMills,
                    draggingTimeslot?.endTimestampMills,
                  ].includes(mills)
                ) {
                  return null;
                }

                // Convert mills to px
                const px = (mills - dayBounds[0]) * pxPerMs;
                // Convert mills to time using date-fns
                const time = new TZDate(mills, getComputerTimezone());
                const timeString = format(time, "HH:mm");
                return (
                  <TimeDisplay key={index + mills} top={px - 10}>
                    {timeString}
                  </TimeDisplay>
                );
              })}

              {(hoveredTimeslot || draggingTimeslot) &&
                (() => {
                  let topTime = hoveredTimeslot?.startTimestampMills || 0;
                  let bottomTime = hoveredTimeslot?.endTimestampMills || 0;
                  if (draggingTimeslot) {
                    topTime = dragTopMills;
                    bottomTime = dragBottomMills;
                  }
                  const topTimeString = format(
                    new TZDate(topTime, getComputerTimezone()),
                    "HH:mm"
                  );
                  const bottomTimeString = format(
                    new TZDate(bottomTime, getComputerTimezone()),
                    "HH:mm"
                  );
                  return (
                    <>
                      <TimeDisplay
                        top={(topTime - dayBounds[0]) * pxPerMs - 10}
                        theme="hover">
                        {topTimeString}
                      </TimeDisplay>
                      <TimeDisplay
                        top={(bottomTime - dayBounds[0]) * pxPerMs - 10}
                        theme="hover">
                        {bottomTimeString}
                      </TimeDisplay>
                    </>
                  );
                })()}
              {!draggingTimeslot &&
                blankTimeslots.map((timeslot) => (
                  <BlankTimeslotRow
                    key={
                      timeslot.startTimestampMills + timeslot.endTimestampMills
                    }
                    startTimestampMills={timeslot.startTimestampMills}
                    endTimestampMills={timeslot.endTimestampMills}
                    dayBounds={dayBounds}
                    pxPerMs={pxPerMs}
                    onMouseEnter={() => {
                      if (!draggingTimeslot) {
                        setHoveredTimeslot(null);
                      }
                    }}
                    onClick={() => {
                      deselectAll();
                    }}
                  />
                ))}
              {timeslots
                ?.filter((t) => draggingTimeslot?.id !== t.id)
                .map((timeslot) => (
                  <TimeslotComponent
                    key={timeslot.id + timeslot.startTimestampMills}
                    timeslot={timeslot}
                    dayBounds={dayBounds}
                    pxPerMs={pxPerMs}
                    activityCategoryMap={activityCategoryMap}
                    isSelected={selectedTimeslot?.id === timeslot.id}
                    isConflicting={conflictingTimeslots.includes(timeslot.id)}
                    isEditing={editingTimeslot === timeslot.id}
                    onMouseEnter={() => {
                      if (!draggingTimeslot) {
                        setHoveredTimeslot(timeslot);
                      }
                    }}
                    onClick={() => {
                      deselectAll();
                      setSelectedTimeslot(timeslot);
                      console.log("Selected timeslot", timeslot);
                    }}
                    onDoubleClick={() => {
                      if (editingTimeslot !== timeslot.id) {
                        setEditingTimeslot(timeslot.id);
                      } else {
                        setEditingTimeslot(null);
                      }
                    }}
                  />
                ))}
              {draggingTimeslot && (
                <DraggingTimeslotComponent
                  timeslot={draggingTimeslot}
                  activity={activityCategoryMap[draggingTimeslot.activityId]}
                  top={dragTopPx}
                  bottom={dragBottomPx}
                  dayBounds={dayBounds}
                  pxPerMs={pxPerMs}
                />
              )}
              {shouldShowAddButton && (
                <AddButton
                  height={addButtonPosition?.addHeightPx || pxPerHr}
                  variant="outlined"
                  sx={{
                    top: `${addButtonPosition?.addPx}px`,
                  }}
                  onClick={() => {
                    if (addButtonPosition) {
                      openAddTimeslot(
                        addButtonPosition.addMs,
                        addButtonPosition.addMsLength
                      );
                    }
                  }}>
                  +
                </AddButton>
              )}

              {newTimeslotParams !== null && (
                <NewTimeslotDraggableIndicator
                  newTimeslotParams={newTimeslotParams}
                  pxPerMs={pxPerMs}
                  dayBounds={dayBounds}
                  setNewTimeslotTimes={(times) => {
                    setNewTimeslotParams({
                      ...newTimeslotParams,
                      ...times,
                    });
                  }}
                />
              )}

              <CurrentTimeLine
                dayStartMills={dayBounds[0]}
                pxPerMs={pxPerMs}
                currentTimeMills={currentTimeMills}
                showTime={true}
              />
            </TimeslotColumn>

            {showLocationHistory && (
              <LocationHistoryColumn
                dayBounds={dayBounds}
                pxPerMs={pxPerMs}
                currentTimeMills={currentTimeMills}
                locationHistory={locationHistory}
                locationTimeBlocks={locationTimeBlocks}
                applyPlaceToTimeslots={applyPlaceToTimeslots}
                setNewTimeslotParams={setNewTimeslotParams}
              />
            )}
          </>
        )}
      </Stack>

      {newTimeslotParams !== null ? (
        <NewTimeslotInfo
          newTimeslotParams={newTimeslotParams}
          cancel={() => setNewTimeslotParams(null)}
          addTimeslot={(timeslot) => {
            setNewTimeslotParams(null);
            addTimeslot(timeslot);
          }}
          updateActivity={(activityId) => {
            setNewTimeslotParams({
              ...newTimeslotParams,
              activityId,
            });
          }}
        />
      ) : selectedTimeslot ? (
        <SelectedTimeslotInfo
          selectedTimeslot={selectedTimeslot}
          selectedTimeslotLotLon={selectedTimeslotLotLon}
          editingTimeslot={editingTimeslot}
          setEditingTimeslot={(editingTimeslot) => {
            setEditingTimeslot(editingTimeslot);
          }}
          onCancelEdit={() => {
            setEditingTimeslot(null);
          }}
          changeTimeslotActivity={(activityId, startMs, endMs) => {
            if (!selectedTimeslot) return;
            changeTimeslotActivityAndTime(
              selectedTimeslot,
              activityId,
              startMs,
              endMs
            );
          }}
          changeTimeslotPlace={(placeId) => {
            if (!selectedTimeslot) return;
            changeTimeslotPlace(selectedTimeslot, placeId);
          }}
        />
      ) : isToday ? (
        <SuggestedTimeslotInfo
          setAddingActivity={(activityId) => {
            setNewTimeslotParams({
              startMs: currentTimeMills,
              endMs: currentTimeMills + 60 * 60 * 1000,
              activityId,
            });
            // Add scroll behavior here
            scrollToCurrentTime();
          }}
        />
      ) : null}
    </Container>
  );
};
