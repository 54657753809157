import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Activity,
  Category,
  db,
  LocationHistory,
  Place,
  Timeslot,
} from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";
import { useSettings } from "../../../hooks/useSettings";
import {
  clearDatabase,
  seedDefaults,
  setAppInitialised,
} from "../../../utils/db_helpers";

type ExportData = {
  categories: Category[];
  activities: Activity[];
  timeslots: Timeslot[];
  places: Place[];
  locationHistory: LocationHistory[];
  uniqueIdentifier: string;
};

export const DataExport: React.FC = () => {
  const [showImportWarning, setShowImportWarning] = useState(false);
  const [showClearWarning, setShowClearWarning] = useState(false);
  const [importFile, setImportFile] = useState<File | null>(null);
  const { disableCloudSync } = useSettings();
  const cloudDb = useCloudSyncedDB();

  const [isImporting, setIsImporting] = useState(false);
  const [importProgress, setImportProgress] = useState(0);
  const [importProgressText, setImportProgressText] = useState("");
  const [importCount, setImportCount] = useState(0);
  const [importTotal, setImportTotal] = useState(0);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    setImportFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "application/json": [".json"] },
    multiple: false,
  });

  const exportData = async () => {
    console.log("Exporting data");

    const categories = await db.categories.toArray();
    const activities = await db.activities.toArray();
    const timeslots = await db.timeslots.toArray();
    const places = await db.places.toArray();
    const locationHistory = await db.locationHistories.toArray();

    const exportData: ExportData = {
      categories,
      activities,
      timeslots,
      places,
      locationHistory,
      uniqueIdentifier: localStorage.getItem("uniqueIdentifier") || "",
    };

    const exportString = JSON.stringify(exportData, null, 2);

    // Download the data as a json file
    const blob = new Blob([exportString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date();
    const formattedDate = format(currentDate, "ddMMyy_HHmm");
    link.download = `dumbertime_export_${formattedDate}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clearDatabaseHandler = async () => {
    console.log("Clearing database");
    await clearDatabase();
    await seedDefaults();
    console.log("Database cleared");
    setShowClearWarning(false);
  };

  const importData = async () => {
    console.log("Importing data");
    if (!importFile) {
      alert("No data to import");
      return;
    }

    try {
      setIsImporting(true);
      setImportProgress(0);
      setImportProgressText("Loading data");
      setImportCount(0);
      const fileContent = await importFile.text();
      const importData = JSON.parse(fileContent) as ExportData;

      const totalRows =
        importData.categories.length +
        importData.activities.length +
        importData.places.length +
        importData.timeslots.length +
        importData.locationHistory.length;
      let processedRows = 0;
      setImportTotal(totalRows);

      const updateProgress = async () => {
        const progress = Math.round((processedRows / totalRows) * 100);
        setImportProgress(progress);
        setImportCount(processedRows);
        await new Promise((resolve) => setTimeout(resolve, 0));
      };

      await disableCloudSync();
      await cloudDb.emptyCloudBackupChanges();
      setImportProgressText("Clearing database");
      await clearDatabase();
      setAppInitialised(true, importData.uniqueIdentifier);

      setImportProgressText("Adding categories");
      await db.categories.bulkAdd(importData.categories);
      processedRows += importData.categories.length;
      updateProgress();

      setImportProgressText("Adding activities");
      await db.activities.bulkAdd(importData.activities);
      processedRows += importData.activities.length;
      updateProgress();

      setImportProgressText("Adding places");
      await db.places.bulkAdd(importData.places);
      processedRows += importData.places.length;
      updateProgress();

      setImportProgressText("Adding timeslots");
      const CHUNK_SIZE = 1000;
      for (let i = 0; i < importData.timeslots.length; i += CHUNK_SIZE) {
        const chunk = importData.timeslots.slice(i, i + CHUNK_SIZE);
        await db.timeslots.bulkAdd(chunk);
        processedRows += chunk.length;
        await new Promise((resolve) => setTimeout(resolve, 0));
        updateProgress();
      }

      setImportProgressText("Adding location history");
      for (let i = 0; i < importData.locationHistory.length; i += CHUNK_SIZE) {
        const chunk = importData.locationHistory.slice(i, i + CHUNK_SIZE);
        await db.locationHistories.bulkAdd(chunk);
        processedRows += chunk.length;
        await new Promise((resolve) => setTimeout(resolve, 0));
        updateProgress();
      }

      setImportProgressText("Import completed");
      setImportProgress(100);

      console.log("Import completed");
      setShowImportWarning(false);
      setImportFile(null);

      setAppInitialised(true);
      setIsImporting(false);
    } catch (error) {
      console.error(error);
      alert("Invalid data");
    }
  };

  return (
    <Paper elevation={2} sx={{ padding: 3, marginTop: 4 }}>
      <Typography variant="h5" gutterBottom>
        Data Export and Import
      </Typography>
      <Stack spacing={2}>
        <Button variant="contained" onClick={exportData} disabled={isImporting}>
          Export Data
        </Button>
        <Box
          {...getRootProps()}
          sx={{ border: "1px dashed grey", p: 2, my: 2, cursor: "pointer" }}>
          <input {...getInputProps()} />
          <Typography>
            {importFile
              ? `File selected: ${importFile.name}`
              : "Drop import.json file here or click to select"}
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={() => setShowImportWarning(true)}
          disabled={!importFile || isImporting}>
          Import Data (Clears existing data)
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={() => setShowClearWarning(true)}
          disabled={isImporting}>
          Clear Database
        </Button>

        {(isImporting || importProgressText) && (
          <>
            <Typography>
              {importProgressText} - {importCount} / {importTotal} (
              {importProgress}%)
            </Typography>

            <LinearProgress
              value={importProgress}
              variant="determinate"
              sx={{ width: "100%" }}
            />
          </>
        )}

        {showImportWarning && !isImporting && (
          <Alert
            severity="warning"
            action={
              <Button color="inherit" size="small" onClick={importData}>
                Confirm
              </Button>
            }>
            Warning: This action will erase all existing data and replace it
            with the imported data.
          </Alert>
        )}
        {showClearWarning && (
          <Alert
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={clearDatabaseHandler}>
                Confirm
              </Button>
            }>
            Warning: This action will erase all existing data. Are you sure?
          </Alert>
        )}
      </Stack>
    </Paper>
  );
};
