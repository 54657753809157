import { Cancel, Check } from "@mui/icons-material";
import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { ICONS } from "../../const/icons";
import { useActivityJoinCategory } from "../../hooks/useActivityJoinCategory";
import { toTitleCase } from "../../utils/string";

interface ActivityOption {
  id: string;
  label: string;
  category?: string;
  colour?: string;
}

const ChangeTimeslot = ({
  activityId,
  changedTimeslot,
  cancelled,
}: {
  activityId: string;
  changedTimeslot?: (activityId: string) => void;
  cancelled?: () => void;
}) => {
  const { activityCategoryArray, activityCategoryMap } =
    useActivityJoinCategory();
  const [isLoading, setIsLoading] = useState(true);
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const activityOptions: ActivityOption[] = useMemo(() => {
    if (!activityCategoryArray) return [];
    return activityCategoryArray.map((a) => ({
      id: a.id,
      label: a.name,
      category: a.category,
      colour: a.colour,
    }));
  }, [activityCategoryArray]);

  const [selectedActivity, setSelectedActivity] =
    useState<ActivityOption | null>(null);

  useEffect(() => {
    if (activityOptions.length > 0) {
      setIsLoading(false);
      const activity = activityOptions.find((a) => a.id === activityId) || null;
      setSelectedActivity(activity);
      setAutocompleteKey((prev) => prev + 1);
    }
  }, [activityOptions, activityId]);

  // Focus and select input text when component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.key === "Enter") {
        if (changedTimeslot && selectedActivity?.id) {
          changedTimeslot(selectedActivity.id);
        }
      }
      if (event.key === "Escape") {
        if (cancelled) cancelled();
      }
      event.stopPropagation();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [cancelled, changedTimeslot, selectedActivity]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("keydown", (e) => {
        e.stopPropagation();
      });
    }
  }, []);

  if (selectedActivity === null || isLoading) return null;

  const Icon = activityCategoryMap[selectedActivity.id]?.icon
    ? ICONS[activityCategoryMap[selectedActivity.id].icon]
    : null;

  return (
    <Stack direction={"row"} alignItems="center" justifyContent="center">
      {Icon && <Icon sx={{ color: selectedActivity.colour, marginRight: 2 }} />}
      <Autocomplete
        key={autocompleteKey}
        options={activityOptions}
        size="small"
        sx={{ width: "100%" }}
        autoHighlight
        disableClearable
        loading={isLoading}
        value={selectedActivity}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(_, newValue: ActivityOption | null) => {
          if (newValue) {
            setSelectedActivity(newValue);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            const textField = inputRef.current;
            if (
              textField &&
              textField.getAttribute("aria-expanded") === "true"
            ) {
              event.stopPropagation();
            }
          } else {
            event.stopPropagation();
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            select={false}
            inputRef={inputRef}
          />
        )}
        renderOption={(props, option) => {
          if (!option?.id || !activityCategoryMap?.[option.id]) {
            return null;
          }
          const Icon = ICONS[activityCategoryMap[option.id]?.icon];
          return (
            <Box component="li" {...props} key={option.id}>
              <Stack direction="row" gap={2}>
                {Icon && <Icon sx={{ color: option.colour }} />}
                {toTitleCase(option.label)}
              </Stack>
            </Box>
          );
        }}
      />

      <Button
        sx={{ minWidth: 0 }}
        onClick={() => {
          if (changedTimeslot && selectedActivity) {
            changedTimeslot(selectedActivity.id);
          }
        }}>
        <Check />
      </Button>
      <Button
        sx={{ minWidth: 0 }}
        onClick={() => {
          if (cancelled) cancelled();
        }}
        color="error">
        <Cancel />
      </Button>
    </Stack>
  );
};

export default ChangeTimeslot;
