import styled from "@emotion/styled";
import {
  Alert,
  Autocomplete,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  Slider,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { Category } from "../../../database/db";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";

const ColourSquare = styled.div<{ colour: string }>`
  background-color: ${(props) => props.colour};
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 8px;
`;

interface MergeCategoryFormProps {
  category: Category;
  categories: Category[];
  onCancel: () => void;
  onMerge: () => void;
}

export const MergeCategoryForm: React.FC<MergeCategoryFormProps> = ({
  category,
  categories,
  onCancel,
  onMerge,
}) => {
  const cloudSyncedDB = useCloudSyncedDB();
  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
      <Typography variant="h6" gutterBottom>
        Merge Category: {category.name}
      </Typography>
      <Alert severity="warning" sx={{ marginBottom: 2 }}>
        Warning: This action is not reversible. All activities associated with
        this category will be reassigned to the selected category.
      </Alert>
      <Formik
        initialValues={{
          targetCategory: null as Category | null,
          confirmMerge: 0,
        }}
        validate={(values) => {
          const errors: { targetCategory?: string; confirmMerge?: string } = {};
          if (!values.targetCategory) {
            errors.targetCategory = "Target category is required";
          }
          if (values.confirmMerge < 90) {
            errors.confirmMerge =
              "Please confirm that you are at least 90% sure to merge";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.targetCategory && values.confirmMerge >= 90) {
            try {
              // Update all activities
              await cloudSyncedDB.updateActivitiesForCategory(category.id, {
                categoryId: values.targetCategory.id,
              });

              // Delete the original category
              await cloudSyncedDB.deleteCategory(category.id);

              onMerge();
            } catch (error) {
              console.error("Error merging category:", error);
              // Handle error (e.g., show an error message)
            }
          }
          setSubmitting(false);
        }}>
        {({ values, setFieldValue, errors, touched, isSubmitting }) => (
          <Form>
            <Stack spacing={2}>
              <Autocomplete
                options={categories.filter((c) => c.id !== category.id)}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props}>
                    <ColourSquare colour={option.colour} />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Merge into"
                    error={touched.targetCategory && !!errors.targetCategory}
                    helperText={touched.targetCategory && errors.targetCategory}
                  />
                )}
                onChange={(_, value) => setFieldValue("targetCategory", value)}
              />
              <Typography gutterBottom>
                Are you sure you want to merge these categories?
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography>Hmmm...</Typography>
                <Slider
                  value={values.confirmMerge}
                  onChange={(_, newValue) =>
                    setFieldValue("confirmMerge", newValue)
                  }
                  aria-labelledby="confirm-merge-slider"
                  valueLabelDisplay="off"
                  step={1}
                  min={0}
                  max={100}
                />
                <Typography>Definitely!</Typography>
              </Stack>
              {touched.confirmMerge && errors.confirmMerge && (
                <Typography color="error" variant="caption">
                  {errors.confirmMerge}
                </Typography>
              )}
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button onClick={onCancel} variant="outlined">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    isSubmitting ||
                    values.confirmMerge < 90 ||
                    !values.targetCategory
                  }>
                  Merge
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
