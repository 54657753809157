import { Box, Container, Tab, Tabs } from "@mui/material";
import { useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSettings } from "../../hooks/useSettings";
import OverviewSection from "./components/OverviewSection";
import StatsCalendar from "./components/StatsCalendar";
import Trends from "./components/Trends";

const tabRoutes = ["recents", "trends", "calendar"];

export const Analytics = () => {
  const { page } = useParams();
  const navigate = useNavigate();

  const { startAnalyticsScreen } = useSettings();
  const currentTab = tabRoutes.indexOf(page || "");

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    navigate(`/analytics/${tabRoutes[newValue]}`);
  };

  useEffect(() => {
    if (!page) {
      navigate(`/analytics/${startAnalyticsScreen}`);
    }
  }, [page, startAnalyticsScreen, navigate]);

  const renderTabContent = () => {
    switch (page) {
      case "recents":
        return <OverviewSection />;
      case "trends":
        return <Trends />;
      case "calendar":
        return <StatsCalendar />;
    }
  };

  return (
    <Container>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        centered
        sx={{ width: "100%" }}>
        <Tab label="Recents" />
        <Tab label="Trends" />
        <Tab label="Calendar" />
      </Tabs>
      <Box mt={5}>{renderTabContent()}</Box>
    </Container>
  );
};
