import { AddOutlined, ArchiveOutlined } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  List,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useEffect, useMemo, useState } from "react";
import { Activity, Category, db } from "../../../database/db";
import { getAllActivityMetadata } from "../../../utils/activity_management";
import { ActivityBulkArchive } from "./ActivityBulkArchive";
import { ActivityForm } from "./ActivityForm";
import { ActivityRow } from "./ActivityRow";
import { MergeActivityForm } from "./MergeActivityForm";
import { useCloudSyncedDB } from "../../../hooks/useCloudSyncedDb";

export const ActivityComponent: React.FC<{
  selectedCategory: Category | null;
}> = ({ selectedCategory }) => {
  const cloudSyncedDB = useCloudSyncedDB();
  const activities = useLiveQuery(() => db.activities.toArray(), []);
  const categories = useLiveQuery(() => db.categories.toArray(), []);
  const [editingActivity, setEditingActivity] = useState<Activity | null>(null);
  const [deletingActivity, setDeletingActivity] = useState<Activity | null>(
    null
  );
  const [mergingActivity, setMergingActivity] = useState<Activity | null>(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showAllActivities, setShowAllActivities] = useState(false);
  const [showArchivedActivities, setShowArchivedActivities] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const [showBulkArchiveForm, setShowBulkArchiveForm] = useState(false);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedCategories([selectedCategory]);
    } else {
      setSelectedCategories([]);
    }
  }, [selectedCategory]);

  const handleArchive = (activity: Activity) => {
    cloudSyncedDB.updateActivity(activity.id, { archived: !activity.archived });
  };
  const handleEdit = (activity: Activity) => {
    setEditingActivity(activity);
  };

  const handleUpdate = async (updatedActivity: Omit<Activity, "id">) => {
    if (editingActivity) {
      await cloudSyncedDB.updateActivity(editingActivity.id, updatedActivity);
      setEditingActivity(null);
    }
  };

  const handleCancel = () => {
    setEditingActivity(null);
    setShowAddForm(false);
    setMergingActivity(null);
    setShowBulkArchiveForm(false);
  };

  const handleDeleteConfirmation = (activity: Activity) => {
    setDeletingActivity(activity);
  };

  const handleDelete = async () => {
    if (deletingActivity) {
      await cloudSyncedDB.deleteActivity(deletingActivity.id);
      setDeletingActivity(null);
    }
  };

  const handleCancelDelete = () => {
    setDeletingActivity(null);
  };

  const addActivity = async (values: Omit<Activity, "id">) => {
    await cloudSyncedDB.addActivity({
      ...values,
    });
    setShowAddForm(false);
  };

  const handleMerge = (activity: Activity) => {
    setMergingActivity(activity);
  };

  const activityMetadata = useLiveQuery<
    Record<
      string,
      {
        lastUsedTime: number;
        timeslotCount: number;
      }
    >
  >(async () => {
    return await getAllActivityMetadata();
  }, []);

  const filteredActivities = useMemo<
    (Activity & {
      lastUsedTime: number | undefined;
      timeslotCount: number | undefined;
    })[]
  >(() => {
    const filtered = activities?.filter(
      (activity) =>
        (selectedCategories.length === 0 ||
          selectedCategories.some((cat) => cat.id === activity.categoryId)) &&
        activity.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
        showArchivedActivities === !!activity.archived
    );
    const withMeta = filtered?.map((activity) => ({
      ...activity,
      lastUsedTime: activityMetadata?.[activity.id]?.lastUsedTime,
      timeslotCount: activityMetadata?.[activity.id]?.timeslotCount,
    }));
    withMeta?.sort((a, b) => {
      if (!a.timeslotCount && !b.timeslotCount) {
        if (!a.lastUsedTime && !b.lastUsedTime) {
          return 0;
        }
        if (!a.lastUsedTime) return 1;
        if (!b.lastUsedTime) return -1;
      }
      if (!a.timeslotCount) return 1;
      if (!b.timeslotCount) return -1;
      return b.timeslotCount - a.timeslotCount;
    });
    return withMeta || [];
  }, [
    activities,
    selectedCategories,
    nameFilter,
    showArchivedActivities,
    activityMetadata,
  ]);

  const displayedActivities = showAllActivities
    ? filteredActivities
    : filteredActivities.slice(0, 10);

  if (editingActivity) {
    return (
      <Paper elevation={2} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>
          Edit Activity
        </Typography>
        <ActivityForm
          activity={editingActivity}
          categories={categories || []}
          onSubmit={handleUpdate}
          onCancel={handleCancel}
        />
      </Paper>
    );
  }

  if (mergingActivity && activities) {
    return (
      <MergeActivityForm
        activity={mergingActivity}
        activities={activities}
        onCancel={handleCancel}
        onMerge={() => {
          setMergingActivity(null);
        }}
      />
    );
  }

  if (showBulkArchiveForm) {
    return (
      <Paper elevation={2} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>
          Bulk Archive Activities
        </Typography>

        <ActivityBulkArchive onCancel={handleCancel} />
      </Paper>
    );
  }

  return (
    <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
      <Stack
        direction="row"
        spacing={2}
        marginTop={2}
        marginBottom={3}
        justifyContent={"space-between"}>
        <Typography variant="h4" gutterBottom>
          Activities <small>({activities?.length ?? 0})</small>
        </Typography>
        <Button
          variant="outlined"
          onClick={() => setShowBulkArchiveForm(true)}
          startIcon={<ArchiveOutlined />}>
          Bulk Archive
        </Button>
      </Stack>

      <Stack direction="column" spacing={2}>
        <Stack direction={{ sm: "column", md: "row" }} spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center" width="100%">
            <Autocomplete
              multiple
              options={categories || []}
              value={selectedCategories}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Filter by Categories" />
              )}
              onChange={(_, newValue) => setSelectedCategories(newValue)}
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  color={"secondary"}
                  checked={showArchivedActivities}
                  onChange={() => setShowArchivedActivities((a) => !a)}
                />
              }
              label={"Archived"}
            />
          </Stack>
          <TextField
            label="Filter by Name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            margin="normal"
            fullWidth
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="body2">
            Showing {displayedActivities.length} of {filteredActivities.length}{" "}
            activities
          </Typography>
          {filteredActivities.length > 10 &&
            (!showAllActivities ? (
              <Button onClick={() => setShowAllActivities(true)}>
                Show all ({filteredActivities.length})
              </Button>
            ) : (
              <Button onClick={() => setShowAllActivities(false)}>
                Show less
              </Button>
            ))}
          <Button
            startIcon={<AddOutlined />}
            variant="contained"
            onClick={() => setShowAddForm(true)}>
            Add
          </Button>
        </Stack>
      </Stack>
      {!showAddForm && (
        <>
          {displayedActivities.length > 0 ? (
            <>
              <List>
                {displayedActivities.map((activity) => (
                  <ActivityRow
                    key={activity.id}
                    activity={activity}
                    category={categories?.find(
                      (c) => c.id === activity.categoryId
                    )}
                    onArchive={handleArchive}
                    onEdit={handleEdit}
                    onDelete={handleDeleteConfirmation}
                    onMerge={handleMerge}
                  />
                ))}
              </List>
              {displayedActivities.length < filteredActivities.length && (
                <Typography variant="body2">
                  {filteredActivities.length - displayedActivities.length} more
                  activities not shown
                </Typography>
              )}
            </>
          ) : (
            <Typography variant="body1">No activities found</Typography>
          )}
          {deletingActivity && (
            <Alert
              severity="warning"
              action={
                <>
                  <Button color="inherit" size="small" onClick={handleDelete}>
                    Confirm
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={handleCancelDelete}>
                    Cancel
                  </Button>
                </>
              }>
              Are you sure you want to delete {deletingActivity.name}?
            </Alert>
          )}
        </>
      )}
      {showAddForm && (
        <>
          <Typography variant="h6" gutterBottom>
            Add New Activity
          </Typography>
          <ActivityForm
            categories={categories || []}
            onSubmit={addActivity}
            onCancel={handleCancel}
          />
        </>
      )}
    </Paper>
  );
};
