import { Cancel, Check } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useEffect, useMemo, useState } from "react";
import { db } from "../../database/db";
import { haversineDistance } from "../../utils/location";

interface PlaceOption {
  id: string;
  label: string;
  distance: string;
}

const ChangePlace = ({
  placeId,
  changedPlace,
  cancelled,
  sortFromLatLon,
}: {
  placeId: string;
  changedPlace?: (placeId: string | null) => void;
  cancelled?: () => void;
  sortFromLatLon?: { latitude: number; longitude: number };
}) => {
  const places = useLiveQuery(() => db.places.toArray(), []);

  const placeOptions: PlaceOption[] = useMemo(() => {
    if (!places) return [];
    if (!sortFromLatLon) {
      return places.map((place) => ({
        id: place.id,
        label: place.name,
        distance: "?",
      }));
    }
    const placeOptions = places
      .map((place) => {
        const distance = haversineDistance(
          sortFromLatLon.latitude,
          sortFromLatLon.longitude,
          place.latitude,
          place.longitude
        );
        return {
          id: place.id,
          label: place.name,
          distance: distance,
        };
      })
      .sort((a, b) => a.distance - b.distance);

    return placeOptions.map((place) => ({
      ...place,
      distance:
        place.distance > 1000
          ? `${(place.distance / 1000).toFixed(1)}k`
          : `${place.distance.toFixed(0)}m`,
    }));
  }, [places, sortFromLatLon]);

  const [selectedPlace, setSelectedPlace] = useState<PlaceOption | null>(null);

  useEffect(() => {
    if (placeOptions.length === 0) return;
    if (!placeId) return;
    setSelectedPlace(placeOptions.find((p) => p.id === placeId) || null);
  }, [placeOptions, placeId]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (cancelled) cancelled();
        event.stopPropagation();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [cancelled]);

  return (
    <Stack direction={"row"} flexGrow={1}>
      <Autocomplete
        size="small"
        options={placeOptions}
        value={selectedPlace}
        sx={{ width: "100%", padding: 0 }}
        autoHighlight={true}
        autoFocus={true}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            InputProps={{
              ...params.InputProps,
              autoFocus: true,
            }}
          />
        )}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <Box
              key={key}
              component="li"
              sx={{
                width: "100%",
              }}
              {...optionProps}>
              <Stack direction="row" gap={1} width="100%">
                <Typography
                  variant="body1"
                  flexGrow={1}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                  {option.label}
                </Typography>
                <Typography variant="body2">{option.distance}</Typography>
              </Stack>
            </Box>
          );
        }}
        onChange={(_: React.SyntheticEvent, newValue: PlaceOption | null) => {
          setSelectedPlace(newValue);
        }}
      />

      <Button
        sx={{
          minWidth: 0,
        }}
        onClick={() => {
          if (changedPlace) changedPlace(selectedPlace?.id || null);
        }}>
        <Check />
      </Button>
      <Button
        sx={{
          minWidth: 0,
        }}
        onClick={() => {
          if (cancelled) cancelled();
        }}
        color="error">
        <Cancel />
      </Button>
    </Stack>
  );
};

export default ChangePlace;
