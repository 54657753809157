import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ICONS } from "../../../const/icons";
import { Timeslot } from "../../../database/db";
import { ActivityJoinCategory } from "../../../database/helpers";
import { timeDisplay } from "../../../utils/time";
import { MIN_TIMESLOT_HEIGHT } from "./TimeslotComponent";

const TimeslotRow = styled(Box)`
  position: absolute;
  left: 50px;
  width: calc(100% - 120px);
  z-index: 1;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #aaaaaa;
  cursor: pointer;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

interface DraggingTimeslotComponentProps {
  timeslot: Timeslot;
  activity: ActivityJoinCategory;
  top: number;
  bottom: number;
  dayBounds: number[];
  pxPerMs: number;
}

const DraggingTimeslotComponent: React.FC<DraggingTimeslotComponentProps> = ({
  timeslot,
  activity,
  top,
  bottom,
  pxPerMs,
}) => {
  const Icon = ICONS[activity.icon];

  const height = bottom - top;
  const minutes = Math.round(height / pxPerMs / 1000 / 60);

  return (
    <TimeslotRow
      key={timeslot.id}
      sx={{
        top: `${top}px`,
        height: `${height}px`,
        backgroundColor: `${activity.colour}66`,
      }}>
      <Stack direction="row" padding={1} gap={1}>
        {height >= MIN_TIMESLOT_HEIGHT && (
          <>
            <Icon />
            <Typography
              textAlign={"left"}
              sx={{
                flexGrow: 1,
              }}>
              {activity.name}
            </Typography>
            <Typography>{timeDisplay(minutes)}</Typography>
          </>
        )}
      </Stack>
    </TimeslotRow>
  );
};

export default DraggingTimeslotComponent;
