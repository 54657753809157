import styled from "@emotion/styled";
import {
  DeleteOutlined,
  EditOutlined,
  MergeOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { Category, db } from "../../../database/db";
import { useSettings } from "../../../hooks/useSettings";
import { DEV_COLOUR } from "../../../const/colours";

const ColourSquare = styled(Box)<{ c: string }>`
  background-color: ${(props) => props.c};
  width: 30px;
  height: 30px;
  border-radius: 5px;
`;

interface CategoryRowProps {
  category: Category;
  onEdit: (category: Category) => void;
  onDelete: (category: Category) => void;
  onMerge: (category: Category) => void;
  onClick: (category: Category) => void;
}

export const CategoryRow: React.FC<CategoryRowProps> = ({
  category,
  onEdit,
  onDelete,
  onMerge,
  onClick,
}) => {
  const activityCount = useLiveQuery(
    () => db.activities.where("categoryId").equals(category.id).count(),
    [category.id]
  );

  const { developerMode } = useSettings();

  return (
    <ListItem>
      <ColourSquare c={category.colour}></ColourSquare>
      <ListItemText
        sx={{ marginLeft: 2 }}
        primary={category.name}
        secondary={`Activities: ${activityCount ?? "Loading..."}`}
      />
      {developerMode && (
        <Tooltip title={`${category.id}`}>
          <Typography
            sx={{
              ml: 2,
              fontSize: "0.8rem",
              color: DEV_COLOUR,
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            ID: {category.id}
          </Typography>
        </Tooltip>
      )}

      <Tooltip title="View">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onClick(category);
          }}>
          <VisibilityOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton onClick={() => onEdit(category)}>
          <EditOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Merge">
        <IconButton onClick={() => onMerge(category)}>
          <MergeOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton onClick={() => onDelete(category)} color="error">
          <DeleteOutlined />
        </IconButton>
      </Tooltip>
    </ListItem>
  );
};
