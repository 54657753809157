import Dexie, { type EntityTable } from "dexie";

interface Category {
  id: string;
  name: string;
  colour: string;
}

interface Activity {
  id: string;
  categoryId: string;
  name: string;
  icon: string;
  archived?: boolean;
}

interface Timeslot {
  id: string;
  activityId: string;
  startTimestampMills: number;
  endTimestampMills: number;
  timezone: string; // This makes it easier to deal with timezones because Javascript timezones are a bitch
  placeId?: string;
  notes?: string;
  smarterTimeImport?: boolean;
  metadata?: string; // Storing other metadata that we do not have fields for yet JSON string
}

interface Place {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  radiusMetres: number;
  icon: string;
  smarterTimeImport?: boolean;
}

interface LocationHistory {
  id: string;
  timestampMills: number;
  latitude: number;
  longitude: number;
  accuracyMetres?: number;
  guessedActivity?: string;
  guessedActivityConfidence?: number;
  metadata?: string; // Storing other metadata that we do not have fields for yet JSON string
  smarterTimeImport?: boolean;
}

// Cloud backup tables

export type TableType =
  | "timeslots"
  | "activities"
  | "categories"
  | "places"
  | "locationHistories";

interface CloudBackupChanges {
  id: number; // Auto incrementing id
  timestampMills: number;
  tableId: string; // The id of the row that was changed
  table: TableType;
  changeType: "add" | "update" | "delete";
  data?: string; // JSON string of the changes that have been made
}

const db = new Dexie("DumberDatabase") as Dexie & {
  timeslots: EntityTable<Timeslot, "id">;
  activities: EntityTable<Activity, "id">;
  categories: EntityTable<Category, "id">;
  places: EntityTable<Place, "id">;
  locationHistories: EntityTable<LocationHistory, "id">;
  cloudBackupChanges: EntityTable<CloudBackupChanges, "id">;
};

// Schema declaration
db.version(1).stores({
  timeslots: "id, activityId, startTimestampMills, endTimestampMills, timezone",
  activities: "id, categoryId, name, icon",
  categories: "id, name, colour",
  places: "id, name, latitude, longitude",
  locationHistories: "id, timestampMills, latitude, longitude",
  cloudBackupChanges: "++id, timestampMills, tableId, table, changeType, data",
});

export const dbuuid = () => {
  return crypto.randomUUID().replace(/-/g, "");
};

export type { Timeslot, Activity, Category, LocationHistory, Place };
export { db, type CloudBackupChanges };
