import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useMemo, useState } from "react";
import { useCloudSyncedDB } from "./useCloudSyncedDb";
import { useSettings } from "./useSettings";
import { useSnackbar } from "./useSnackbar";
import {
  getLocationHistory,
  processAndInsertLocationHistory,
  updateLocationHistoryEndpoint,
} from "../utils/location_history_processing";

export const useSyncing = () => {
  const [isSyncingLocationHistory, setIsSyncingLocationHistory] =
    useState(false);
  const [isSyncingBackups, setIsSyncingBackups] = useState(false);

  const isSyncing = useMemo(
    () => isSyncingLocationHistory || isSyncingBackups,
    [isSyncingLocationHistory, isSyncingBackups]
  );

  const { showSnackbar } = useSnackbar();
  const cloudSyncedDB = useCloudSyncedDB();
  const {
    locationHistoryEndpoint,
    cloudBackupEndpoint,
    setLastCloudChangeTimestamp,
  } = useSettings();

  const cloudBackupChanges = useLiveQuery(
    () => cloudSyncedDB.getChangeCount(),
    [cloudSyncedDB]
  );

  const syncLocationHistory = async () => {
    if (!locationHistoryEndpoint) {
      showSnackbar(
        "Location history endpoint not set, please set it in settings first",
        "error",
        2000
      );
      return;
    }

    setIsSyncingLocationHistory(true);
    console.log("Syncing location history");
    const locationHistory = await getLocationHistory(locationHistoryEndpoint);

    if (locationHistory) {
      console.log("Location history:", locationHistory);
      const success = await processAndInsertLocationHistory(
        cloudSyncedDB,
        locationHistory
      );

      if (success) {
        updateLocationHistoryEndpoint(locationHistoryEndpoint, locationHistory);
        showSnackbar(
          <>
            Location history synced successfully.
            <br />
            {success.insertedCount} locations inserted, {success.skippedCount}{" "}
            locations skipped.
          </>,
          "success",
          2000
        );
      } else {
        showSnackbar("Failed to sync location history", "error", 2000);
      }

      setIsSyncingLocationHistory(false);
    } else {
      console.log("Failed to fetch location history");
    }
  };

  const sendAllChanges = useCallback(async () => {
    if (cloudBackupChanges && cloudBackupChanges > 0) {
      try {
        await cloudSyncedDB.sendAllChanges();
        showSnackbar("Changes sent to cloud backup", "success", 2000);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_) {
        showSnackbar("Failed to send changes to cloud backup", "error", 2000);
      }
    }
  }, [cloudSyncedDB, showSnackbar, cloudBackupChanges]);

  const receiveChanges = useCallback(async () => {
    const result = await cloudSyncedDB.checkForChanges(cloudBackupEndpoint);
    if (result === false) {
      showSnackbar("Failed to check for changes", "error", 2000);
    } else {
      console.log("Result:", result);
      if (result.new_changes > 0) {
        console.log("Getting changes from cloud backup");
        const cloudChanges = await cloudSyncedDB.retrieveChanges(
          cloudBackupEndpoint
        );
        console.log("Cloud changes:", cloudChanges);

        showSnackbar(`Synced ${result.new_changes} changes`, "success", 2000);
      } else {
        showSnackbar("Cloud Backup is up to date", "success", 2000);
        setLastCloudChangeTimestamp(result.latest_change_timestamp);
      }
    }
  }, [
    cloudBackupEndpoint,
    cloudSyncedDB,
    showSnackbar,
    setLastCloudChangeTimestamp,
  ]);

  const syncBackups = useCallback(async () => {
    setIsSyncingBackups(true);

    await sendAllChanges();
    await receiveChanges();

    setIsSyncingBackups(false);
  }, [sendAllChanges, receiveChanges]);

  return {
    isSyncing,
    isSyncingLocationHistory,
    isSyncingBackups,
    cloudBackupChanges,
    cloudBackupEndpoint,
    cloudSyncedDB,
    syncLocationHistory,
    syncBackups,
  };
};
