import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";
import { format, parse } from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useEffect, useMemo, useState } from "react";
import Calendar, { OnArgs } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useActivityJoinCategory } from "../../../hooks/useActivityJoinCategory";
import theme from "../../../theme/theme";
import {
  bucketTimeslotsByDay,
  CategorySegmentMap,
  getAllTimeslotsInAMonth,
  getDayCategorySegments,
} from "../../../utils/stats";
import StatsCalendarCell from "./StatsCalendarCell";

const FullWidthCalendar = styled(Calendar)<{ isXS: boolean }>`
  width: 100%;
  max-width: none;

  .react-calendar__month-view__days__day {
    height: ${(props) => (props.isXS ? "180px" : "120px")};
    position: relative;
    padding: 0;
    border: 1px solid #e0e0e0;
  }

  .react-calendar__tile > abbr {
    position: absolute;
    top: 7px;
    left: 7px;
    font-size: 14px;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__tile {
    padding: 5px;
  }

  /* Disable hover and focus styles */
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: inherit;
  }

  /* Remove the active style */
  .react-calendar__tile--active {
    background: inherit;
    color: inherit;
  }
`;

const StatsCalendar: React.FC = () => {
  const { activityCategoryMap } = useActivityJoinCategory();

  const [monthStats, setMonthStats] = useState<{
    [date: string]: CategorySegmentMap;
  }>({});
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const viewDate = useMemo(() => {
    const yearMonth = searchParams.get("yearMonth");
    if (!yearMonth) {
      const currentDate = new Date();
      return currentDate;
    }
    return parse(yearMonth, "yyyy-MM", new Date());
  }, [searchParams]);

  useEffect(() => {
    let yearMonth = searchParams.get("yearMonth");
    if (!yearMonth) {
      // If yearMonth is not in the URL, add it
      const currentDate = new Date();
      yearMonth = format(currentDate, "yyyy-MM");
      setSearchParams({ yearMonth }, { replace: true });
    }
  }, [viewDate, searchParams, setSearchParams]);

  const timeslots = useLiveQuery(() => {
    return getAllTimeslotsInAMonth(
      viewDate.getMonth() + 1,
      viewDate.getFullYear()
    );
  }, [viewDate]);

  useEffect(() => {
    if (!timeslots || !activityCategoryMap) return;

    const bucketedTimeslots = bucketTimeslotsByDay(
      timeslots,
      viewDate.getMonth() + 1,
      viewDate.getFullYear()
    );

    const newMonthStats: { [date: string]: CategorySegmentMap } = {};

    bucketedTimeslots.forEach((dayStats) => {
      const categorySegments = getDayCategorySegments(
        dayStats.timeslots,
        new Date(dayStats.date),
        activityCategoryMap
      );
      newMonthStats[dayStats.date] = categorySegments;
    });

    setMonthStats(newMonthStats);
  }, [timeslots, activityCategoryMap, viewDate]);

  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    if (view !== "month") return null;

    const dateKey = format(date, "yyyy-MM-dd");
    const dailyStats = monthStats[dateKey];

    if (!dailyStats) return null;

    const segments = Object.values(dailyStats);
    const totalPercentage = segments.reduce(
      (sum, segment) => sum + segment.percentage,
      0
    );
    const percentUnknown = 100 - totalPercentage;

    return (
      <StatsCalendarCell segments={segments} percentUnknown={percentUnknown} />
    );
  };

  const handleActiveStartDateChange = ({
    activeStartDate,
  }: {
    activeStartDate: Date;
  }) => {
    if (activeStartDate) {
      const newYearMonth = format(activeStartDate, "yyyy-MM");
      setSearchParams({ yearMonth: newYearMonth });
    }
  };

  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FullWidthCalendar
      isXS={isXS}
      next2Label={null}
      prev2Label={null}
      tileContent={tileContent}
      onActiveStartDateChange={({ activeStartDate }: OnArgs) => {
        if (activeStartDate) {
          handleActiveStartDateChange({ activeStartDate });
        }
      }}
      activeStartDate={viewDate}
      selectRange={false}
      onClickDay={(value: Date) => {
        const formattedDate = format(value, "yyyy-MM-dd");
        navigate(`/home?date=${formattedDate}`);
      }}
    />
  );
};

export default StatsCalendar;
