interface SmarterTimeLocation {
  Date: string;
  "Timestamp UTC ms": string;
  Time: string;
  Latitude: string;
  Longitude: string;
  Place: string;
  Move: string;
  MoveAccuracy: string;
}

interface SmarterTimeTimeslot {
  Day: string;
  "Timestamp UTC ms": string;
  Time: string;
  Place: string;
  Room: string;
  Activity: string;
  "Activity Category": string;
  "Duration ms": string;
  Steps: string;
  Move: string;
  Device: string;
}

export interface ExtractedPlace {
  name: string;
  latitude: number;
  longitude: number;
}
export interface PlacesAndCategories {
  places: string[];
  categories: Record<string, { activities: string[] }>;
}

export const extractPlacesFromGeoloc = (
  data: SmarterTimeLocation[]
): ExtractedPlace[] => {
  const locationMap = new Map<
    string,
    { sum: [number, number]; count: number }
  >();

  data.forEach((location) => {
    if (location.Place) {
      if (!locationMap.has(location.Place)) {
        locationMap.set(location.Place, { sum: [0, 0], count: 0 });
      }
      const entry = locationMap.get(location.Place)!;

      try {
        entry.sum[0] += parseFloat(location.Latitude);
        entry.sum[1] += parseFloat(location.Longitude);
        entry.count++;
      } catch (error) {
        console.warn(
          `Error processing entry: ${JSON.stringify(location)}`,
          error
        );
      }
    }
  });

  return Array.from(locationMap.entries()).map(([name, { sum, count }]) => {
    if (isNaN(sum[0] / count) || isNaN(sum[1] / count)) {
      console.warn(
        `Invalid latitude or longitude for entry: ${name}`,
        sum,
        count
      );
      return {
        name,
        latitude: 0,
        longitude: 0,
      };
    }
    return {
      name,
      latitude: sum[0] / count,
      longitude: sum[1] / count,
    };
  });
};

export const extractPlaceAndCategories = (
  data: SmarterTimeTimeslot[]
): PlacesAndCategories => {
  const places = new Set<string>();
  const categories: Record<string, Set<string>> = {};

  data.forEach((entry) => {
    if (entry.Place) {
      places.add(entry.Place);
    }

    if (entry["Activity Category"] && entry.Activity) {
      if (!categories[entry["Activity Category"]]) {
        categories[entry["Activity Category"]] = new Set<string>();
      }
      categories[entry["Activity Category"]].add(entry.Activity);
    }
  });

  return {
    places: Array.from(places),
    categories: Object.fromEntries(
      Object.entries(categories).map(([category, activities]) => [
        category,
        { activities: Array.from(activities) },
      ])
    ),
  };
};

interface ExtractedTimeslot {
  activityName: string;
  activityCategory: string;
  startTimestampMills: number;
  endTimestampMills: number;
  timezone: string;
  placeName?: string;
  metadata?: string;
}

export const extractTimeslots = (
  data: SmarterTimeTimeslot[]
): ExtractedTimeslot[] => {
  return data.map((entry) => {
    let startTimestampMills: number;
    let endTimestampMills: number;
    let timezone: string = "";

    try {
      startTimestampMills = parseInt(entry["Timestamp UTC ms"]);
      if (isNaN(startTimestampMills)) {
        throw new Error("Invalid start timestamp");
      }

      const durationMs = parseInt(entry["Duration ms"]);
      if (isNaN(durationMs)) {
        throw new Error("Invalid duration");
      }
      endTimestampMills = startTimestampMills + durationMs;

      const timezoneMatch = entry.Time.match(/GMT([-+]\d{2}:\d{2})/);
      if (timezoneMatch) {
        timezone = timezoneMatch[1];
      } else {
        console.warn(`No timezone found for entry: ${JSON.stringify(entry)}`);
      }
    } catch (error) {
      console.error(`Error processing entry: ${JSON.stringify(entry)}`, error);
      startTimestampMills = 0;
      endTimestampMills = 0;
    }

    const metadata: Record<string, string> = {};
    if (entry.Room) metadata.room = entry.Room;
    if (entry.Steps) metadata.steps = entry.Steps;
    if (entry.Move) metadata.move = entry.Move;
    if (entry.Device) metadata.device = entry.Device;

    return {
      activityName: entry.Activity,
      activityCategory: entry["Activity Category"],
      startTimestampMills,
      endTimestampMills,
      timezone,
      placeName: entry.Place || undefined,
      metadata:
        Object.keys(metadata).length > 0 ? JSON.stringify(metadata) : undefined,
    };
  });
};

export interface ExtractedLocationHistory {
  timestampMills: number;
  latitude: number;
  longitude: number;
  guessedActivity?: string;
  guessedActivityConfidence?: number;
  placeName?: string;
  metadata?: string;
}
export const extractLocationHistory = (
  data: SmarterTimeLocation[]
): ExtractedLocationHistory[] => {
  return data.map((entry) => {
    let timestampMills: number;
    let timezone: string = "";

    try {
      timestampMills = parseInt(entry["Timestamp UTC ms"]);
      if (isNaN(timestampMills)) {
        throw new Error("Invalid timestamp");
      }

      const timezoneMatch = entry.Time.match(/GMT([-+]\d{2}:\d{2})/);
      if (timezoneMatch) {
        timezone = timezoneMatch[1];
      } else {
        console.warn(`No timezone found for entry: ${JSON.stringify(entry)}`);
      }
    } catch (error) {
      console.error(`Error processing entry: ${JSON.stringify(entry)}`, error);
      timestampMills = 0;
    }

    const metadata = {
      timezone: timezone,
    };

    return {
      timestampMills,
      latitude: parseFloat(entry.Latitude),
      longitude: parseFloat(entry.Longitude),
      placeName: entry.Place || undefined,
      guessedActivity: entry.Move || undefined,
      guessedActivityConfidence: entry.MoveAccuracy
        ? parseInt(entry.MoveAccuracy)
        : undefined,
      metadata: JSON.stringify(metadata),
    };
  });
};
