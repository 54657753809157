import React, { ReactNode } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";

interface GlobalSnackbarProps {
  open: boolean;
  message: string | ReactNode;
  severity: AlertColor;
  onClose: () => void;
  autoHideDuration?: number;
}

export const GlobalSnackbar: React.FC<GlobalSnackbarProps> = ({
  open,
  message,
  severity,
  onClose,
  autoHideDuration = 6000,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
