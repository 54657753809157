import styled from "@emotion/styled";

const JSONDisplayPre = styled.pre`
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
  font-weight: 200;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: scroll;
  text-align: left;
  .string {
    color: green;
    white-space: normal;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
`;

export const JSONDisplay = (props: {
  json: string | object;
  style?: React.CSSProperties;
}) => {
  const style = props.style || {};
  const json = props.json;
  function syntaxHighlight(json: string | object) {
    let jsonString = "";
    try {
      if (typeof json != "string") {
        jsonString = JSON.stringify(json, null, 2);
      } else {
        // convert to object and back to string to format it
        jsonString = JSON.parse(json);
        jsonString = JSON.stringify(jsonString, null, 2);
      }
    } catch (e) {
      console.error("Error formatting JSON", e);
      jsonString = "Error formatting JSON";
    }
    jsonString = jsonString
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    return jsonString.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match: string) {
        let cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
  }

  return (
    <JSONDisplayPre
      style={style}
      dangerouslySetInnerHTML={{ __html: syntaxHighlight(json) }}
    />
  );
};
