import { db, Timeslot } from "../database/db";
import { CloudSyncedDB } from "../hooks/useCloudSyncedDb";

// This function checks if the new Timeslot overlaps with any existing Timeslots
// It returns the type of overlap as one of the following:
// "none": There are no overlaps
// "start": The checkTimeslot overlaps only the start of the existingTimeslot
// "end": The checkTimeslot overlaps only the end of the existingTimeslot
// "middle": The checkTimeslot overlaps the middle of the existingTimeslot
// "bigger": The checkTimeslot completely encompasses the existingTimeslot
export type OverlapType = "start" | "end" | "middle" | "bigger" | "none";
export const checkOverlapType = (
  existingTimeslot: Timeslot,
  checkTimeslot: Timeslot
): OverlapType => {
  // Check if the new Timeslot overlaps with the existing Timeslot
  if (
    checkTimeslot.startTimestampMills < existingTimeslot.endTimestampMills &&
    checkTimeslot.endTimestampMills > existingTimeslot.startTimestampMills
  ) {
    // Check if the new Timeslot completely encompasses the existing Timeslot
    if (
      checkTimeslot.startTimestampMills <=
        existingTimeslot.startTimestampMills &&
      checkTimeslot.endTimestampMills >= existingTimeslot.endTimestampMills
    ) {
      return "bigger";
    }
    // Check if the new Timeslot starts before the existing Timeslot but ends within it
    if (
      checkTimeslot.startTimestampMills < existingTimeslot.startTimestampMills
    ) {
      return "start";
    }
    // Check if the new Timeslot starts within the existing Timeslot but ends after it
    if (checkTimeslot.endTimestampMills > existingTimeslot.endTimestampMills) {
      return "end";
    }
    // The new Timeslot overlaps the middle of the existing Timeslot
    return "middle";
  }
  // No overlap
  return "none";
};

// Gets all timeslots that overlaps this one
export const getOverlappingTimeslotsDb = async (
  timeslot: Timeslot
): Promise<Timeslot[]> => {
  // Get the timeslots from the database that are in the same time range as the new Timeslot
  const timeslots = await db.timeslots
    .where("startTimestampMills")
    .between(timeslot.startTimestampMills, timeslot.endTimestampMills)
    .or("endTimestampMills")
    .between(timeslot.startTimestampMills, timeslot.endTimestampMills)
    .toArray();
  return timeslots;
};

// This assumes the existing Timeslots are in the same time range as the new Timeslot
// If not, call the other function that will check the database for conflicts
export const getConflictingTimeslots = (
  existingTimeslots: Timeslot[],
  newTimeslot: Timeslot
): Timeslot[] => {
  return existingTimeslots.filter((timeslot) => {
    // If the new Timeslot is the same as the existing Timeslot, it's not a conflict
    if (timeslot.id === newTimeslot.id) return false;
    // If the new Timeslot starts before the existing Timeslot ends
    // and the new Timeslot ends after the existing Timeslot starts
    // then they overlap
    if (
      newTimeslot.startTimestampMills < timeslot.endTimestampMills &&
      newTimeslot.endTimestampMills > timeslot.startTimestampMills
    ) {
      return true;
    }
    return false;
  });
};

// This function will adjust the surrounding Timeslots if there are any conflicts.
// It will return ONLY the modified Timeslots without the new Timeslot
export type TimeslotChange =
  | {
      action: "update";
      timeslot: Timeslot;
    }
  | {
      action: "delete";
      timeslot: {
        id: string;
      };
    }
  | {
      action: "add";
      timeslot: Omit<Timeslot, "id">;
    };
export const getTimeslotAdjustments = async (
  existingTimeslots: Timeslot[],
  newTimeslot: Timeslot
): Promise<TimeslotChange[]> => {
  if (existingTimeslots.length === 0) return [];

  // Remove the newTimeslot
  const timeslotActions: TimeslotChange[] = [];

  // There are 4 cases:
  // No overlap, do nothing
  // Overlap at the start, adjust the start of the existing Timeslot
  // Overlap at the end, adjust the end of the existing Timeslot
  // Overlap in the middle, split the existing Timeslot into two
  // Completely encompassed, remove the existing Timeslot
  for (const existingTimeslot of existingTimeslots) {
    // If the existing Timeslot is the same as the new Timeslot, skip it
    if (existingTimeslot.id === newTimeslot.id) continue;
    const overlapType = checkOverlapType(existingTimeslot, newTimeslot);
    switch (overlapType) {
      case "start":
        // Adjust the start of the existing Timeslot
        existingTimeslot.startTimestampMills = newTimeslot.endTimestampMills;
        timeslotActions.push({
          action: "update",
          timeslot: {
            ...existingTimeslot,
          },
        });
        break;
      case "end":
        // Adjust the end of the existing Timeslot
        existingTimeslot.endTimestampMills = newTimeslot.startTimestampMills;
        timeslotActions.push({
          action: "update",
          timeslot: {
            ...existingTimeslot,
          },
        });
        break;
      case "middle": {
        // Split the existing Timeslot into two
        const newTimeslot1 = {
          activityId: existingTimeslot.activityId,
          startTimestampMills: newTimeslot.endTimestampMills,
          endTimestampMills: existingTimeslot.endTimestampMills,
          timezone: existingTimeslot.timezone,
        };
        existingTimeslot.endTimestampMills = newTimeslot.startTimestampMills;
        timeslotActions.push({
          action: "update",
          timeslot: {
            ...existingTimeslot,
          },
        });
        timeslotActions.push({
          action: "add",
          timeslot: {
            ...newTimeslot1,
          },
        });
        break;
      }
      case "bigger":
        // Remove the existing Timeslot
        timeslotActions.push({
          action: "delete",
          timeslot: {
            id: existingTimeslot.id,
          },
        });
        break;
    }
  }
  return timeslotActions;
};

// Gets all timeslots that overlaps this one from the database
export const getConflictingTimeslotsDb = async (
  newTimeslot: Timeslot
): Promise<Timeslot[]> => {
  // Get the timeslots from the database that are in the same time range as the new Timeslot
  const timeslots = await getOverlappingTimeslotsDb(newTimeslot);
  // Return the conflicting Timeslots
  const conflicts = getConflictingTimeslots(timeslots, newTimeslot);
  return conflicts;
};

// Gets all timeslots that overlaps this one from the database
// and returns the adjustments that need to be made to the database
export const getTimeslotAdjustmentsDb = async (
  newTimeslot: Timeslot
): Promise<TimeslotChange[]> => {
  const timeslots = await getOverlappingTimeslotsDb(newTimeslot);
  return getTimeslotAdjustments(timeslots, newTimeslot);
};

export const applyTimeslotChanges = async (
  cloudSyncedDB: CloudSyncedDB,
  changes: TimeslotChange[],
  generateUndoSteps: boolean = false
) => {
  const undoSteps: TimeslotChange[] = [];
  for (const change of changes) {
    if (change.action === "delete") {
      if (generateUndoSteps) {
        // Get existing timeslot from db
        const existingTimeslot = await db.timeslots.get(change.timeslot.id);
        if (existingTimeslot) {
          const newTimeslot: Omit<Timeslot, "id"> = {
            activityId: existingTimeslot.activityId,
            startTimestampMills: existingTimeslot.startTimestampMills,
            endTimestampMills: existingTimeslot.endTimestampMills,
            timezone: existingTimeslot.timezone,
          };
          undoSteps.push({
            action: "add",
            timeslot: newTimeslot,
          });
        }
      }
      await cloudSyncedDB.deleteTimeslot(change.timeslot.id);
    } else if (change.action === "update") {
      if (generateUndoSteps) {
        // Get existing timeslot from db
        const existingTimeslot = await db.timeslots.get(change.timeslot.id);
        if (existingTimeslot) {
          undoSteps.push({
            action: "update",
            timeslot: {
              ...existingTimeslot,
            },
          });
        }
      }
      await cloudSyncedDB.updateTimeslot(change.timeslot.id, change.timeslot);
    } else if (change.action === "add") {
      // Remove the ID key if it exists
      const { id: _, ...newTimeslot } = change.timeslot as Timeslot;
      const newTimeslotId = await cloudSyncedDB.addTimeslot(newTimeslot);
      if (generateUndoSteps) {
        undoSteps.push({
          action: "delete",
          timeslot: {
            id: newTimeslotId,
          },
        });
      }
    }
  }
  return undoSteps;
};
