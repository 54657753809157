import { Box, Button } from "@mui/material";
import { ICON_ARRAY } from "../../../const/icons";

export const IconSelector: React.FC<{
  selectedIcon: string;
  onSelectIcon: (iconName: string) => void;
  color?: string;
}> = ({ selectedIcon, onSelectIcon, color }) => {
  const selectedColor = color || "primary";
  return (
    <Box>
      {ICON_ARRAY.map((i) => {
        const IconComponent = i.icon;
        return (
          <Button
            key={i.name}
            onClick={() => onSelectIcon(i.name)}
            sx={{
              color: selectedColor,
              borderColor: selectedColor,
            }}
            variant={selectedIcon === i.name ? "outlined" : "text"}
            title={i.name}>
            <IconComponent />
          </Button>
        );
      })}
    </Box>
  );
};
