import { useContext } from "react";
import { UndoRedoContext, UndoRedoContextType } from "./UndoRedoProvider";

export const useUndoRedo = (): UndoRedoContextType => {
  const context = useContext(UndoRedoContext);
  if (context === undefined) {
    throw new Error("useUndoRedo must be used within a UndoRedoProvider");
  }
  return context;
};
