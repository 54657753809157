import { useCallback, useState } from "react";
import { MATERIAL_COLOURS } from "../const/colours";
import {
  Activity,
  Category,
  CloudBackupChanges,
  LocationHistory,
  Place,
  TableType,
  Timeslot,
  db,
  dbuuid,
} from "../database/db";
import { useModal } from "./useModal";
import { useSettings } from "./useSettings";
import { useSnackbar } from "./useSnackbar";

export type CloudChange = {
  id: string;
  timestamp: number;
  table: TableType;
  type: "add" | "update" | "delete";
  data?: string;
};

export function useCloudSyncedDB() {
  const {
    cloudBackupEndpoint,
    lastCloudChangeTimestamp,
    setLastCloudChangeTimestamp,
    cloudSyncKey,
    disableCloudSync,
  } = useSettings();

  const { showModal } = useModal();
  const { showSnackbar } = useSnackbar();

  const [processedChanges, setProcessedChanges] = useState(0);
  const [totalChanges, setTotalChanges] = useState(0);

  const [downloadingChanges, setDownloadingChanges] = useState(false);
  const [changesLeftToReceive, setChangesLeftToReceive] = useState(10);

  // Add a change to the cloud backup changes table if there's a valid cloud backup endpoint
  const addToCloudBackupChanges = async (
    change: Omit<CloudBackupChanges, "id" | "timestampMills">
  ) => {
    if (!cloudBackupEndpoint) {
      return;
    }

    // Wrap the operations in a transaction
    await db.transaction("rw", db.cloudBackupChanges, async () => {
      // Search for old changes with the same tableId and table and delete them
      await db.cloudBackupChanges
        .where("tableId")
        .equals(change.tableId)
        .and((change) => change.table === change.table)
        .delete();

      await db.cloudBackupChanges.add({
        ...change,
        timestampMills: Date.now(),
      });
    });
  };

  // UTILITY FUNCTIONS

  // Should be called when cloud backup is initialized
  // Adds all database items to the cloud backup changes table
  const initialiseCloudBackup = async () => {
    // Get the total number of items across all tables
    const timeslotsCount = await db.timeslots.count();
    const activitiesCount = await db.activities.count();
    const categoriesCount = await db.categories.count();
    const placesCount = await db.places.count();
    const locationHistoriesCount = await db.locationHistories.count();

    const total =
      timeslotsCount +
      activitiesCount +
      categoriesCount +
      placesCount +
      locationHistoriesCount;
    setTotalChanges(total);
    setProcessedChanges(0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function processTable(table: TableType, items: any[]) {
      const BATCH_SIZE = 1000;
      let processedCount = 0;

      for (let i = 0; i < items.length; i += BATCH_SIZE) {
        const batch = items.slice(i, Math.min(i + BATCH_SIZE, items.length));
        const validBatch = batch.filter((item) => item?.id);

        const changes = validBatch.map((item) => ({
          tableId: item.id as string,
          table: table,
          changeType: "add" as const,
          data: JSON.stringify(item),
          timestampMills: Date.now(),
        }));

        await db.cloudBackupChanges.bulkAdd(changes);
        processedCount += validBatch.length;

        // Update processed changes and pause to allow UI updates
        setProcessedChanges((prev) => prev + validBatch.length);
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      // Log any invalid items that were skipped
      const invalidCount = items.length - processedCount;
      if (invalidCount > 0) {
        console.error(
          `Skipped ${invalidCount} invalid items in ${table} table`
        );
      }
    }

    await processTable("timeslots", await db.timeslots.toArray());
    await processTable("activities", await db.activities.toArray());
    await processTable("categories", await db.categories.toArray());
    await processTable("places", await db.places.toArray());
    await processTable(
      "locationHistories",
      await db.locationHistories.toArray()
    );

    // Ensure processedChanges equals totalChanges at the end
    setProcessedChanges(total);
  };

  const emptyCloudBackupChanges = async () => {
    await db.cloudBackupChanges.clear();
  };

  const getChangeCount = async () => {
    return await db.cloudBackupChanges.count();
  };

  const getChanges = async (count: number = 1000) => {
    return await db.cloudBackupChanges
      .orderBy("timestampMills")
      .limit(count)
      .toArray();
  };

  const disconnectedCloudSyncCallback = useCallback(async () => {
    await showModal(
      "Cloud Sync Disconnected",
      "Cloud sync has been disconnected from the server. Please reconnect in the settings screen.",
      {
        okText: "OK",
        colour: MATERIAL_COLOURS.red,
        cancelText: false,
      }
    );

    disableCloudSync();
    emptyCloudBackupChanges();
  }, [showModal, disableCloudSync]);

  const deleteChanges = async (changeIds: number[]) => {
    await db.cloudBackupChanges.bulkDelete(changeIds);
  };

  const sendAllChanges = async () => {
    while (true) {
      const remainingChanges = await sendChanges();
      if (!remainingChanges || remainingChanges <= 0) {
        break;
      }
    }
  };

  const sendChanges = async (count: number = 3000) => {
    if (!cloudBackupEndpoint) {
      return;
    }

    let latestChangeTimestamp = lastCloudChangeTimestamp;

    const changes = await getChanges(count);
    const remappedChanges = changes.map((change) => {
      latestChangeTimestamp = Math.max(
        latestChangeTimestamp,
        change.timestampMills
      );
      return {
        id: change.tableId,
        table: change.table,
        type: change.changeType,
        data: change.data,
        timestamp: change.timestampMills,
      };
    });

    const response = await fetch(cloudBackupEndpoint, {
      method: "POST",
      body: JSON.stringify(remappedChanges),
      headers: {
        "Content-Type": "application/json",
        "X-Sync-Key": cloudSyncKey || "",
      },
    });

    if (response.ok) {
      await deleteChanges(changes.map((change) => change.id));
      setLastCloudChangeTimestamp(latestChangeTimestamp);
      // Return remaining changes
      const remainingChanges = await getChangeCount();
      return remainingChanges;
    } else if (response.status === 403) {
      disconnectedCloudSyncCallback();
      throw new Error("Cloud sync disconnected");
    } else {
      throw new Error("Failed to send changes to cloud backup");
    }
  };

  const checkForChanges = async (
    endpoint: string,
    syncKey?: string
  ): Promise<
    | {
        new_changes: number;
        latest_change_timestamp: number;
      }
    | false
  > => {
    const syncKeyToUse = syncKey || cloudSyncKey;
    try {
      const response = await fetch(
        `${endpoint}?check=${lastCloudChangeTimestamp}`,
        {
          headers: {
            "X-Sync-Key": syncKeyToUse || "",
          },
        }
      );
      if (response.ok) {
        const body = await response.json();
        return {
          new_changes: body?.new_changes || 0,
          latest_change_timestamp: body?.latest_change_timestamp || 0,
        };
      } else if (response.status === 403) {
        disconnectedCloudSyncCallback();
        return false;
      }
      return false;
    } catch (error) {
      console.error("Error checking cloud backup endpoint:", error);
      return false;
    }
  };

  const retrieveChanges = useCallback(
    async (endpoint: string): Promise<boolean> => {
      setDownloadingChanges(true);
      setChangesLeftToReceive(0);
      await new Promise((resolve) => setTimeout(resolve, 100));

      let previousTimestamp = -1;
      let latestTimestamp = lastCloudChangeTimestamp;
      while (true) {
        try {
          const response = await fetch(
            `${endpoint}?retrieve=${latestTimestamp}`,
            {
              headers: {
                "X-Sync-Key": cloudSyncKey || "",
              },
            }
          );
          if (response.ok) {
            const body = await response.json();
            const updates = (body?.updates || []) as CloudChange[];
            const hasMoreUpdates = body?.more_updates || false;
            const changesLeft = body?.updates_left || 0;
            setChangesLeftToReceive(changesLeft);

            // Wrap the whole process in a transaction
            await db.transaction(
              "rw",
              [
                db.timeslots,
                db.activities,
                db.categories,
                db.places,
                db.locationHistories,
                db.cloudBackupChanges,
              ],
              async () => {
                for (const change of updates) {
                  const table = db[change.table];
                  const localItem = await table.get(change.id);

                  if (!localItem) {
                    // Item doesn't exist locally, apply the change
                    if (change.type !== "delete") {
                      if (!change.data) {
                        console.error("No data for change", change);
                        continue;
                      }
                      await table.add(JSON.parse(change.data));
                    }
                  } else {
                    // Item exists locally, check for conflicts
                    const localChange = await db.cloudBackupChanges
                      .where({ tableId: change.id, table: change.table })
                      .reverse()
                      .first();

                    if (
                      !localChange ||
                      change.timestamp > localChange.timestampMills
                    ) {
                      // Cloud change is newer, apply it
                      if (change.type === "delete") {
                        await table.delete(change.id);
                      } else {
                        if (!change.data) {
                          console.error("No data for change", change);
                          continue;
                        }
                        await table.put(JSON.parse(change.data));
                      }
                      // Remove the local change if it exists
                      if (localChange) {
                        await db.cloudBackupChanges.delete(localChange.id);
                      }
                    }
                    // If local change is newer, we keep the local version (do nothing)
                  }

                  // Update the latest timestamp
                  latestTimestamp = Math.max(latestTimestamp, change.timestamp);
                }
              }
            );

            // Update the last cloud change timestamp
            setLastCloudChangeTimestamp(latestTimestamp);

            if (changesLeft > 0) {
              showSnackbar(`Changes left: ${changesLeft}`, "info", 2000);
            }

            if (hasMoreUpdates) {
              // We need to retrieve more changes
              if (previousTimestamp === latestTimestamp) {
                console.error("Changes did not progress");
                return false;
              }
              previousTimestamp = latestTimestamp;
              // Add a timeout to allow the UI thread to refresh
              await new Promise((resolve) => setTimeout(resolve, 0));
              continue;
            } else {
              setDownloadingChanges(false);
              setChangesLeftToReceive(0);
              return true;
            }
          } else if (response.status === 403) {
            disconnectedCloudSyncCallback();
            setDownloadingChanges(false);
            return false;
          }
        } catch (error) {
          console.error("Error retrieving changes from cloud backup:", error);
          setDownloadingChanges(false);
          return false;
        }
        setDownloadingChanges(false);
        return false;
      }
    },
    [
      cloudSyncKey,
      disconnectedCloudSyncCallback,
      lastCloudChangeTimestamp,
      setLastCloudChangeTimestamp,
      showSnackbar,
    ]
  );

  // TIMESLOTS

  const addTimeslot = async (timeslot: Omit<Timeslot, "id">) => {
    const newId = dbuuid();
    const timeslotWithId: Timeslot = {
      ...timeslot,
      id: newId,
    };
    await db.timeslots.add(timeslotWithId);
    await addToCloudBackupChanges({
      tableId: newId,
      table: "timeslots",
      changeType: "add",
      data: JSON.stringify(timeslotWithId),
    });
    return newId;
  };

  const bulkAddTimeslots = async (timeslots: Omit<Timeslot, "id">[]) => {
    const newTimeslots = timeslots.map((timeslot) => ({
      ...timeslot,
      id: dbuuid(),
    }));
    await db.timeslots.bulkAdd(newTimeslots);

    for (const timeslot of newTimeslots) {
      await addToCloudBackupChanges({
        tableId: timeslot.id,
        table: "timeslots",
        changeType: "add",
        data: JSON.stringify(timeslot),
      });
    }
  };

  const updateTimeslot = async (id: string, changes: Partial<Timeslot>) => {
    await db.timeslots.update(id, changes);
    const timeslot = await db.timeslots.get(id);
    if (!timeslot) {
      return;
    }
    await addToCloudBackupChanges({
      tableId: id,
      table: "timeslots",
      changeType: "update",
      data: JSON.stringify(timeslot),
    });
  };

  const updateTimeslotsForActivity = async (
    activityId: string,
    changes: Partial<Timeslot>
  ) => {
    const timeslots = await db.timeslots
      .where("activityId")
      .equals(activityId)
      .toArray();
    for (const timeslot of timeslots) {
      await updateTimeslot(timeslot.id, changes);
    }
  };

  const deleteTimeslot = async (id: string) => {
    await db.timeslots.delete(id);
    await addToCloudBackupChanges({
      tableId: id,
      table: "timeslots",
      changeType: "delete",
    });
  };

  // ACTIVITIES

  const addActivity = async (activity: Omit<Activity, "id">) => {
    const newId = dbuuid();
    await db.activities.add({
      ...activity,
      id: newId,
    });
    await addToCloudBackupChanges({
      tableId: newId,
      table: "activities",
      changeType: "add",
      data: JSON.stringify(activity),
    });
    return newId;
  };

  const bulkUpdateActivities = async (
    activities: {
      key: string;
      changes: Partial<Activity>;
    }[]
  ) => {
    await db.activities.bulkUpdate(activities);

    for (const activity of activities) {
      await addToCloudBackupChanges({
        tableId: activity.key,
        table: "activities",
        changeType: "update",
        data: JSON.stringify(activity.changes),
      });
    }
  };

  const updateActivity = async (id: string, changes: Partial<Activity>) => {
    await db.activities.update(id, changes);
    const activity = await db.activities.get(id);
    if (!activity) {
      return;
    }
    await addToCloudBackupChanges({
      tableId: id,
      table: "activities",
      changeType: "update",
      data: JSON.stringify(activity),
    });
  };

  const updateActivitiesForCategory = async (
    categoryId: string,
    changes: Partial<Activity>
  ) => {
    const activities = await db.activities
      .where("categoryId")
      .equals(categoryId)
      .toArray();

    activities.forEach(async (activity) => {
      await updateActivity(activity.id, changes);
    });
  };

  const deleteActivity = async (id: string) => {
    await db.activities.delete(id);
    await addToCloudBackupChanges({
      tableId: id,
      table: "activities",
      changeType: "delete",
    });
  };

  // LOCATION HISTORY

  const addLocationHistory = async (
    locationHistory: Omit<LocationHistory, "id">
  ) => {
    const newId = dbuuid();
    await db.locationHistories.add({
      ...locationHistory,
      id: newId,
    });
    await addToCloudBackupChanges({
      tableId: newId,
      table: "locationHistories",
      changeType: "add",
      data: JSON.stringify(locationHistory),
    });
    return newId;
  };

  const bulkAddLocationHistory = async (
    locationHistory: Omit<LocationHistory, "id">[]
  ) => {
    const newLocationHistory = locationHistory.map((location) => ({
      ...location,
      id: dbuuid(),
    }));
    await db.locationHistories.bulkAdd(newLocationHistory);

    for (const location of newLocationHistory) {
      await addToCloudBackupChanges({
        tableId: location.id,
        table: "locationHistories",
        changeType: "add",
        data: JSON.stringify(location),
      });
    }
  };

  // CATEGORIES
  const addCategory = async (category: Omit<Category, "id">) => {
    const newId = dbuuid();
    await db.categories.add({
      ...category,
      id: newId,
    });
    await addToCloudBackupChanges({
      tableId: newId,
      table: "categories",
      changeType: "add",
      data: JSON.stringify(category),
    });
    return newId;
  };

  const updateCategory = async (id: string, changes: Partial<Category>) => {
    await db.categories.update(id, changes);
    const category = await db.categories.get(id);
    if (!category) {
      return;
    }
    await addToCloudBackupChanges({
      tableId: id,
      table: "categories",
      changeType: "update",
      data: JSON.stringify(category),
    });
  };

  const deleteCategory = async (id: string) => {
    await db.categories.delete(id);
    await addToCloudBackupChanges({
      tableId: id,
      table: "categories",
      changeType: "delete",
    });
  };

  // PLACES
  const addPlace = async (place: Omit<Place, "id">) => {
    const newId = dbuuid();
    await db.places.add({
      ...place,
      id: newId,
    });
    await addToCloudBackupChanges({
      tableId: newId,
      table: "places",
      changeType: "add",
      data: JSON.stringify(place),
    });
    return newId;
  };

  const updatePlace = async (id: string, changes: Partial<Place>) => {
    await db.places.update(id, changes);
    const place = await db.places.get(id);
    if (!place) {
      return;
    }
    await addToCloudBackupChanges({
      tableId: id,
      table: "places",
      changeType: "update",
      data: JSON.stringify(place),
    });
  };

  const deletePlace = async (id: string) => {
    await db.places.delete(id);
    await addToCloudBackupChanges({
      tableId: id,
      table: "places",
      changeType: "delete",
    });
  };

  return {
    // UTILITY FUNCTIONS
    initialiseCloudBackup,
    emptyCloudBackupChanges,
    getChangeCount,
    getChanges,
    deleteChanges,
    sendChanges,
    sendAllChanges,
    retrieveChanges,
    checkForChanges,
    // TIMESLOTS
    addTimeslot,
    bulkAddTimeslots,
    updateTimeslot,
    updateTimeslotsForActivity,
    deleteTimeslot,
    // ACTIVITIES
    addActivity,
    bulkUpdateActivities,
    updateActivity,
    updateActivitiesForCategory,
    deleteActivity,
    // CATEGORIES
    addCategory,
    updateCategory,
    deleteCategory,
    // PLACES
    addPlace,
    updatePlace,
    deletePlace,
    // LOCATION HISTORY
    addLocationHistory,
    bulkAddLocationHistory,

    // PROGRESS
    processedChanges,
    totalChanges,
    changesLeftToReceive,
    downloadingChanges,
  };
}

export type CloudSyncedDB = ReturnType<typeof useCloudSyncedDB>;
