import {
  ArchiveOutlined,
  DeleteOutlined,
  EditOutlined,
  MergeOutlined,
  UnarchiveOutlined,
  Help as HelpIcon,
} from "@mui/icons-material";
import {
  Chip,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { ICONS } from "../../../const/icons";
import { Activity, Category } from "../../../database/db";
import { formatDistanceToNow } from "date-fns";
import { DEV_COLOUR, GREY_COLOUR } from "../../../const/colours";
import { useSettings } from "../../../hooks/useSettings";

interface ActivityRowProps {
  activity: Activity & {
    lastUsedTime: number | undefined;
    timeslotCount: number | undefined;
  };
  category: Category | undefined;
  onEdit: (activity: Activity) => void;
  onArchive: (activity: Activity) => void;
  onDelete: (activity: Activity) => void;
  onMerge: (activity: Activity) => void;
}

export const ActivityRow: React.FC<ActivityRowProps> = ({
  activity,
  category,
  onEdit,
  onArchive,
  onDelete,
  onMerge,
}) => {
  const Icon = ICONS[activity.icon] || HelpIcon;

  const { developerMode } = useSettings();

  const relativeTime = useMemo(() => {
    if (!activity.lastUsedTime) return "Not used in two months";
    return formatDistanceToNow(activity.lastUsedTime, { addSuffix: true });
  }, [activity.lastUsedTime]);

  const timeslotCount = activity.timeslotCount || 0;

  const titleCaseName = activity.name.replace(/\b\w/g, (char) =>
    char.toUpperCase()
  );
  const titleCaseCategoryName = category?.name
    ? category?.name.replace(/\b\w/g, (char) => char.toUpperCase())
    : "No Category";
  return (
    <ListItem>
      <ListItemIcon>
        {React.isValidElement(<Icon />) ? (
          <Icon style={{ color: category?.colour || "inherit" }} />
        ) : (
          <HelpIcon style={{ color: category?.colour || "inherit" }} />
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          <Stack direction="row" spacing={1}>
            <Typography variant="h6">{titleCaseName}</Typography>
            <Typography variant="body1">—</Typography>
            <Typography variant="body1" color={GREY_COLOUR}>
              {titleCaseCategoryName}
            </Typography>
          </Stack>
        }
        secondary={
          timeslotCount > 0 ? (
            <>
              <strong>{timeslotCount}</strong> times in two months, last used{" "}
              <strong>{relativeTime}</strong>
            </>
          ) : (
            <em style={{ color: GREY_COLOUR }}>{relativeTime}</em>
          )
        }
      />
      {activity.archived ? <Chip label="Archived" color="secondary" /> : null}

      {developerMode && (
        <Tooltip title={`ID: ${activity.id}`}>
          <Typography
            sx={{
              ml: 2,
              fontSize: "0.8rem",
              color: DEV_COLOUR,
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            ID: {activity.id}
          </Typography>
        </Tooltip>
      )}
      <Tooltip title={activity.archived ? "Unarchive" : "Archive"}>
        <IconButton onClick={() => onArchive(activity)}>
          {activity.archived ? <UnarchiveOutlined /> : <ArchiveOutlined />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton onClick={() => onEdit(activity)}>
          <EditOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Merge">
        <IconButton onClick={() => onMerge(activity)}>
          <MergeOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton onClick={() => onDelete(activity)} color="error">
          <DeleteOutlined />
        </IconButton>
      </Tooltip>
    </ListItem>
  );
};
