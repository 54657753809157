export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getStorageInfoString = (info: {
  quota: number;
  usage: number;
  usageDetails: { indexedDB: number };
}) => {
  const usedSpace = formatBytes(info.usage);
  const totalSpace = formatBytes(info.quota);
  const percentUsed = ((info.usage / info.quota) * 100).toFixed(2);

  return `Used: ${usedSpace} of ${totalSpace} locally (${percentUsed}%)`;
};

export const fetchStorageInfo = async (): Promise<{
  quota: number;
  usage: number;
  usageDetails: { indexedDB: number };
} | null> => {
  try {
    const estimate = await navigator.storage.estimate();
    return estimate as {
      quota: number;
      usage: number;
      usageDetails: { indexedDB: number };
    };
  } catch (error) {
    console.error("Error fetching storage info:", error);
    return null;
  }
};
